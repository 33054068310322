import { ARRAY_DELETION_KEYS, INFINITY, BCAPD_BAND_COUNT } from '@policyfly/utils/constants'
import { Big } from 'big.js'
import get from 'lodash-es/get'
import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'

import {
  extractObjectDiffs,
  createAddressComputedMap,
  createArrayComputedMap,
  combineRemovedArrayItems,
} from '@/utils/diffHelpers'
import { checkDisplayIf } from '@/utils/displayIf'
import { formatValue } from '@/utils/formatter'
import { getNestedResponse, getQuoteResponse, getIssueResponse } from '@/utils/responses'

import type { NestedResponseObject, PayloadObject, PayloadResponse } from '@/lib/Payload'
import type { CreateMapFn } from '@/stores/programData'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { DiffMap } from 'types/application'
import type { ComputedRef } from 'vue'

interface TotalInsuredValue {
  compositeTIV: { v?: string }
  totalTIV: { v?: string }
  trailerInterchangeResponse: { v?: string }
  powerUnits: { v?: string }
  limit: { v?: string }
  nonOwnedTrailersResponse: { v?: string }
  nonOwnedTrailers: { v?: string }
  valuePerUnit: { v?: string }
}

export function totalInsuredValueFormatter (totalInsuredValue: TotalInsuredValue): string {
  if (!totalInsuredValue) return ''
  const compositeTIV = totalInsuredValue.compositeTIV.v
  const totalTIV = totalInsuredValue.totalTIV.v
  const trailerInterchangeResponse = totalInsuredValue.trailerInterchangeResponse.v
  const powerUnits = totalInsuredValue.powerUnits.v || 0
  const limit = totalInsuredValue.limit.v || '0.00'
  const nonOwnedTrailersResponse = totalInsuredValue.nonOwnedTrailersResponse.v
  const nonOwnedTrailers = totalInsuredValue.nonOwnedTrailers.v || 0
  const valuePerUnit = totalInsuredValue.valuePerUnit.v || '0.00'
  const nounTrailers = +nonOwnedTrailers === 1 ? 'Trailer' : 'Trailers'

  if (!compositeTIV) return formatValue(totalTIV, 'currency')
  let TIV = formatValue(compositeTIV, 'currency')
  const extras = []
  if (trailerInterchangeResponse) {
    extras.push(`${powerUnits} x Trailer Interchange @ USD ${formatValue(limit, 'currency').replace('$', '')}`)
  }
  if (nonOwnedTrailersResponse) {
    extras.push(`${nonOwnedTrailers} x Non-Owned ${nounTrailers} @ USD ${formatValue(valuePerUnit, 'currency').replace('$', '')}`)
  }
  if (extras.length) TIV += ` (includes ${extras.join(' and ')})`
  return TIV
}

const STATE_DISCLOSURES = {
  AL: 'REF 9022 - Alabama Surplus Lines Notice',
  AK: 'REF 9025A - Alaska Surplus Lines Notice',
  AZ: 'REF 9027B - Arizona Surplus Lines Notice',
  AR: 'REF 9028 - Arkansas Surplus Lines Notice',
  CA: 'REF 9030 - California Surplus Lines Notice 2',
  CO: 'REF 9031 - Colorado Surplus Lines Notice',
  CT: 'REF 9035A - Connecticut Surplus Lines Notice',
  DE: 'REF 9036 - Delaware Surplus Lines Notice',
  FL: 'REF 9037 - Florida Surplus Lines Notice (Guaranty Act)',
  GA: 'REF 9042 - Georgia Surplus Lines Notice',
  HI: 'REF 9044 - Hawaii Surplus Lines Notice',
  ID: 'REF 9045 - Idaho Surplus Lines Notice',
  IL: 'REF 9046 - Illinois Surplus Lines Notice',
  IA: 'REF 9047A - Iowa Surplus Lines Notice',
  KS: 'REF 9048 - Kansas Surplus Lines Notice',
  KY: 'REF 9092 - Kentucky Surplus Lines Notice',
  LA: 'REF 9049A - Louisiana Surplus Lines Notice',
  ME: 'REF 9050 - Maine Surplus Lines Notice',
  MD: 'REF 9051 - Maryland Surplus Lines Notice',
  MA: 'REF 9054 - Massachusetts Surplus Lines Notice',
  MI: 'REF 9055 - Michigan Surplus Lines Notice',
  MN: 'REF 9056 - Minnesota Surplus Lines Notice',
  MS: 'REF 9057 - Mississippi Surplus Lines Notice',
  MO: 'REF 9058 - Missouri Surplus Lines Notice',
  MT: 'REF 9059 - Montana Surplus Lines Notice',
  NE: 'REF 9060 - Nebraska Surplus Lines Notice',
  NV: 'REF 9061 - Nevada Surplus Lines Notice',
  NH: 'REF 9062 - New Hampshire Surplus Lines Notice',
  NJ: 'REF 9063 - New Jersey Surplus Lines Notice',
  NM: 'REF 9065 - New Mexico Surplus Lines Notice',
  NY: 'REF 9067 - New York Surplus Lines Notice',
  NC: 'REF 9068 - North Carolina Surplus Lines Notice',
  ND: 'REF 9069 - North Dakota Surplus Lines Notice',
  OH: 'REF 9070 - Ohio Surplus Lines Notice',
  OK: 'REF 9071 - Oklahoma Surplus Lines Notice',
  OR: 'REF 9072 - Oregon Surplus Lines Notice',
  PA: 'REF 9073 - Pennsylvania Surplus Lines Notice',
  PR: 'REF 9074 - Puerto Rico Surplus Lines Notice',
  RI: 'REF 9075 - Rhode Island Surplus Lines Notice',
  SC: 'REF 9076A - South Carolina Surplus Lines Notices',
  SD: 'REF 9077 - South Dakota Surplus Lines Notice',
  TN: 'REF 9078 - Tennessee Surplus Lines Notice',
  TX: 'REF 9079 (amended 2021) - Texas Surplus Lines Notice',
  VI: 'REF 9081A - US Virgin Islands Surplus Lines Notice',
  UT: 'REF 9082 - Utah Surplus Lines Notice',
  VT: 'REF 9083 - Vermont Surplus Lines Notice',
  VA: 'REF 9084 - Virginia Surplus Lines Notice',
  WA: 'REF 9085 - Washington Surplus Lines Notice',
  WV: 'REF 9087 - West Virginia Surplus Lines Notice (Guaranty Act)',
  WI: 'REF 9089 - Wisconsin Surplus Lines Notice',
  WY: 'REF 9090B - Wyoming Surplus Lines Notice',
}

const STATE_EXTRA_DISCLOSURES: Record<string, string> = {
  AK: 'REF 9026 - Alaska Notice of Non-Renewal and Premium Increase',
  CA: 'REF 9098B - California Surplus Lines Notice 1 (Post Bind)',
  CO: 'REF 9033 - Colorado Notice for Automobile Policies not providing the minimum coverage required under Colorado Law',
  FL: 'REF 9038 - Florida Surplus Lines Notice (Rates and Forms)',
  TX: 'REF 9080D - Texas Complaints Notice (Surplus Lines)',
}

export const createMap: CreateMapFn = () => {
  const totalInsuredValueRaw = computed<TotalInsuredValue>(() => {
    const appContextStore = useAppContextStore()
    const basePath = 'nestedResponseObjects.'
    return {
      compositeTIV: get(appContextStore, `${basePath}coverages.compositeTIV`) || {},
      totalTIV: get(appContextStore, `${basePath}coverages.totalTIV`) || {},
      trailerInterchangeResponse: get(appContextStore, `${basePath}coverages.trailerInterchange.response`) || {},
      powerUnits: get(appContextStore, `${basePath}coverages.trailerInterchange.numberPowerUnits`) || {},
      limit: get(appContextStore, `${basePath}coverages.trailerInterchange.limit`) || {},
      nonOwnedTrailersResponse: get(appContextStore, `${basePath}vehicles.nonOwnedTrailers.response`) || {},
      nonOwnedTrailers: get(appContextStore, `${basePath}vehicles.nonOwnedTrailers.count`) || {},
      valuePerUnit: get(appContextStore, `${basePath}vehicles.nonOwnedTrailers.valuePerUnit`) || {},
    } as TotalInsuredValue
  })
  const ratingVersion = computed(() => {
    const appContextStore = useAppContextStore()
    return get(appContextStore, 'nestedResponseObjects.coverages.AOP.band4.min.v') ? 2 : 1
  })
  const hasExcludedDrivers = computed(() => {
    const additionalDrivers = (getQuoteResponse('driversOutsideCriteriaAdditional', true) as PayloadObject[]) || []
    if (additionalDrivers) {
      if (additionalDrivers.some((driver) => (driver.excludeDriver as PayloadResponse).get())) return true
    }
    const excludedDrivers = (getNestedResponse('vehicles.excludedDrivers', true) as PayloadObject[]) || []
    if (excludedDrivers) {
      if (excludedDrivers.length) return true
    }
    return false
  })
  const deductibleWording = computed(() => {
    const appContextStore = useAppContextStore()
    if (['DRAFT', 'REVIEW', 'QUOTED'].includes(appContextStore.status!)) return null
    let wording = 'Deductible:'
    for (let i = 0; i <= BCAPD_BAND_COUNT; i++) {
      const deductible = getQuoteResponse(`aop.band${i}.deductible`)
      if (!deductible) continue
      const basePath = `nestedResponseObjects.coverages.AOP.band${i}.`
      const min = get(appContextStore, `${basePath}min.v`)
      let max: number | null | undefined = get(appContextStore, `${basePath}max.v`)
      if (ratingVersion.value === 1) {
        if (i === 1) max = null
        if (i > 1) continue
      }
      let lineItem = `\n${formatValue(deductible, 'noDecimalCurrencyUSD')} each loss, each automobile, all perils in respect of automobiles valued `
      if (!min) lineItem += `up to ${formatValue(max, 'noDecimalCurrencyUSD')}`
      else if (!max) lineItem += `${formatValue(min, 'noDecimalCurrencyUSD')} and over`
      else lineItem += `from ${formatValue(min, 'noDecimalCurrencyUSD')} to ${formatValue(max, 'noDecimalCurrencyUSD')}`
      wording += lineItem
    }
    const additionalDrivers = (getQuoteResponse('driversOutsideCriteriaAdditional', true) as { excludeDriver: PayloadResponse, deductible: PayloadResponse, fullName: PayloadResponse }[]) || []
    if (additionalDrivers) {
      additionalDrivers.forEach((driver) => {
        // include deductible wording line for every included driver
        if (!driver.excludeDriver.get()) {
          wording += `\n ${formatValue(driver.deductible.get(), 'noDecimalCurrencyUSD')} each loss in respect of driver named ${driver.fullName.get()}`
        }
      })
    }
    return wording
  })
  const aopVehiclesRaw = computed<NestedResponseObject[]>(() => {
    const appContextStore = useAppContextStore()
    const diffStore = useDiffStore()
    const aopVehicles = get(appContextStore, 'nestedResponseObjects.vehicles.vehicleDetails', [])
    const combined = combineRemovedArrayItems(aopVehicles as NestedResponseObject[], 'vehicles.vehicleDetails', diffStore.nestedRemovedDiffs)!
    // TODO: This should probably be a global feature of `combineRemovedArrayItems`
    const currentAppId = get(appContextStore, 'loadedApplicationData.id', 0)
    return combined.filter((value) => {
      if (get(value, [ARRAY_DELETION_KEYS.DELETED, 'v'], false)) {
        return get(value, [ARRAY_DELETION_KEYS.DELETED_ID, 'v'], null) === currentAppId
      }
      return true
    })
  })
  function createAOPBands (): Record<string, ComputedRef<string>> {
    const computedMap = {}
    for (let i = 0; i < BCAPD_BAND_COUNT; i++) {
      const computedName = `aopBand${i}`
      const computedDiffMapName = `${computedName}DiffMap`
      const bandLabelName = `aopBand${i}Label`
      const basePath = `nestedResponseObjects.coverages.AOP.band${i}.`
      const bandMap = {
        [computedName]: computed(() => {
          const appContextStore = useAppContextStore()
          if (!aopVehiclesRaw.value) return []
          let min: number | null | undefined = get(appContextStore, `${basePath}min.v`)
          let max: number | null | undefined = get(appContextStore, `${basePath}max.v`)
          if (!min && !max) return []
          if (ratingVersion.value === 1) {
            if (i === 1) max = INFINITY
            if (i > 1) return []
          }
          if (!min) min = 0
          if (!max) max = INFINITY
          return aopVehiclesRaw.value.filter((v) => {
            const value = +((v.value as APIPayloadResponse).v as string)
            return value >= min! && value <= max!
          })
        }),
        [computedDiffMapName]: computed(() => {
          const diffStore = useDiffStore()
          return diffStore.diffMap
        }),
        [bandLabelName]: computed(() => {
          const appContextStore = useAppContextStore()
          if (ratingVersion.value === 1) {
            if (i === 1) return '$100,000 +'
            if (i > 1) return ''
          }
          const min = get(appContextStore, `${basePath}min.v`) || 0
          const max = get(appContextStore, `${basePath}max.v`) || INFINITY
          if (!min && max === INFINITY) return ''
          if (max === INFINITY) return `${formatValue(min, 'currency')} +`
          return `${formatValue(min, 'currency')} - ${formatValue(max, 'currency')}`
        }),
      }
      Object.assign(computedMap, bandMap)
    }
    return computedMap
  }
  const additionalNamedInsuredWording = computed(() => {
    const additionalNamed = (getNestedResponse('additionalNamedInsured', true) as PayloadObject[]) || []
    if (additionalNamed.length === 0) return null
    let wording = 'Additional Named Insured - '
    const names: string[] = []
    additionalNamed.forEach((named) => {
      const additionalNamedInsuredName = (named.name as PayloadResponse<string>).get()
      if (additionalNamedInsuredName) {
        names.push((additionalNamedInsuredName))
      }
    })
    wording += names.join(', ')
    return wording
  })
  return {
    ...createAddressComputedMap('physicalAddress', { path: 'physicalAddress', source: 'responses' }),
    ...createArrayComputedMap('commodityType', 'operations.comType', null, {
      agricultural: 'Agricultural',
      autoHaulers: 'Auto Haulers',
      boatHaulers: 'Boat Haulers',
      business: 'Business',
      coalHaulers: 'Coal Haulers',
      contractors: 'Contractors & Construction',
      courier: 'Courier (Packaging Service) / Mail',
      dryGoods: 'Dry Goods',
      dump: 'Dump',
      garbageHaulers: 'Garbage / Refuse Haulers',
      glass: 'Glass',
      householdGoods: 'Household Goods (Movers)',
      intermodal: 'Intermodal / Containers',
      livery: 'Livery',
      livestockHaulers: 'Livestock Haulers',
      logging: 'Logging',
      machinery: 'Machinery & Equipment',
      military: 'Military Equipment',
      milk: 'Milk',
      mobileHome: 'Mobile Home Hauler',
      oversizeOverweight: 'Oversize / Overweight',
      paper: 'Paper / Plastic',
      refrigerated: 'Refrigerated',
      tankerHazmat: 'Tanker (Hazmat)',
      tankerNonHazmat: 'Tanker (Non-Hazmat)',
    }),
    totalInsuredValueRaw,
    totalInsuredValue: computed(() => {
      return { v: totalInsuredValueFormatter(totalInsuredValueRaw.value) }
    }),
    totalInsuredValueDiff: computed(() => {
      const diffStore = useDiffStore()
      return extractObjectDiffs(
        // @ts-expect-error: Index signatures do not match
        totalInsuredValueRaw.value,
        diffStore.diffMap,
        totalInsuredValueFormatter,
        undefined,
        true,
      )
    }),
    hasExcludedDrivers,
    deductibleWording,
    wordings: computed<{ name: { v: string | null } }[]>(() => {
      const quoteState = getQuoteResponse('sld.state') as keyof typeof STATE_DISCLOSURES & keyof typeof STATE_EXTRA_DISCLOSURES
      const gliseWording = (() => {
        let wording = 'GLISE APD Wording (03/19)'
        const exclusions: string[] = []
        if (getNestedResponse('operations.comType.livery')) {
          exclusions.push('4(iv) deleted')
        }
        if (exclusions.length) {
          wording += ` - Exclusion ${exclusions.join(', exclusion ')}`
        }
        return wording
      })()
      const effectiveDate = getQuoteResponse('effectiveDate')
      let stateDisclosure = STATE_DISCLOSURES[quoteState] || null
      if (effectiveDate && effectiveDate < '2021-07-01' && quoteState === 'WY') {
        stateDisclosure = 'REF 9090A Wyoming Surplus Lines Notice'
      }
      const extraDisclosures = { ...STATE_EXTRA_DISCLOSURES }
      const totalPremium = getQuoteResponse('totalPremium') ?? '0.00'
      if ((typeof totalPremium === 'string' && Big(totalPremium).lte(Big('5000.00'))) ||
      checkDisplayIf({ path: 'isEndorsement', source: 'store', store: 'appContext', permittedValues: [true] })) {
        extraDisclosures.GA = 'REF 9043 - Georgia Surplus Lines Notice (Standard Disclosure Brochure)'
      }

      return [
        { name: { v: stateDisclosure || null } },
        { name: { v: extraDisclosures[quoteState] || null } },
        { name: { v: checkDisplayIf({ path: 'floridaSecondaryForm.response', source: 'quote', permittedValues: [true] }) ? 'REF 9041 - Florida Surplus Lines Notice (Private Passenger)' : null } },
        { name: { v: gliseWording } },
        { name: { v: 'B&C RCEC 2023 04 – Radioactive Contamination Exclusion Clause' } },
        { name: { v: 'REF 2342 - Seepage and / or Contamination Exclusion' } },
        { name: { v: checkDisplayIf({ path: 'effectiveDate', source: 'responses', comparator: { name: 'lessThan', value: '2020-01-01' } }) ? 'REF 2915 - Electronic Data Endorsements' : null } },
        { name: { v: 'REF 2920a - Terrorism Exclusion' } },
        { name: { v: 'REF 2962 - Biological or Chemical Materials Exclusions' } },
        { name: { v: 'REF 3100 - Sanction Limitation and Exclusion Clause' } },
        { name: { v: checkDisplayIf({ path: 'effectiveDate', source: 'responses', comparator: { name: 'greaterThanOrEqual', value: '2020-01-01' } }) ? 'REF 5401 - Property Cyber and Data Exclusion' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf({ operator: 'OR', conditions: [{ path: 'state', source: 'responses', permittedValues: ['California'] }, { path: 'physicalAddress.state', source: 'responses', permittedValues: ['California'] }] }) ? 'REF 9191 GLISE -- CCPA Privacy Policy' : null } },
        { name: { v: checkDisplayIf({ path: 'effectiveDate', source: 'responses', comparator: { name: 'greaterThanOrEqual', value: '2020-01-01' } }) ? 'REF 9151 - Data Protection Short Form Information Notice' : null } },
        { name: { v: 'Insuring Agreement 2 – Basis of Valuation: Actual Cash Value' } },
        { name: { v: deductibleWording.value } },
        { name: { v: 'Protection of Salvage, Recovery and Storage – Limit USD 5,000 – as per Condition 9 of the wording' } },
        { name: { v: 'Salvage Clause, as per wording' } },
        { name: { v: 'Total or Constructive Total Loss, as per wording' } },
        { name: { v: 'Cancellation Clause (30 days Cancellation, except for non-payment of premium being 10 days), as per wording' } },
        { name: { v: 'Service of Suit Clause, as per wording' } },
        { name: { v: 'Loss Payee(s) are automatically added hereon as their interest may appear' } },
        { name: { v: additionalNamedInsuredWording.value } },
        { name: { v: 'Claim Notification Clause (USA) – Insurers to appoint Adjusters' } },
        { name: { v: checkDisplayIf({ path: 'minimumEarnedPremium.premium', source: 'quote', restrictUndefined: true, restrictedValues: [null, 0, '0'] }) ? 'Minimum Premium Earned Clause' : null } },
        { name: { v: 'Driver Criteria Conditions Endorsement (B&C DCCE 2020/01)' } },
        { name: { v: checkDisplayIf({ path: 'refundBonusClause.response', source: 'quote', permittedValues: [true] }) ? 'Refund Bonus Clause' : null } },
        { name: { v: checkDisplayIf({ path: 'coverages.trailerInterchange.response', source: 'responses', permittedValues: [true] }) ? 'Trailer Interchange Endorsement' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([{ path: 'coverages.hiredVehicleCoverage.response', source: 'responses', permittedValues: [true] }, { path: 'coverages.hiredVehicleCoverage.reason', source: 'responses', permittedValues: ['Replacement of scheduled unit'] }]) ? 'Hired/Non-Owned Replacement Power Unit Coverage Endorsement' : null } },
        // eslint-disable-next-line @stylistic/max-len
        { name: { v: checkDisplayIf([{ path: 'coverages.hiredVehicleCoverage.response', source: 'responses', permittedValues: [true] }, { path: 'coverages.hiredVehicleCoverage.reason', source: 'responses', permittedValues: ['In addition to scheduled unit'] }]) ? 'Hired/Non-Owned Additional Power Unit Coverage Endorsement' : null } },
        { name: { v: checkDisplayIf({ path: 'monthlyReporting.response', source: 'quote', permittedValues: [true] }) ? 'Monthly Reporting / Adjustment Clause' : null } },
        { name: { v: checkDisplayIf({ path: 'unreportedDriversEndorsement.response', source: 'issue', permittedValues: [true] }) ? 'Unreported Drivers Endorsement' : null } },
        { name: { v: checkDisplayIf({ path: 'vehicles.nonOwnedTrailers.response', source: 'responses', permittedValues: [true] }) ? 'Non-Owned Trailer Endorsement' : null } },
        { name: { v: checkDisplayIf({ path: 'operations.comType.garbageHaulers', source: 'responses', permittedValues: [true] }) ? 'Hot Ash Toxic Waste Exclusion' : null } },
        { name: { v: checkDisplayIf({ path: 'operations.comType.garbageHaulers', source: 'responses', permittedValues: [true] }) ? 'Garbage Refuse Warranty' : null } },
        { name: { v: hasExcludedDrivers.value ? 'BC-TR3 Named Driver Exclusion' : null } },
        {
          name: {
            v: checkDisplayIf([
              { path: 'isCompany.response', source: 'responses', permittedValues: ['Individual Company'] },
              { path: 'effectiveDate', source: 'responses', comparator: { name: 'lessThan', value: '2020-01-01' } },
            ])
              ? 'GLISE Privacy Policy Statement'
              : null,
          },
        },
        { name: { v: checkDisplayIf({ path: 'coInsuranceClause.response', source: 'quote', permittedValues: [true] }) ? 'BC - TR12 Ref 362 (amended) 80% Coinsurance Clause (Actual Cash Value)' : null } },
      ]
    }),
    wordingsDiffMap: computed<DiffMap>(() => {
      return { added: {}, changed: {}, removed: {} }
    }),
    aopVehiclesRaw,
    // TODO: This can be removed after V1 of quoting fades away
    ratingVersion,
    ...createAOPBands(),
    aopBandHigh: computed(() => {
      const appContextStore = useAppContextStore()
      let total = 0
      for (const band of ['1', '2', '3', '4']) {
        total += Number(get(appContextStore, `nestedResponseObjects.coverages.AOP.band${band}.unitCount.v`) || 0)
      }
      return total
    }),
    effectiveDate: computed<{ v: string | null } | null>(() => {
      const appContextStore = useAppContextStore()
      if (!appContextStore.loadedApplicationData) return null
      const { effectiveDate, policyEffectiveDate } = appContextStore.loadedApplicationData
      const usedPolicyEffective = appContextStore.isInProgress ? null : policyEffectiveDate
      const effective = (usedPolicyEffective || effectiveDate || getIssueResponse('effectiveDate') || getQuoteResponse('effectiveDate') || null) as string | null
      return { v: effective }
    }),
    expirationDate: computed<{ v: string | null } | null>(() => {
      const appContextStore = useAppContextStore()
      if (!appContextStore.loadedApplicationData) return null
      const { expirationDate, policyExpirationDate } = appContextStore.loadedApplicationData
      const usedPolicyExpiration = appContextStore.isInProgress ? null : policyExpirationDate
      const expiration = (usedPolicyExpiration || expirationDate || getIssueResponse('expirationDate') || getQuoteResponse('expirationDate') || null) as string | null
      return { v: expiration }
    }),
  }
}
