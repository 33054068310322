/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const AgencyLayout = () => import('@/layouts/AgencyLayout.vue')
const AgencyListPage = () => import('@/pages/Agency/AgencyListPage.vue')
const AgencyDetailPage = () => import('@/pages/Agency/AgencyDetailPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/agency',
    meta: { requiresAuth: true },
    component: AgencyLayout,
    children: [
      {
        path: '',
        name: routeNames.AGENCY_LIST,
        component: AgencyListPage,
      },
      {
        path: ':id(\\d+)',
        name: routeNames.AGENCY_DETAIL,
        component: AgencyDetailPage,
        meta: { onBack: '-1' },
      },
    ],
  },
]

export default routeConfig
