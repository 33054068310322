import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import type { ThemeDefinition, VuetifyOptions } from 'vuetify'

const light: ThemeDefinition = {
  colors: {
    background: '#F9F9F9',
    darknavyblue: '#182C4F',
    navyblue: '#1A3056',
    panelblue: '#F3F7FC',
    faintblue: '#F0F7FF',
    alertblue: '#DDE8F6',
    lightblue: '#4A90E2',
    lightgrey: '#FAFAFA',
    subheadgrey: '#9B9B9B',
    placeholdergrey: '#C4C4C4',
    bordergrey: '#E5E5E5',
    alternaterow: '#F6F6F6',
    error: '#F17F74',
    warning: '#FCC35B',
    info: '#2196F3',
    success: '#55D19A',
    primary: '#1976D2',
    diffremoved: '#FAE1E1',
    diffadded: '#E1FAE3',
    disabled: '#EFEFEF',
  },
}
const dark: ThemeDefinition = {
  dark: true,
  colors: {
    ...light.colors,
    background: '#212121',
    panelblue: '#0A2832',
    faintblue: '#243447',
    lightgrey: '#393939',
    bordergrey: '#7C7C7C',
    alternaterow: '#444444',
    disabled: '#101010',
  },
}
const pink: ThemeDefinition = {
  colors: {
    ...light.colors,
    darknavyblue: '#990052',
    navyblue: '#A60059',
  },
}

export const opts: VuetifyOptions = {
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'light',
    variations: {
      colors: ['navyblue', 'success', 'info', 'warning'],
      lighten: 3,
      darken: 3,
    },
    themes: {
      light,
      dark,
      pink,
    },
  },
  defaults: {
    VAutocomplete: {
      variant: 'underlined',
      color: 'primary',
      itemTitle: 'text',
      itemValue: 'value',
    },
    VBtn: {
      variant: 'elevated',
    },
    VCard: {
      class: 'bg-surface',
    },
    VCheckbox: {
      color: 'primary',
    },
    VCombobox: {
      variant: 'underlined',
      color: 'primary',
    },
    VDataTable: {
      hover: true,
    },
    VDataTableServer: {
      hover: true,
    },
    VMenu: {
      transition: 'fade-transition',
    },
    VProgressLinear: {
      color: 'primary',
    },
    VRadio: {
      color: 'primary',
    },
    VSelect: {
      variant: 'underlined',
    },
    VSwitch: {
      color: 'primary',
    },
    VTabs: {
      color: 'primary',
    },
    VTextarea: {
      variant: 'solo',
    },
    VTextField: {
      variant: 'underlined',
      color: 'primary',
    },
    VToolbar: {
      color: 'surface',
    },
  },
}

export const vuetify = createVuetify(opts)
