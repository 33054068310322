<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-avatar
      v-bind="mergeProps($attrs, props)"
      class="avatar-view"
      :class="{ 'dashed-border': !seed }"
      :color="avatarColor"
      :size="size"
    >
      <v-img
        v-if="avatarUrl"
        :src="avatarUrl"
        class="profile-image"
        :style="borderWidth ? { 'border-width': borderWidth } : {}"
      />
      <span
        v-else-if="truncatedName"
        class="text-white font-light"
        :class="fontClass"
        v-text="truncatedName"
      />
      <v-overlay
        :model-value="isEditable && (isHovering || showWhenEditable)"
        contained
        persistent
        :close-on-content-click="false"
        class="avatar--overlay"
      >
        <v-icon
          id="avatar--edit"
          color="white"
          icon="mdi-pencil"
          @click="editProfilePhoto"
        />
        <input
          ref="fileOpenButton"
          type="file"
          style="display: none;"
          @change="onFileChange"
        >
      </v-overlay>
    </v-avatar>
  </v-hover>
</template>

<script lang="ts">
import { defineComponent, mergeProps } from 'vue'

import type { PropType } from 'vue'

export default defineComponent({
  name: 'Avatar',

  inheritAttrs: false,

  emits: {
    fileChange: (_: Event) => true,
  },

  props: {
    first: { type: String, default: '' },
    last: { type: String, default: '' },
    avatarUrl: { type: String as PropType<string | null>, default: null },
    seed: { type: Number as PropType<number | null>, default: null },
    borderWidth: { type: [String, Number], default: 0 },
    size: { type: Number, default: 44 },
    fontClass: { type: String, default: '' },
    isEditable: { type: Boolean, default: false },
    showWhenEditable: { type: Boolean, default: false },
  },

  setup () {
    return {
      mergeProps,
    }
  },

  computed: {
    avatarColor (): string {
      if (this.seed === null && this.avatarUrl === null) return 'transparent'
      // color selection for avatar backgrounds
      const colors = [
        '#196A9C',
        '#147AB9',
        '#61308A',
        '#9B1D63',
        '#D3135E',
        '#EC5D34',
        '#F2A547',
      ]
      return this.avatarUrl
        ? 'white'
        : colors[this.seed! % colors.length]
    },
    truncatedName (): string {
      return this.first.substring(0, 1).toUpperCase() + this.last.substring(0, 1).toUpperCase()
    },
  },

  methods: {
    editProfilePhoto (): void {
      const input = this.$refs.fileOpenButton as HTMLInputElement | null
      if (input) input.click()
    },
    onFileChange (e: Event): void {
      this.$emit('fileChange', e)
    },
  },
})
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="sass">
.avatar-badge
  .dashed-border
    border: dashed 1px grey !important
  .avatar-view
    position: relative
    .profile-image
      width: 100%
      height: 100%
      border-radius: 50%
      background-repeat: no-repeat
      background-position: center
      background-size: cover
      position: relative
    &.account
      border-width: 4px
.avatar--overlay
  align-items: center
  justify-content: center
</style>
