import {
  CheckVersionRequest,
  PatrickServiceClient,
  CheckVersionResponse,
} from '@policyfly/protobuf/patrick'

import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface VersionApiEndpoints {
  /**
   * Sets up a stream to check the version of the application.
   */
  check: () => Promise<CheckVersionResponse>
}

/**
 * Creates endpoints related to the Application workflow.
 */
export const createVersionEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<VersionApiEndpoints> => {
  const patrickServiceClient = new PatrickServiceClient(params.transportAnonymous)

  const check: VersionApiEndpoints['check'] = async function () {
    const request = CheckVersionRequest.create()
    const { response } = await patrickServiceClient.checkVersion(request)
    devtools.logGrpc({
      logLevel: 'debug',
      description: 'Check Version',
      messages: [
        { type: CheckVersionRequest, key: 'request', message: request },
        { type: CheckVersionResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const endpoints: VersionApiEndpoints = {
    check,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
