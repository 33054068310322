import { defineStore } from 'pinia'

import { useAppContextStore } from '@/stores/appContext'

import { api } from '@/api'
import Payload from '@/lib/Payload'
import { hotReloadStore } from '@/utils/build'

import type { APIPayloadResponse } from '@policyfly/utils/types'

export const useLicenseStore = defineStore({
  id: 'license',

  state: () => ({
    responses: [] as APIPayloadResponse[],
  }),

  getters: {
    licensePayload: (state) => Payload.fromResponses(state.responses),
  },

  actions: {
    async update ({ data }: { data: { responses: APIPayloadResponse[] } }) {
      const appContextStore = useAppContextStore()
      const id = appContextStore.loadedApplicationData.id!
      const res = await api.applications.license({ path: { pk: id }, body: { data } })
      return appContextStore.loadApplicationData(res.data.Application)
    },
  },
})

hotReloadStore(useLicenseStore)
