import axios from 'axios'

import type { AxiosResponse } from 'axios'
import type { APIResponse, ResponseLike, APIRequest, Method } from 'types/api'
import type { paths } from 'types/swagger'

type APIPath = keyof paths
type PathLike<T extends Method, U extends APIPath> = paths[U] & { [K in T]: ResponseLike<unknown> }
type ValidMethod<P extends APIPath> = Method & keyof paths[P]

/**
 * A Promise with the extra `abort` method to cancel the request.
 * The generic is wrapped in {@link AxiosResponse}.
 */
export interface AxiosResponsePromise<T> extends Promise<AxiosResponse<T>> {
  /**
   * If called will cancel the request.
   */
  abort: AbortController['abort']
}

export function replacePath (path: string, params: Record<string, string | number>): string {
  let newPath = path.replace('/api/v1', '')
  const pathParams = newPath.matchAll(/\{(.*?)\}/g)
  for (const param of pathParams) {
    const [fullMatch, paramName] = param
    newPath = newPath.replace(fullMatch, String(params[paramName]))
  }
  return newPath
}

export function request<P extends APIPath, M extends ValidMethod<P>, V extends PathLike<M, P>, Params extends APIRequest<M, V>> (
  method: M,
  path: P,
  transformParams?: (params: Params) => void,
): (params: Params, config?: { baseURL?: string }) => AxiosResponsePromise<APIResponse<V[M]>> {
  return (params, config) => {
    const controller = new AbortController()
    transformParams && transformParams(params)
    const responsePromise = axios.request({
      method,
      url: replacePath(path, params.path as Record<string, string | number>),
      data: params.body,
      params: params.query,
      baseURL: config?.baseURL,
      signal: controller.signal,
    }) as AxiosResponsePromise<APIResponse<V[M]>>
    responsePromise.abort = controller.abort.bind(controller)
    return responsePromise
  }
}
