import {
  createAddressComputedMap,
  createArrayComputedMap,
  createLocationArrayComputedMap,
} from '@/utils/diffHelpers'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createAddressComputedMap('mailingAddress', { path: 'mailingAddress', source: 'responses' }),
    ...createLocationArrayComputedMap('coapplicantsArray', 'coapplicants.items'),
    ...createAddressComputedMap('trustAddress', { path: 'trust.address', source: 'responses' }),
    ...createLocationArrayComputedMap('mortgageeArray', 'mortgagees.items'),
    ...createLocationArrayComputedMap('additionalInsuredArray', 'additionalNamedInsured'),
    ...createArrayComputedMap('poolTypes', 'propertyData.poolType', null, {
      swimmingPool: 'Swimming Pool',
      hotTub: 'Hot Tub',
    }),
  }
}
