import { defineStore } from 'pinia'

import { hotReloadStore } from '@/utils/build'

export const useBridgeStore = defineStore({
  id: 'bridge',

  state: () => ({
    errors: [] as string[],
    warnings: [] as string[],
    action: {} as {
      text?: string
      to?: string
      params?: Record<string, string>
    },
  }),

  actions: {
    clearErrors (): void {
      this.errors = []
      this.action = {}
    },
  },
})

hotReloadStore(useBridgeStore)
