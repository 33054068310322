import { normalizePath } from '@policyfly/utils/string'
import set from 'lodash-es/set'
import { defineStore } from 'pinia'

import { useApiStore } from './api'
import { hotReloadStore } from '@/utils/build'
import { removeInvalidDeletedDiffs } from '@/utils/diffHelpers'

import type { NestedResponseObject } from '@/lib/Payload'
import type { DiffMap, DiffData } from 'types/application'
import type { NamedDriversDiff, VehiclesDiff, PlatesDiff } from 'types/programData/apd'

export interface DiffState {
  diffData: DiffData | null
  loadedID: number | null
  targetID: number | null
  loading: boolean
  showSummary: boolean
  appDiffs: {
    namedDriversDiff: NamedDriversDiff | null
    driverDiff: DriverAppDiff | Record<string, DriverAppDiff> | null
    vehicleDiff: VehicleAppDiff | Record<string, VehicleAppDiff> | null
    plateDiff: PlateAppDiff | Record<string, PlateAppDiff> | null
    vehicleDiffEnglish: string | null
  }
}

export interface VehicleAppDiff {
  vehicles: VehiclesDiff
  deletedVehicles: VehiclesDiff
}

export interface DriverAppDiff {
  drivers: NamedDriversDiff
  deletedDrivers: NamedDriversDiff
}

export interface PlateAppDiff {
  plates: PlatesDiff
  deletedPlates: PlatesDiff
}

export const useDiffStore = defineStore({
  id: 'diff',

  state: (): DiffState => ({
    diffData: null,
    loadedID: null,
    targetID: null,
    loading: false,
    showSummary: false,
    appDiffs: {
      namedDriversDiff: null,
      driverDiff: null,
      vehicleDiff: null,
      plateDiff: null,
      vehicleDiffEnglish: null,
    },
  }),

  getters: {
    vehicleDiffEnglish (state): string | null {
      return state.appDiffs.vehicleDiffEnglish
    },
    diffMap ({ diffData }): DiffMap | null {
      if (!diffData) return null
      const UUIDmap: DiffMap = {
        added: {},
        changed: {},
        removed: {},
      }
      for (const el of diffData.added) {
        // we only really need the v in the added diffMap but we include the whole object for consistency
        UUIDmap.added[el.uuid4] = el
      }
      const arrayPathRegex = /_\d+_/
      for (const el of diffData.changed) {
        const { k, v } = el.changed
        const [k1, k2] = k
        const arrName1 = k1.split(arrayPathRegex)[0]
        const arrName2 = k2.split(arrayPathRegex)[0]
        if (arrName1 !== arrName2) {
          // this has moved from one array to another, that's an equivalent of an add and a remove
          UUIDmap.removed[el.uuid4] = { uuid4: el.uuid4, k: k[0], v: v[0] }
          UUIDmap.added[el.uuid4] = { uuid4: el.uuid4, k: k[1], v: v[1] }
        }
        // even if this has changed array we still record v changes in changed
        // synced arrays with a missing counterpart are then able to show a change because the add/remove above is then ignored
        if (v[0] !== v[1]) {
          UUIDmap.changed[el.uuid4] = { uuid4: el.uuid4, v }
        }
      }
      for (const el of diffData.removed) {
        // we need k in the removed diffMap for combining removed items in fields so we include the whole object
        UUIDmap.removed[el.uuid4] = el
      }
      return UUIDmap
    },
    /**
     * Transforms removed diffs into a NestedResponseObject so that we can combine arrays more easily, `k` is used to find where they should exist.
     *
     * Different removed array items are guaranteed to have different indexes because the keys used indicate their index on the Comparison App, not the Base.
     *
     * We also do some post-processing on this to remove invalid diffs.
     */
    nestedRemovedDiffs (): NestedResponseObject | null {
      if (!this.diffMap) return null
      const nestedDiffs: NestedResponseObject = {}
      Object.values(this.diffMap.removed).forEach((el) => {
        set(nestedDiffs, normalizePath(el.k), el)
      })
      removeInvalidDeletedDiffs(nestedDiffs)
      return nestedDiffs
    },
  },

  actions: {
    /**
     * Compare two Policies and set diffData to show changes
     */
    async compare (baseID: number | null, targetID: number | null): Promise<void> {
      const apiStore = useApiStore()

      if (!baseID || !targetID) {
        this.resetExceptSummary()
        return
      }
      if (this.loadedID !== baseID) this.resetExceptSummary()
      if (this.targetID === targetID) return

      this.loadedID = baseID
      this.targetID = targetID
      this.loading = true

      try {
        this.diffData = await apiStore.application.compare(baseID, targetID)
      } catch (err) {
        console.error(err)
        this.resetExceptSummary()
        throw err
      } finally {
        this.loading = false
      }
    },
    resetExceptSummary (): void {
      const summary = this.showSummary
      this.$reset()
      this.showSummary = summary
    },
  },
})

hotReloadStore(useDiffStore)
