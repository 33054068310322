import { markRaw } from 'vue'

import {
  useNotificationStore,
} from '@/stores/notification'

import type {
  ConfirmOptions,
  ConfirmOptionsDateOption,
  ConfirmOptionsDefault,
  ConfirmOptionsEndorsementCorrection,
  ConfirmOptionsManageAuthenticator,
  ConfirmOptionsManageSMS,
  ConfirmOptionsRadioOption,
  ConfirmOptionsTextField,
  ConfirmOptionsAddAgency,
  ConfirmOptionsAddAgent,
  ConfirmOptionsManageProgramsAgency,
  ConfirmOptionsManageProgramsUser,
} from '@/stores/notification'

export type SnackbarFunction = (m: string, t?: number, c?: boolean) => void
export interface Snackbar {
  default: SnackbarFunction
  error: SnackbarFunction
  info: SnackbarFunction
  success: SnackbarFunction
  warning: SnackbarFunction
}

function message (type: string, msg: string, timeout = 7000, close = true): void {
  const notificationStore = useNotificationStore()
  notificationStore.snackbar = {
    model: true,
    type,
    msg,
    timeout,
    close,
  }
}

const snackbar: Snackbar = markRaw({
  default: (m, t, c) => message('', m, t, c),
  error: (m, t, c) => message('error', m, t, c),
  info: (m, t, c) => message('info', m, t, c),
  success: (m, t, c) => message('success', m, t, c),
  warning: (m, t, c) => message('warning', m, t, c),
})

function userConfirm (payload: ConfirmOptionsDefault): Promise<true | false>
function userConfirm (payload: ConfirmOptionsAddAgency): Promise<{ extra: NonNullable<ConfirmOptionsAddAgency['defaults']> }>
function userConfirm (payload: ConfirmOptionsAddAgent): Promise<{ extra: NonNullable<ConfirmOptionsAddAgent['defaults']> }>
function userConfirm (payload: ConfirmOptionsManageProgramsAgency): Promise<{ extra: NonNullable<ConfirmOptionsManageProgramsAgency['defaults']> }>
function userConfirm (payload: ConfirmOptionsManageProgramsUser): Promise<{ extra: NonNullable<ConfirmOptionsManageProgramsUser['defaults']> }>
function userConfirm (payload: ConfirmOptionsManageSMS): Promise<{ extra: string } | false>
function userConfirm (payload: ConfirmOptionsManageAuthenticator): Promise<{ extra: string } | false>
function userConfirm (payload: ConfirmOptionsDateOption): Promise<{ extra: string } | false>
function userConfirm (payload: ConfirmOptionsEndorsementCorrection): Promise<{ extra: { start: string, end: string } } | false>
function userConfirm (payload: ConfirmOptionsRadioOption): Promise<{ extra: string } | false>
function userConfirm (payload: ConfirmOptionsTextField): Promise<{ extra: string } | false>
async function userConfirm (payload: ConfirmOptions): Promise<unknown> {
  const notificationStore = useNotificationStore()
  return notificationStore.confirm(payload)
}

interface UseNotificationReturnValue {
  snackbar: Snackbar
  userConfirm: typeof userConfirm
}

export function useNotification (): UseNotificationReturnValue {
  return {
    snackbar,
    userConfirm,
  }
}
