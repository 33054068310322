import { useNotification } from '@/composables/notification'

export const SPA_VERSION_KEY = 'spa-version'

export interface UseVersionReturnValue {
  /**
   * Checks that the SPA version has not changed.
   * If it has then an alert will appear to force them to refresh.
   */
  checkSpaVersion: (version: string | undefined) => Promise<void>
}

export function useVersion (): UseVersionReturnValue {
  const { userConfirm } = useNotification()
  let notificationDialog = false
  const checkSpaVersion: UseVersionReturnValue['checkSpaVersion'] = async (version) => {
    if (!version) return
    const spaVersions = ((): string[] | null => {
      try {
        const raw = sessionStorage.getItem(SPA_VERSION_KEY)
        if (!raw) return null
        const parsed = JSON.parse(raw)
        return Array.isArray(parsed) ? parsed : null
      } catch {
        return null
      }
    })()

    if (!spaVersions) {
      sessionStorage.setItem(SPA_VERSION_KEY, JSON.stringify([version]))
      return
    }

    if (spaVersions.includes(version)) return
    sessionStorage.setItem(SPA_VERSION_KEY, JSON.stringify([...spaVersions, version]))

    if (notificationDialog) return
    notificationDialog = true
    if (await userConfirm({
      title: 'PolicyFly has been updated',
      body: 'Please click Confirm to reload the page.',
      persistent: true,
      onlyConfirm: import.meta.env.VITE_DISMISS_UPDATE !== 'true',
    })) {
      location.reload()
    }
  }

  return {
    checkSpaVersion,
  }
}
