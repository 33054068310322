import { createArrayComputedMap } from '@/utils/diffHelpers'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createArrayComputedMap('storeLocations', 'applicantOperations.q5', 'response', {
      shoppingMall: 'Shopping Mall',
      standaloneStore: 'Standalone Store',
      stripCenter: 'Strip Center',
    }),
  }
}
