/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const ApplicationLayout = () => import('@/layouts/ApplicationLayout.vue')
const ApplicationEditPage = () => import('@/pages/Application/ApplicationEditPage.vue')
const SubmitQuotesPage = () => import('@/pages/Application/SubmitQuotesPage.vue')
const IssueBinderPage = () => import('@/pages/Application/IssueBinderPage.vue')
const IssuePolicyPage = () => import('@/pages/Application/IssuePolicyPage.vue')
const QuoteSignaturePage = () => import('@/pages/Application/QuoteSignaturePage.vue')
const FeedPage = () => import('@/pages/Application/FeedPage.vue')
const PrintPage = () => import('@/pages/Application/PrintPage.vue')
const ReviewPage = () => import('@/pages/Application/ApplicationReviewPage.vue')
const AttachmentsPage = () => import('@/pages/Application/AttachmentsPage.vue')
const PolicyDocsPage = () => import('@/pages/Application/PolicyDocsPage.vue')
const ReviewQuotesPage = () => import('@/pages/Application/ReviewQuotesPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    // optional applicationId prevents vue-router error incorrectly erroring on policy > application navigation
    // ApplicationLayout handles redirect if no ids are provided
    path: '/application/:applicationId(\\d+)?',
    meta: { requiresAuth: true, applicationLayout: true },
    component: ApplicationLayout,
    props: true,
    children: [
      {
        path: '',
        redirect: { name: routeNames.APPLICATION_FEED },
      },
      {
        path: 'edit',
        name: routeNames.APPLICATION_EDIT,
        component: ApplicationEditPage,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        name: routeNames.APPLICATION_REVIEW,
        path: 'details',
        component: ReviewPage,
        meta: { isTabsList: true },
      },
      {
        name: routeNames.APPLICATION_PRINT,
        path: 'print',
        component: PrintPage,
        meta: { isTabsList: false, hiddenFAB: true },
      },
      {
        path: 'quotes',
        name: routeNames.APPLICATION_REVIEW_QUOTES,
        component: ReviewQuotesPage,
        meta: { isTabsList: true },
      },
      {
        path: 'submitquotes',
        component: SubmitQuotesPage,
        name: routeNames.APPLICATION_SUBMIT_QUOTES,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        path: 'endorse',
        component: SubmitQuotesPage,
        name: routeNames.APPLICATION_SUBMIT_ENDORSEMENT_QUOTE,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        path: 'endorse/cancel',
        component: SubmitQuotesPage,
        name: routeNames.APPLICATION_SUBMIT_CANCELLATION_QUOTE,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        path: 'editpremium',
        component: SubmitQuotesPage,
        name: routeNames.APPLICATION_EDIT_PREMIUM,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        path: 'signature',
        name: routeNames.SIGNATURE,
        component: QuoteSignaturePage,
        meta: { hiddenFAB: true },
      },
      {
        path: 'issuebinder',
        component: IssueBinderPage,
        name: routeNames.APPLICATION_ISSUE_BINDER,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        path: 'issuepolicy',
        component: IssuePolicyPage,
        name: routeNames.APPLICATION_ISSUE_POLICY,
        meta: {
          isTabsList: false,
          hiddenFAB: true,
          form: true,
        },
      },
      {
        path: 'feed',
        redirect: { name: routeNames.APPLICATION_FEED },
      },
      {
        path: 'activity',
        component: FeedPage,
        name: routeNames.APPLICATION_FEED,
        meta: { isTabsList: true },
      },
      {
        path: 'attachments',
        name: routeNames.APPLICATION_ATTACHMENTS,
        component: AttachmentsPage,
        meta: { isTabsList: true },
      },
      {
        path: 'policy',
        name: routeNames.APPLICATION_POLICY_DOCS,
        component: PolicyDocsPage,
        meta: { isTabsList: true },
      },
    ],
  },
]

export default routeConfig
