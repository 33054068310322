import {
  ApplicationServiceClient,
  Policy as PolicyGrpc,
  PolicyServiceClient,
  RetrievePolicyRequest,
  User as CoreUser,
  Request,
  TransactionType,
  PolicyState,
  Kind,
  GenericStatus,
} from '@policyfly/protobuf'
import {
  PatrickServiceClient,
  GetPolicyWithStateRequest_Type,
  GetPolicyWithStateResponse,
  GetPolicyWithStateRequest,
} from '@policyfly/protobuf/patrick'
import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport'
import { RpcError } from '@protobuf-ts/runtime-rpc'

import { useApiStore } from '@/stores/api'
import { useProtobufStore } from '@/stores/protobuf'
import { useUserStore } from '@/stores/user'

import { getPolicyStateForApi } from './application'
import { api } from '@/api'
import { devtools } from '@/plugins/devtools/api'
import { policyGrpcToJson, policyStateToApplication } from '@/utils/protobuf'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type { APIApplication, APIApplicationRaw } from 'types/application'
import type { APIPolicy, Policy as PolicyJson } from 'types/policy'

interface ReadPolicyResponse {
  policy?: PolicyGrpc
  json: PolicyJson | APIPolicy
}

export interface PolicyApiEndpoints {
  /**
   * Finds & returns data about a Policy with the provided id.
   */
  read: (id: number) => Promise<ReadPolicyResponse>
  /**
   * Requests a policy along with an associated application.
   */
  readWithState: (request: GetPolicyWithStateRequest) => Promise<{
    policy?: PolicyGrpc
    policyJson: PolicyJson | APIPolicy
    policyState?: PolicyState
    policyStateJson: APIApplication | APIApplicationRaw
  }>
  /**
   * Transfer Policy to a new owner
   */
  transfer: (id: number, owner: CoreUser | null) => Promise<ReadPolicyResponse>
  /**
   * Reinstate a cancelled Policy
   */
  reinstate: (id: number) => Promise<ReadPolicyResponse>
  /**
   * Allow Policy to be renewed
   */
  renew: (id: number, offered: boolean) => Promise<ReadPolicyResponse>
}

/**
 * Creates endpoints related to the Policy.
 */
export const createPolicyEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<PolicyApiEndpoints> => {
  const policyServiceClient = new PolicyServiceClient(params.transport)
  const applicationServiceClient = new ApplicationServiceClient(params.transport)
  const patrickServiceClient = new PatrickServiceClient(params.transport)

  const readDjango: PolicyApiEndpoints['read'] = async (id) => {
    const res = await api.policies.read({ path: { pk: id }, query: {} })
    return {
      json: res.data.Policy,
    }
  }
  const readGrpc: PolicyApiEndpoints['read'] = async (id) => {
    const request = RetrievePolicyRequest.create({ id })
    const { response } = await policyServiceClient.retrievePolicy(request)
    const policy = response.policy!

    devtools.logGrpc({
      description: 'Read Policy',
      messages: [
        { type: RetrievePolicyRequest, key: 'request', message: request },
        { type: PolicyGrpc, key: 'response', message: policy },
      ],
    })

    return {
      policy,
      json: policyGrpcToJson(policy),
    }
  }

  const readWithStateDjango: PolicyApiEndpoints['readWithState'] = async (request) => {
    const apiStore = useApiStore()
    switch (request.type) {
      case GetPolicyWithStateRequest_Type.TYPE_POLICY: {
        const { json: policyJson } = await apiStore.policy.read(request.id)
        const displayAppId = policyJson.latest_issued_app?.id ?? policyJson.latest_application?.id
        const { json: policyStateJson } = await apiStore.application.read(displayAppId)
        return {
          policyJson,
          policyStateJson,
        }
      }
      default: {
        const { json: policyStateJson } = await apiStore.application.read(request.id)
        const { json: policyJson } = await apiStore.policy.read(policyStateJson.policy.id)
        return {
          policyJson,
          policyStateJson,
        }
      }
    }
  }
  const readWithStateGrpc: PolicyApiEndpoints['readWithState'] = async (request) => {
    const { response } = await patrickServiceClient.getPolicyWithState(request)

    devtools.logGrpc({
      description: 'Read Policy With State',
      messages: [
        { type: GetPolicyWithStateRequest, key: 'request', message: request },
        { type: GetPolicyWithStateResponse, key: 'response', message: response },
      ],
    })

    const { policy, policyState } = response
    if (!policy || !policyState) throw new RpcError('Policy or PolicyState not returned', GrpcStatusCode[GrpcStatusCode.NOT_FOUND])

    return {
      policy,
      policyJson: policyGrpcToJson(policy),
      policyState,
      policyStateJson: policyStateToApplication(policyState),
    }
  }

  const transferDjango: PolicyApiEndpoints['transfer'] = async (id, owner) => {
    const { data } = await api.policies.transfer({
      body: { new_owner_id: owner?.id ?? 0, send_notification: true, notification_type: 'TRANSFER' },
      path: { pk: id },
      query: {},
    })

    return {
      json: data.Policy,
    }
  }

  const transferGrpc: PolicyApiEndpoints['transfer'] = async (_id, owner) => {
    const userStore = useUserStore()
    const request = getPolicyStateForApi()
    request.txn = TransactionType.OWNERSHIP_CHANGE_TXN

    if (owner) {
      request.owner = CoreUser.create(owner)
    } else {
      delete request.owner
    }
    request.request = Request.create({ user: userStore.details })
    const { response } = await applicationServiceClient.submit(request)

    devtools.logGrpc({
      description: 'Transfer Policy',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return readGrpc(response.policy!.id)
  }

  const reinstateDjango: PolicyApiEndpoints['reinstate'] = async (id) => {
    const { data } = await api.policies.reinstate({ path: { pk: id }, body: {} })

    return {
      json: data.Policy,
    }
  }

  const reinstateGrpc: PolicyApiEndpoints['reinstate'] = async () => {
    const protobufStore = useProtobufStore()
    const request = getPolicyStateForApi()
    request.id = 0
    request.uuid = ''
    request.txn = TransactionType.REINSTATEMENT_TXN
    request.kind = Kind.KIND_REINSTATEMENT
    request.policy = PolicyGrpc.clone((protobufStore.policy ?? {}) as PolicyGrpc)

    const { response } = await applicationServiceClient.newApplication(request)

    devtools.logGrpc({
      description: 'Reinstate Policy',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return readGrpc(response.policy!.id)
  }

  const renewDjango: PolicyApiEndpoints['renew'] = async (id: number, offered: boolean) => {
    const { data } = await api.policies.forbid_renewal({ path: { pk: id }, body: { renewal_forbidden: offered ? false : null } })

    return {
      json: data.Policy,
    }
  }

  const renewGrpc: PolicyApiEndpoints['renew'] = async (_, offered: boolean) => {
    const request = {
      ...getPolicyStateForApi(),
      id: 0,
      uuid: '',
      kind: Kind.KIND_RENEWAL,
    }
    const { policy } = await readGrpc(request.policy!.id)
    request.policy = policy

    // Policy with already declined renewal use the reconsidered txn, otherwise use offered/declined
    if (policy!.status === GenericStatus.RENEWAL_DECLINED) {
      request.txn = TransactionType.RENEWAL_RECONSIDERED_TXN
    } else if (offered) {
      request.txn = TransactionType.RENEWAL_OFFERED_TXN
    } else {
      request.txn = TransactionType.RENEWAL_DECLINED_TXN
      // request.status = GenericStatus.RENEWAL_DECLINED
    }
    const { response } = await applicationServiceClient.newApplication(request)

    devtools.logGrpc({
      description: 'Offer Policy Renewal',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return readGrpc(response.policy!.id)
  }

  return {
    django: {
      read: readDjango,
      readWithState: readWithStateDjango,
      reinstate: reinstateDjango,
      renew: renewDjango,
      transfer: transferDjango,
    },
    grpc: {
      read: readGrpc,
      readWithState: readWithStateGrpc,
      reinstate: reinstateGrpc,
      renew: renewGrpc,
      transfer: transferGrpc,
    },
  }
}
