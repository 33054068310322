import get from 'lodash-es/get'
import { APD_KEY, MTC_KEY } from 'snippets/ap/common'
import { targetGoods } from 'snippets/bcmtc/common'
import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'
import { usePolicyStore } from '@/stores/policy'

import Payload from '@/lib/Payload'
import { extractVehicleChanges } from '@/utils/apdHelpers'
import { createAddressComputedMap, createArrayComputedMap, extractObjectDiffs, createLocationArrayComputedMap } from '@/utils/diffHelpers'
import { checkDisplayIf } from '@/utils/displayIf'
import { formatValue } from '@/utils/formatter'
import { loadParentApplicationChain } from '@/utils/policy'
import { createSignedProposalComputed } from '@/utils/programData'
import { getNestedResponse } from '@/utils/responses'

import type { PayloadArray, PayloadObject } from '@/lib/Payload'
import type { VehicleAppDiff } from '@/stores/diff'
import type { CreateMapFn } from '@/stores/programData'
import type { LoadableApp } from '@/utils/policy'

interface TotalInsuredValue {
  compositeTIV: { v?: string }
  totalTIV: { v?: string }
  trailerInterchangeResponse: { v?: string }
  powerUnits: { v?: string }
  limit: { v?: string }
  nonOwnedTrailersResponse: { v?: string }
  nonOwnedTrailers: { v?: string }
  valuePerUnit: { v?: string }
}

export function totalInsuredValueFormatter (totalInsuredValue: TotalInsuredValue): string {
  if (!totalInsuredValue) return ''
  const compositeTIV = totalInsuredValue.compositeTIV.v
  const totalTIV = totalInsuredValue.totalTIV.v
  const nonOwnedTrailersResponse = totalInsuredValue.nonOwnedTrailersResponse.v
  const nonOwnedTrailers = totalInsuredValue.nonOwnedTrailers.v || 0
  const valuePerUnit = totalInsuredValue.valuePerUnit.v || '0.00'
  const nounTrailers = +nonOwnedTrailers === 1 ? 'Trailer' : 'Trailers'

  if (!compositeTIV) return formatValue(totalTIV, 'currency')
  let TIV = formatValue(compositeTIV, 'currency')
  const extras = []
  if (nonOwnedTrailersResponse) {
    extras.push(`${nonOwnedTrailers} x Non-Owned ${nounTrailers} @ USD ${formatValue(valuePerUnit, 'currency').replace('$', '')}`)
  }
  if (extras.length) TIV += ` (includes ${extras.join(' and ')})`
  return TIV
}

export const createMap: CreateMapFn = () => {
  const totalInsuredValueRaw = computed<TotalInsuredValue>(() => {
    const appContextStore = useAppContextStore()
    const basePath = 'nestedResponseObjects.'
    return {
      compositeTIV: get(appContextStore, `${basePath}coverages.compositeTIV`) || {},
      totalTIV: get(appContextStore, `${basePath}coverages.totalTIV`) || {},
      trailerInterchangeResponse: get(appContextStore, `${basePath}coverages.trailerInterchange.response`) || {},
      powerUnits: get(appContextStore, `${basePath}coverages.trailerInterchange.numberPowerUnits`) || {},
      limit: get(appContextStore, `${basePath}coverages.trailerInterchange.limit`) || {},
      nonOwnedTrailersResponse: get(appContextStore, `${basePath}vehicles.nonOwnedTrailers.response`) || {},
      nonOwnedTrailers: get(appContextStore, `${basePath}vehicles.nonOwnedTrailers.count`) || {},
      valuePerUnit: get(appContextStore, `${basePath}vehicles.nonOwnedTrailers.valuePerUnit`) || {},
    } as TotalInsuredValue
  })
  const coverages = computed<{ APD: boolean, MTC: boolean }>(() => {
    const appContextStore = useAppContextStore()
    const currentCoverages = { APD: false, MTC: false }
    const selectedQuotePayload: Payload | null = appContextStore.selectedQuotePayload
    const nestedResponsePayload: Payload | null = appContextStore.nestedResponsePayload
    if (selectedQuotePayload) {
      currentCoverages.APD = !!selectedQuotePayload.get(APD_KEY)
      currentCoverages.MTC = !!selectedQuotePayload.get(MTC_KEY)
    } else if (nestedResponsePayload) {
      currentCoverages.APD = !!nestedResponsePayload.get(APD_KEY)
      currentCoverages.MTC = !!nestedResponsePayload.get(MTC_KEY)
    }
    return currentCoverages
  })
  const hasAPD = computed(() => {
    return coverages.value.APD
  })
  const hasMTC = computed(() => {
    return coverages.value.MTC
  })
  return {
    ...createAddressComputedMap('physicalAddress', { path: 'physicalAddress', source: 'responses' }),
    ...createAddressComputedMap('terminalAddress', { path: 'terminalAddress', source: 'responses' }),
    ...createArrayComputedMap('targetGoods', 'operations.targetGoods', 'response', targetGoods.reduce<Record<string, string>>((acc, t) => {
      acc[t.path] = t.label
      return acc
    }, {})),
    ...createLocationArrayComputedMap('terminalLocations', 'coverages.terminalLocations'),
    coverages,
    hasAPD,
    hasMTC,
    hasAPDNonRenew: computed(() => {
      return hasAPD.value && checkDisplayIf({ path: 'coverages.nonRenewCancellation.APD', source: 'responses', permittedValues: [true] })
    }),
    hasMTCNonRenew: computed(() => {
      return hasMTC.value && checkDisplayIf({ path: 'coverages.nonRenewCancellation.MTC', source: 'responses', permittedValues: [true] })
    }),
    // TODO: Replace with `keysEverEqualed` calculation
    hasPastHiredVehicleCoverage: computed(() => {
      const policyStore = usePolicyStore()
      const appContextStore = useAppContextStore()
      const applications = policyStore.applications as LoadableApp[]
      const apps = loadParentApplicationChain(applications, appContextStore.loadedApplicationData.parentApplicationID)
      for (const responses of apps) {
        const payload = Payload.fromResponses(responses)
        if (payload.get('coverages.hiredVehicleCoverage.response') === true) return true
      }
      return false
    }),
    driversOutsideCriteriaCombined: computed<PayloadObject[] | null>(() => {
      const appContextStore = useAppContextStore()
      const docApp = getNestedResponse('driversOutsideCriteria', true) as PayloadObject[] | null
      if (!docApp) return null
      // the 1st quote is fine, they all have the same DOC
      const quote = appContextStore.loadedApplicationData.quoteSet?.[0]
      if (!quote) return null
      const quotePayload = Payload.fromResponses(quote.responses)
      const docQuote = quotePayload.getWhole<PayloadObject[]>('driversOutsideCriteriaAdditional')
      if (!docQuote) return null
      return docApp.map((d, i) => {
        const dq = docQuote[i]
        if (!dq) return d
        return { ...d, ...dq }
      })
    }),
    totalInsuredValueRaw,
    totalInsuredValue: computed<{ v: string }>(() => {
      return { v: totalInsuredValueFormatter(totalInsuredValueRaw.value) }
    }),
    totalInsuredValueDiff: computed<ReturnType<typeof extractObjectDiffs>>(() => {
      const diffStore = useDiffStore()
      return extractObjectDiffs(
        // @ts-expect-error: Index signatures do not match
        totalInsuredValueRaw.value,
        diffStore.diffMap,
        totalInsuredValueFormatter,
        undefined,
        true,
      )
    }),
    activeAPDVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as Record<string, VehicleAppDiff> | null)?.APD?.vehicles)
    }),
    inactiveAPDVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as Record<string, VehicleAppDiff> | null)?.APD?.deletedVehicles)
    }),
    activeMTCVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as Record<string, VehicleAppDiff> | null)?.MTC?.vehicles)
    }),
    inactiveMTCVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as Record<string, VehicleAppDiff> | null)?.MTC?.deletedVehicles)
    }),
    hasSignedAPDProposal: createSignedProposalComputed('APD_PROPOSAL'),
    hasSignedMTCProposal: createSignedProposalComputed('MTC_PROPOSAL'),
  }
}
