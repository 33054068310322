<template>
  <v-menu
    v-bind="sharedMenuProps"
    :model-value="infoOpen"
    :open-on-hover="true"
    location="bottom end"
    @update:model-value="onInfoOpen"
  >
    <template #activator="{ props: infoProps }">
      <v-menu
        v-bind="sharedMenuProps"
        :model-value="searchOpen"
        location="bottom"
        min-width="300"
        max-height="400"
        @update:model-value="onSearchOpen"
      >
        <template #activator="{ props: searchProps }">
          <v-hover v-slot="{ isHovering, props: hoverProps }">
            <div v-bind="hoverProps">
              <v-text-field
                v-bind="mergeProps(infoProps, searchProps, $attrs)"
                ref="searchInputRef"
                v-model="searchDisplay"
                hide-details
                density="compact"
                variant="outlined"
                :color="light ? '' : 'white'"
                class="user-select"
                :class="{ borderless, readonly: isReadonly }"
                :placeholder="selectedName"
                :label="displayLabel"
                :loading="!borderless && (loading || fetchingUsers)"
                :disabled="!modelValue && isReadonly"
                :readonly="isReadonly"
                :style="{ maxWidth }"
                @update:model-value="onInput"
                @focus="onFocus"
                @blur="onBlur"
              >
                <template #prepend-inner>
                  <div class="prepend-avatar" @click.stop="onPrependClick">
                    <template v-if="modelValue">
                      <v-btn
                        v-if="clearable && !isReadonly && isHovering"
                        icon
                        variant="flat"
                        width="24"
                        height="24"
                        @click="selectUser(null)"
                      >
                        <v-icon icon="mdi-close-circle" />
                      </v-btn>
                      <Avatar
                        v-else
                        :size="24"
                        :seed="modelValue.userId"
                        :first="modelValue.firstName"
                        :last="modelValue.lastName"
                        :avatar-url="modelValue.avatar"
                        font-class="fs-12"
                      />
                    </template>
                    <div v-else class="empty-avatar" />
                  </div>
                </template>
              </v-text-field>
            </div>
          </v-hover>
        </template>
        <v-list>
          <v-list-item
            v-for="user of searchUsers"
            :key="user.userId"
            data-user-select-item
            class="py-4"
            @click="onListClick(user)"
          >
            <template #prepend>
              <div class="mr-2">
                <Avatar
                  :size="24"
                  :seed="user.userId"
                  :first="user.firstName"
                  :last="user.lastName"
                  :avatar-url="user.avatar"
                  font-class="fs-12"
                />
              </div>
            </template>
            <v-list-item-title class="pl-1">
              <span class="font-weight-medium fs-13">{{ `${user.firstName} ${user.lastName}` }}</span>
              <span v-if="emailInMenu" class="font-weight-regular ml-3 text-subheadgrey fs-12" v-text="`${user.email}`" />
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!fetchingUsers && !searchUsers.length">
            <v-list-item-title>
              No Users Found
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <UserInfoCard v-if="infoOpen && modelValue" :user="modelValue" />
  </v-menu>
</template>

<script lang="ts">
import { ListUsersRequest } from '@policyfly/protobuf/patrick'
import { formatSentence } from '@policyfly/utils/string'
import { defineComponent, ref, mergeProps, computed, markRaw, watch } from 'vue'
import { useRoute } from 'vue-router'

import Avatar from '@/components/common/Avatar/Avatar.vue'
import UserInfoCard from '@/components/common/UserInfoCard/UserInfoCard.vue'
import { useNotification } from '@/composables/notification'
import { useApiStore } from '@/stores/api'
import { useAppContextStore } from '@/stores/appContext'
import { useAuthenticationStore } from '@/stores/authentication'
import { useProtobufStore } from '@/stores/protobuf'

import routeNames from '@/router/routeNames'

import type { User } from '@policyfly/protobuf/patrick'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'UserSelect',

  components: {
    Avatar,
    UserInfoCard,
  },

  inheritAttrs: false,

  emits: {
    'update:modelValue': (_value: User | null): true => true,
  },

  props: {
    modelValue: { type: Object as PropType<User | null>, default: null },
    policyId: { type: Number, default: null },
    applicationId: { type: Number, default: null },
    label: { type: String, default: '' },
    unassignedLabel: { type: String, default: '' },
    userlist: { type: Array as PropType<User[] | null>, default: null },
    light: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    borderless: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    emailInMenu: { type: Boolean, default: false },
    confirm: { type: String, default: '' },
    maxWidth: { type: String, default: '300px' },
  },

  setup (props, { emit }) {
    const authenticationStore = useAuthenticationStore()

    // menu
    const sharedMenuProps = markRaw({
      offset: 4,
      closeDelay: 100,
      closeOnClick: false,
      closeOnContentClick: false,
      transition: 'fade-transition',
    })
    const infoOpen = ref(false)
    function onInfoOpen (opened: boolean): void {
      if (opened && props.modelValue && !searchOpen.value) {
        infoOpen.value = true
      } else {
        infoOpen.value = false
      }
    }

    // readonly
    const isReadonly = computed<boolean>(() => {
      return props.readonly || props.loading || props.disabled
    })

    // search input
    const searchOpen = ref(false)
    const searchInputRef = ref<{ blur: () => void } | null>(null)
    const searchDisplay = ref('')
    /**
     * It seems impossible to stop the field focusing when clicking the prepend slot
     * This is a hack to force a blur immediately after
     */
    function onPrependClick (): void {
      searchInputRef.value?.blur()
    }
    function closeSearch (): void {
      searchOpen.value = false
      searchDisplay.value = selectedName.value
    }
    const isFocused = ref(false)
    function onSearchOpen (opened: boolean): void {
      if (opened) {
        searchOpen.value = true
      } else if (!isFocused.value) {
        closeSearch()
      }
    }
    function onInput (v: string): void {
      searchValue.value = v
      searchDisplay.value = v
    }
    function onFocus (): void {
      if (isReadonly.value) return
      searchValue.value = ''
      searchDisplay.value = ''
      infoOpen.value = false
      isFocused.value = true
      searchOpen.value = true
    }
    function onBlur (e: FocusEvent): void {
      // if selecting a list item ignore the blur
      if (e.relatedTarget instanceof HTMLElement && e.relatedTarget.hasAttribute('data-user-select-item')) {
        isFocused.value = false
        return
      }
      closeSearch()
    }

    // users
    const route = useRoute()
    const contextAppId = computed<number | null>(() => {
      const value = props.applicationId ?? route.params.applicationId
      return value ? Number(value) : null
    })
    const contextPolicyId = computed<number | null>(() => {
      const value = props.policyId ?? route.params.policyId
      return value ? Number(value) : null
    })
    const appContextStore = useAppContextStore()
    const appAgency = computed(() => {
      return appContextStore.loadedApplicationData?.agency
    })
    const fetchingUsers = ref(false)
    const protobufStore = useProtobufStore()
    const apiStore = useApiStore()
    async function loadUsers (): Promise<void> {
      try {
        fetchingUsers.value = true
        const onTasklist = route.name === routeNames.TASKS

        const request = ListUsersRequest.create({
          programId: authenticationStore.programId,
        })
        // if no agency, fetch without context to get set of all users in all agencies
        if (contextAppId.value && (onTasklist || appAgency.value)) request.applicationId = contextAppId.value
        if (contextPolicyId.value && (onTasklist || appAgency.value)) request.policyId = contextPolicyId.value
        if (protobufStore.policyState.agency?.id) request.agencyId = protobufStore.policyState.agency.id
        if (protobufStore.policyState.coverholder?.id) request.coverholderId = protobufStore.policyState.coverholder.id

        const response = await apiStore.user.listUsers(request)
        localUsers.value = response.users
      } catch (e) {
        console.error(e)
      } finally {
        fetchingUsers.value = false
      }
    }
    watch([
      searchOpen,
      appAgency,
      () => authenticationStore.programId,
      () => route.params.applicationId,
    ], ([open]) => {
      if (open && !props.userlist) {
        loadUsers()
      }
    })

    const localUsers = ref<User[]>([])
    const users = computed<User[]>(() => {
      const userlist = (props.userlist ?? localUsers.value ?? [])
        .slice()
      const { modelValue } = props
      if (modelValue && !userlist.find((user) => user.userId === modelValue.userId)) {
        userlist.push(modelValue)
      }
      return userlist
    })
    const searchValue = ref('')
    const searchUsers = computed<User[]>(() => {
      if (!searchValue.value) return users.value
      return users.value.filter((user) => {
        const first = user.firstName.toLowerCase()
        const last = user.lastName.toLowerCase()
        const text = `${first} ${last}`
        const searchText = searchValue.value.toLowerCase()
        return text.includes(searchText)
      })
    })
    function selectUser (v: User | null): void {
      emit('update:modelValue', v)
      closeSearch()
    }
    const { userConfirm } = useNotification()
    async function onListClick (user: User): Promise<void> {
      if (props.confirm && !await userConfirm({ title: formatSentence(props.confirm), body: `Are you sure you want to ${props.confirm}?` })) {
        return
      }
      selectUser(user)
    }

    // selected
    const selectedName = computed<string>(() => {
      if (!props.modelValue) return ''
      return `${props.modelValue.firstName} ${props.modelValue.lastName}`
    })
    const displayLabel = computed<string>(() => {
      if (!props.modelValue && !searchOpen.value && props.unassignedLabel) return props.unassignedLabel
      return props.label
    })
    watch(() => props.modelValue, () => {
      if (!searchOpen.value) {
        searchDisplay.value = selectedName.value
      }
    }, { immediate: true })

    return {
      mergeProps,
      // menu
      sharedMenuProps,
      infoOpen,
      onInfoOpen,
      // readonly
      isReadonly,
      // search input
      searchOpen,
      searchInputRef,
      searchDisplay,
      onPrependClick,
      onSearchOpen,
      onInput,
      onFocus,
      onBlur,
      // users
      fetchingUsers,
      searchUsers,
      selectUser,
      onListClick,
      // selected
      selectedName,
      displayLabel,
      // testing
      searchValue,
      closeSearch,
      users,
      localUsers,
      loadUsers,
    }
  },
})
</script>

<style lang="sass" scoped>
.prepend-avatar
  width: 32px
  margin-right: 8px
.empty-avatar
  height: 24px
  width: 24px
  border-radius: 50%
  border: dashed 1px grey
// @vuetify-override
.user-select
  &.borderless
    :deep(.v-field__outline__start),
    :deep(.v-field__outline__notch):before,
    :deep(.v-field__outline__notch):after,
    :deep(.v-field__outline__end)
      border: unset !important
</style>
