import { createI18n } from 'vue-i18n'

import { en } from './translations'
import { processTranslations } from '@/utils/i18n'

import type { SchemaTranslations } from '@policyfly/protobuf'

export const i18n = createI18n<[SchemaTranslations], string, false>({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  fallbackWarn: false,
  silentFallbackWarn: true,
  // fallback to key for missing translations and suppress warnings
  missing: (_, key) => {
    return key
  },
})

// Parse, then load provided translations and set as active locale
export const loadTranslations = (slug: string, translations: SchemaTranslations): void => {
  i18n.global.setLocaleMessage(
    slug,
    processTranslations(translations),
  )
  i18n.global.locale.value = slug
}

loadTranslations('en', en)
