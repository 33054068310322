import {
  CommentVisibility,
  EventVisibility,
  SchemaTranslations_FeedEventLink_RouteName,
} from '@policyfly/protobuf'

import { coreUserToUserJson } from './user'

import type { Comment, EventEntryResponse } from '@policyfly/protobuf'
import type { FeedEventLog } from 'types/events'
import type { RouteNamesValues } from 'types/router'

/**
 * Converts Protobuf {@link EventEntryResponse} to equivalent Django {@link FeedEventLog}
 */
export function eventEntryResponseToFeedEventLog (event: EventEntryResponse): FeedEventLog {
  const created = new Date(event.created ? +event.created.seconds * 1000 : 0)
  const name = event.userName.split(' ')
  return {
    id: +event.id,
    application: 0,
    application_src: '',
    comment: null,
    created: event.created ? created.toISOString() : '',
    created_by: {
      id: 0,
      first_name: name[0],
      last_name: name.length > 1 ? name[1] : '',
    },
    disabled: false,
    policy: 0,
    policy_src: 0,
    verb: event.data?.data.oneofKind || '',
    data: event.data?.data || {},
    visibility: event.visibility,
  }
}

/**
 * Converts Protobuf {@link Comment}
 * to equivalent Django {@link FeedEventLog}
 */
export function commentToFeedEventLog (comment: Comment): FeedEventLog {
  const created = new Date(comment.created ? +comment.created.seconds * 1000 : 0)
  const attachment = comment.attachments.length ? comment.attachments[0] : null
  return {
    // @ts-expect-error: allow uuids for now
    id: comment.uuid,
    application: +comment.applicationId || undefined,
    application_src: '',
    comment: {
      read_by: comment.readBy ? coreUserToUserJson(comment.readBy) : undefined,
      // @ts-expect-error: allow uuids for now
      id: comment.uuid,
      creator_role: '',
      restricted_visibility: [CommentVisibility.COMMENT_VISIBILITY_RESTRICTED, CommentVisibility.COMMENT_VISIBILITY_STAFF].includes(comment.visibility!),
      body: comment.content,
    },
    created: comment.created ? created.toISOString() : '',
    created_by: {
      id: 0,
      first_name: comment.author?.firstName ?? '',
      last_name: comment.author?.lastName ?? '',
    },
    disabled: false,
    policy: +comment.policyId || undefined,
    policy_src: 0,
    ...(attachment
      ? {
          verb: 'uploaded',
          description: { text: attachment.metadata?.name || '' },
          data: { original_name: attachment.metadata?.name, policy_document: false },
        }
      : {
          verb: 'commented',
          data: {},
        }),
    visibility: EventVisibility.EVENT_VISIBILITY_UNSPECIFIED,
    readOnly: comment.readable === false,
  }
}

export function feedEventRouteNameToPolicyRouteName (routeName: SchemaTranslations_FeedEventLink_RouteName): RouteNamesValues | undefined {
  return feedEventRouteNameToPolicyRouteNameMap.get(routeName) ?? undefined
}

export function feedEventRouteNameToApplicationRouteName (routeName: SchemaTranslations_FeedEventLink_RouteName): RouteNamesValues | undefined {
  return feedEventRouteNameToApplicationRouteNameMap.get(routeName) ?? undefined
}

const feedEventRouteNameToPolicyRouteNameMap = new Map<SchemaTranslations_FeedEventLink_RouteName, RouteNamesValues | undefined>([
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_UNSPECIFIED, undefined],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ACTIVITY, 'policyFeed'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_APPLICATION_REVIEW, 'applicationDetails'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ATTACHMENTS, 'policyAttachments'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_POLICY_DOCS, 'policyPolicyDocs'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_QUOTE_REVIEW, undefined],
])

const feedEventRouteNameToApplicationRouteNameMap = new Map<SchemaTranslations_FeedEventLink_RouteName, RouteNamesValues | undefined>([
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_UNSPECIFIED, undefined],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ACTIVITY, 'applicationFeed'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_APPLICATION_REVIEW, 'applicationDetails'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ATTACHMENTS, 'applicationAttachments'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_POLICY_DOCS, 'applicationPolicyDocs'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_QUOTE_REVIEW, 'applicationReviewQuotes'],
])
