import { VehicleType } from '@policyfly/protobuf'

import type { VINLookupVehicleType } from '@policyfly/schema/types/shared/formComponent/schedule'

/**
 * Converts Django vehicle type string to Protobuf {@link VehicleType}.
 */
export function vinLookupTypeToVehicleType (type: VINLookupVehicleType | null | undefined): VehicleType {
  return vinLookupTypeToVehicleTypeMap.get(type ?? 'UNKNOWN VEHICLE') ?? VehicleType.UNKNOWN_VEHICLE_TYPE
}

const vinLookupTypeToVehicleTypeMap = new Map<VINLookupVehicleType, VehicleType>([
  ['TRUCK', VehicleType.TRUCK],
  ['TRAILER', VehicleType.TRAILER],
  ['TRACTOR', VehicleType.TRACTOR],
  ['MOTORCYCLE', VehicleType.MOTORCYCLE],
  ['MULTIPURPOSE PASSENGER VEHICLE (MPV)', VehicleType.PASSENGER_CAR],
  ['PASSENGER CAR', VehicleType.PASSENGER_CAR],
  ['BUS', VehicleType.BUS],
  ['CAMPER', VehicleType.CAMPER],
  ['ALL TERRAIN VEHICLE', VehicleType.ALL_TERRAIN],
  ['OTHER VEHICLE', VehicleType.OTHER_VEHICLE_TYPE],
  ['UNKNOWN VEHICLE', VehicleType.UNKNOWN_VEHICLE_TYPE],
])

/**
 * Converts Protobuf vehicle enum type to Django string {@link VehicleType}.
 */
export function vehicleTypeToVinLookupType (type: VehicleType | undefined): VINLookupVehicleType {
  return vehicleTypeToVinLookupTypeMap.get(type ?? VehicleType.UNKNOWN_VEHICLE_TYPE) ?? 'UNKNOWN VEHICLE'
}

export const vehicleTypeToVinLookupTypeMap = new Map(Array.from(vinLookupTypeToVehicleTypeMap, ([name, type]) => [type, name]))
