<template>
  <div class="d-flex flex-grow-1 align-center" :class="{ 'justify-center': !stacked }">
    <v-icon class="mr-3" :color="color" icon="mdi-information-outline" />
    <div
      class="d-flex flex-shrink-1 fs-14"
      :class="{ [`text-${color}`]: !!color, 'flex-column': stacked }"
    >
      <p class="mb-0 font-weight-bold mr-2" v-text="helpText" />
      <p class="mb-0 font-weight-regular">
        <span>Contact<a href="mailto:support@policyfly.com" class="ml-1 no-underline text-lightblue">support@policyfly.com</a>.</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SupportLink',

  props: {
    stacked: { type: Boolean, default: false },
    color: { type: String, default: '' },
    helpText: { type: String, default: 'Need help?' },
  },
})
</script>
