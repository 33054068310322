import axios from 'axios'

import { disablePreflightTransformer } from '../interceptors'

import type { AxiosRequestTransformer } from 'axios'
import type { VINLookupResponse } from 'types/api'

export default {
  vin_lookup: ({ vin }: { vin: string }) => {
    return axios.get<VINLookupResponse>(
      `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}/?format=json`,
      { transformRequest: [disablePreflightTransformer as AxiosRequestTransformer] },
    )
  },
  bulk_vin_lookup: ({ vinList }: { vinList: string }) => {
    return axios.post<VINLookupResponse>(
      'https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVINValuesBatch/',
      `DATA=${vinList}&format=json`,
      { transformRequest: [disablePreflightTransformer as AxiosRequestTransformer] },
    )
  },
}
