import { normalizePath } from '@policyfly/utils/string'
import get from 'lodash-es/get'
import set from 'lodash-es/set'

import { useAppContextStore } from '@/stores/appContext'
import { useFormBinderStore } from '@/stores/form/binder'
import { useFormIssueStore } from '@/stores/form/issue'
import { useFormQuoteStore } from '@/stores/form/quote'
import { useLicenseStore } from '@/stores/license'
import { useMetaStore } from '@/stores/meta'
import { usePolicyStore } from '@/stores/policy'
import { useProtobufStore } from '@/stores/protobuf'
import { useRatingStore } from '@/stores/rating'

import Payload, { PayloadCollection } from '@/lib/Payload'

import type { NestedResponseObject, ResponseBlob, PayloadLike } from '@/lib/Payload'
import type { FormPath } from '@policyfly/schema/types/shared/formPath'
import type { InputDataProtobuf } from '@policyfly/schema/types/shared/inputData'
import type { TSFixMe } from '@policyfly/types/common'
import type { APIPayloadResponse } from '@policyfly/utils/types'

type GetResponseReturnValue = ReturnType<Payload['get']> | ReturnType<Payload['getWhole']> | undefined

/**
 * Converts a path, which may be a string, number or {@link FormPath} object, to a string.
 */
export function getPath (path?: FormPath | string | number | null): string | '' {
  if (typeof path === 'string') return path
  if (typeof path === 'number') return String(path)

  if (path && typeof path === 'object') {
    switch (path.calculation) {
      case 'yearsAgo': {
        let output = ''
        if (path.prefix) output += path.prefix
        output += new Date().getFullYear() - (path.calculationParams.offset || 0)
        if (path.suffix) output += path.suffix
        return output
      }
      default:
        throw new Error('Invalid Path Calculation')
    }
  }
  return ''
}

export function getLocalResponse (localValues: PayloadLike | null | undefined, path?: string): GetResponseReturnValue {
  if (!(localValues instanceof Payload || localValues instanceof PayloadCollection) || !path) return undefined
  if (!localValues.hasKey(path)) return undefined
  return localValues.get(path)
}

export function getExternalResponse (localValues: PayloadLike | null | undefined, path?: string): GetResponseReturnValue {
  if (!(localValues instanceof Payload || localValues instanceof PayloadCollection) || !path) return undefined
  const external = localValues.getExternalPayload()
  if (!external) return undefined
  if (!external.hasKey(path)) return undefined
  return external.get(path)
}

export function getParentResponse (path?: string): GetResponseReturnValue {
  if (typeof path !== 'string') return undefined
  const policyStore = usePolicyStore()
  const parentPayload = policyStore.parentPayload
  if (!parentPayload) return undefined
  return parentPayload.hasKey(path) ? parentPayload.get(path) : undefined
}

export function getRecentResponse (path?: string, whole?: boolean): GetResponseReturnValue {
  if (typeof path !== 'string') return undefined
  const policyStore = usePolicyStore()
  const formattedPath = normalizePath(path)
  const recentPayload = policyStore.recentPayload
  if (!recentPayload || !recentPayload.hasKey(formattedPath)) return undefined
  if (whole) return recentPayload.getWhole(formattedPath)
  else return recentPayload.get(formattedPath)
}

export function getNestedResponse (path?: string, whole?: boolean): GetResponseReturnValue {
  if (typeof path !== 'string') return undefined
  const appContextStore = useAppContextStore()
  const formattedPath = normalizePath(path)
  if (!appContextStore.nestedResponseObjects) return undefined
  const responsePayload: Payload | null = appContextStore.nestedResponsePayload
  if (!responsePayload || !responsePayload.hasKey(formattedPath)) return undefined
  if (whole) return responsePayload.getWhole(formattedPath)
  else return responsePayload.get(formattedPath)
}

export function getQuoteResponse (path?: string, whole = false): GetResponseReturnValue {
  if (typeof path !== 'string') return undefined
  const formQuoteStore = useFormQuoteStore()
  const formattedPath = normalizePath(path)
  const activeQuotePayload: Payload | null = formQuoteStore.activeQuotePayload
  if (!activeQuotePayload || !activeQuotePayload.hasKey(formattedPath)) return undefined
  if (whole) {
    const value = activeQuotePayload.getWhole(formattedPath)!
    return value
  } else return activeQuotePayload.get(formattedPath)
}

export function getIssueResponse (path?: string, whole = false): GetResponseReturnValue {
  if (typeof path !== 'string') return undefined
  const formIssueStore = useFormIssueStore()
  const formattedPath = normalizePath(path)
  const issuePayload = formIssueStore.issuePayload
  if (!issuePayload || !issuePayload.hasKey(formattedPath)) return undefined
  if (whole) return issuePayload.getWhole(formattedPath)
  else return issuePayload.hasResponse(formattedPath) ? issuePayload.get(formattedPath) : undefined
}

export function getBinderResponse (path?: string, whole = false): GetResponseReturnValue {
  if (typeof path !== 'string') return undefined
  const formBinderStore = useFormBinderStore()
  const formattedPath = normalizePath(path)
  const binderPayload = formBinderStore.binderPayload
  if (!binderPayload || !binderPayload.hasKey(formattedPath)) return undefined
  if (whole) return binderPayload.getWhole(formattedPath)
  else return binderPayload.hasResponse(formattedPath) ? binderPayload.get(formattedPath) : undefined
}

export function getRatingResponse (path?: string): TSFixMe {
  if (typeof path !== 'string') return undefined
  const ratingStore = useRatingStore()
  const formattedPath = normalizePath(path)
  return get(ratingStore.lastRating, formattedPath)
}

export function getLicenseResponse (path?: string, whole?: boolean): TSFixMe {
  if (typeof path !== 'string') return undefined
  const licenseStore = useLicenseStore()
  const formattedPath = path.split('.').join('_')
  const field = licenseStore.responses.find((q: APIPayloadResponse) => q.k === formattedPath) || {}
  // @ts-expect-error: Does not know about nested v
  return !whole ? field.v : field
}

export function getMetaResponse (path?: string, whole?: boolean): TSFixMe {
  if (typeof path !== 'string') return undefined
  const metaStore = useMetaStore()
  const formattedPath = path.split('.').join('_')
  const field = metaStore.endorsementMeta?.responses.find((q: APIPayloadResponse) => q.k === formattedPath) || {}
  // @ts-expect-error: Does not know about nested v
  return !whole ? field.v : field
}

export function getProtobufResponse (path?: string, access: InputDataProtobuf<string>['access'] = 'write'): TSFixMe {
  if (typeof path !== 'string') return undefined
  const protobufStore = useProtobufStore()
  return protobufStore.getPolicyState(path, access)
}

export function payloadToResponses (payload: ResponseBlob, baseKey?: string): APIPayloadResponse[] {
  return Object.entries(payload)
    .reduce((acc: APIPayloadResponse[], [k, v]) => {
      k = k.replace(/\./g, '_')
      // ignore temp data
      if (k.split('_')[0] === 'TEMP') return acc

      // construct the actual key for reference
      if (baseKey) k = `${baseKey}_${k}`

      // unwind arrays
      if (Array.isArray(v)) {
        v.forEach((obj, i) => {
          const arrayKey = `${k}_${i}`
          // primitive values cannot be handled elsewhere
          if (!obj || typeof obj !== 'object') {
            console.warn('Primitive value could not be built', arrayKey, obj)
            return
          }
          acc.push(...payloadToResponses(obj, arrayKey))
        })
      // recurse objects
      } else if (v && typeof v === 'object') {
        acc.push(...payloadToResponses(v, k))
      // add primitive values as a response
      } else {
        acc.push({ k, v })
      }
      return acc
    }, [])
}

export function nestResponses (responses: APIPayloadResponse[], whole?: boolean): NestedResponseObject | null {
  if (!responses) return null
  return responses.reduce((acc, { k, v, uuid4 }) => {
    const value = whole ? { uuid4, k, v } : v
    return set(acc, normalizePath(k), value)
  }, {})
}
