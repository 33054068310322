/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const LegalLayout = () => import('@/layouts/LegalLayout.vue')
const PrivacyPage = () => import('@/pages/Legal/PrivacyPage.vue')
const TermsPage = () => import('@/pages/Legal/TermsPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/legal',
    component: LegalLayout,
    children: [
      {
        name: routeNames.PRIVACY,
        path: 'privacy',
        component: PrivacyPage,
        meta: {
          onBack: '-1',
        },
      },
      {
        name: routeNames.TERMS,
        path: 'terms',
        component: TermsPage,
        meta: {
          onBack: '-1',
        },
      },
    ],
  },
]

export default routeConfig
