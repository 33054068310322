import { computed } from 'vue'

import { useAttachmentStore } from '@/stores/attachment'
import { useProtobufStore } from '@/stores/protobuf'

import { convertChangeList } from './protobuf'

import type { PayloadArray } from '@/lib/Payload'
import type { ChangeList } from '@policyfly/protobuf'
import type { ComputedRef } from 'vue'

export function createSignedProposalComputed (documentType: string): ComputedRef<boolean> {
  return computed(() => {
    const attachmentStore = useAttachmentStore()
    const docs = attachmentStore.signableDocuments
    if (!docs) return false
    return docs.documents.some((d) => d.attachment.document_type === documentType && d.attachment.signature_status === 'SIGNED' && d.attachment.signature_confirmed === true)
  })
}

export function createFirstYearInBusinessComputed (): ComputedRef<number> {
  return computed(() => {
    const protobufStore = useProtobufStore()
    const yearsInBusiness = protobufStore.getPolicyState<number>('history.yearsInBusiness') ?? 0
    const effectiveYear = protobufStore.getPolicyState<number>('effective.year') ?? 0
    return effectiveYear - yearsInBusiness
  })
}

export function createActiveAPDVehicleScheduleComputed (): ComputedRef<PayloadArray> {
  return computed(() => {
    const protobufStore = useProtobufStore()
    const changes = protobufStore.getPolicyState<ChangeList>('endorsementSummary.changes.(title=APD).changeLists.0')
    return convertChangeList(changes)
  })
}

export function createInactiveAPDVehicleScheduleComputed (): ComputedRef<PayloadArray> {
  return computed(() => {
    const protobufStore = useProtobufStore()
    const changes = protobufStore.getPolicyState<ChangeList>('endorsementSummary.changes.(title=APD).changeLists.1')
    return convertChangeList(changes)
  })
}

export function createActiveMTCVehicleScheduleComputed (): ComputedRef<PayloadArray> {
  return computed(() => {
    const protobufStore = useProtobufStore()
    const changes = protobufStore.getPolicyState<ChangeList>('endorsementSummary.changes.(title=MTC).changeLists.0')
    return convertChangeList(changes)
  })
}

export function createInactiveMTCVehicleScheduleComputed (): ComputedRef<PayloadArray> {
  return computed(() => {
    const protobufStore = useProtobufStore()
    const changes = protobufStore.getPolicyState<ChangeList>('endorsementSummary.changes.(title=MTC).changeLists.1')
    return convertChangeList(changes)
  })
}
