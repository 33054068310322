/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const UsersLayout = () => import('@/layouts/UsersLayout.vue')
const UsersListPage = () => import('@/pages/Users/UsersListPage.vue')
const AddUserPage = () => import('@/pages/Users/AddUserPage.vue')
const UserPage = () => import('@/pages/Users/UserPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/users',
    component: UsersLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: { name: routeNames.USER_USERSLIST },
      },
      {
        path: 'userslist',
        name: routeNames.USER_USERSLIST,
        component: UsersListPage,
      },
      {
        path: 'create',
        name: routeNames.USER_CREATE,
        component: AddUserPage,
        meta: { hiddenFAB: true },
      },
      {
        path: ':id',
        name: routeNames.USER_VIEW,
        component: UserPage,
        meta: { onBack: routeNames.USER_USERSLIST },
      },
    ],
  },
]

export default routeConfig
