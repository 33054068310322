/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { superuserGuard } from '../middleware'
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const AdminLayout = () => import('@/layouts/AdminLayout.vue')
const AdminIndexPage = () => import('@/pages/Admin/AdminIndexPage.vue')
const SandboxPage = () => import('@/pages/Admin/SandboxPage.vue')
const FormSandboxPage = () => import('@/pages/Admin/FormSandboxPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/admin',
    meta: { requiresAuth: true },
    component: AdminLayout,
    beforeEnter: superuserGuard,
    children: [
      {
        path: '/',
        name: routeNames.ADMIN_INDEX,
        component: AdminIndexPage,
      },
      {
        path: '/sandbox',
        name: routeNames.ADMIN_SANDBOX,
        component: SandboxPage,
      },
      {
        path: '/form',
        name: routeNames.ADMIN_FORM_SANDBOX,
        component: FormSandboxPage,
      },
    ],
  },
]

export default routeConfig
