<template>
  <v-dialog
    v-model="localValue"
    persistent
    width="600"
  >
    <v-card>
      <v-card-title>
        Send Feedback Report
      </v-card-title>

      <v-card-text>
        <v-row v-if="!reportId" key="v-row-1">
          <v-col cols="12">
            Please describe what you were doing when you encountered this issue so that our team can better diagnose and resolve your issue.
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="userComment"
              label="Comment"
              data-test="telemetry-user-comment"
              counter="250"
              rows="4"
              no-resize
              variant="outlined"
            />
          </v-col>
        </v-row>
        <v-row v-else key="v-row-2">
          <v-col cols="12">
            <!-- eslint-disable-next-line @stylistic/max-len -->
            <span class="fs-14">Your report ID is: <span class="font-weight-bold" v-text="reportId" />, if you’d like to speak with PolicyFly Support about this issue, please reference this ID and email them at <a href="mailto:support@policyfly.com" class="no-underline cursor-pointer text-lightblue">support@policyfly.com</a>. Otherwise, there is no need to save this ID, it is automatically tracked by our team.</span>
          </v-col>
          <v-col cols="12">
            Saving any unsaved information and reloading the page may resolve your issue.
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <template v-if="!reportId">
          <v-btn
            color="grey"
            variant="text"
            data-test="telemetry-cancel-btn"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            variant="flat"
            data-test="telemetry-send-btn"
            :disabled="userComment.length > 250"
            @click="sendReport"
          >
            Send
          </v-btn>
        </template>
        <template v-if="reportId">
          <v-spacer />
          <v-btn
            color="grey"
            variant="text"
            data-test="telemetry-reload-btn"
            @click="closeAndReload"
          >
            Close & Reload Page
          </v-btn>
          <v-btn
            color="primary"
            variant="flat"
            data-test="telemetry-close-btn"
            @click="close"
          >
            Close
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, computed, ref } from 'vue'

export default defineComponent({
  name: 'TelemetryDialog',

  emits: {
    'update:modelValue': (_value: boolean): true => true,
  },

  props: {
    modelValue: { type: Boolean, required: true },
  },

  setup (props, { emit }) {
    const localValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      },
    })

    const userComment = ref('')

    const reportId = ref('')
    const sendReport = (): void => {
      if (userComment.value.length > 250) return
      reportId.value = Sentry.captureMessage(userComment.value)
      userComment.value = ''
    }
    const close = (): void => {
      reportId.value = ''
      localValue.value = false
    }
    const closeAndReload = (): void => {
      window.location.reload()
    }
    return {
      localValue,

      userComment,
      reportId,
      sendReport,
      close,
      closeAndReload,
    }
  },
})
</script>
