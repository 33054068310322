import { defineStore } from 'pinia'

import { useAuthenticationStore } from './authentication'
import ContentManagementAdapter from '@/lib/ContentManagementAdapter'
import { hotReloadStore } from '@/utils/build'

import type { ReleaseNote } from '@/lib/ContentManagementAdapter'

const RELEASE_NOTES_ENDPOINT = 'https://policyfly-release-notes.cdn.prismic.io/api/v2'
const cmsAdapter = new ContentManagementAdapter(RELEASE_NOTES_ENDPOINT)

export const useCmsStore = defineStore({
  id: 'cms',

  state: () => ({
    releaseNotes: [] as ReleaseNote[],
    lastReadTimestamp: new Date().toISOString(),
  }),

  getters: {
    unreadNotes (state) {
      return state.releaseNotes.filter((note) => {
        return new Date(note.created || 0) > new Date(state.lastReadTimestamp)
      })
    },
    countSinceLastRead () {
      return this.unreadNotes.length
    },
  },

  actions: {
    async getNotes () {
      const authenticationStore = useAuthenticationStore()
      const prodOnly = import.meta.env.MODE === 'prod'
      const program = authenticationStore.slug
      const isInternalRole = authenticationStore.isInternalRole
      let notes = await cmsAdapter.getReleaseNotes({
        program,
        isInternalRole,
        prodOnly,
      })
      notes.sort((a, b) => new Date(a.date_of_release) > new Date(b.date_of_release) ? -1 : 1)
      if (prodOnly) notes = notes.filter((note) => note.production)
      this.releaseNotes = notes
      return notes
    },
    setLastRead (timestamp: string) {
      this.lastReadTimestamp = timestamp
    },
  },
})

hotReloadStore(useCmsStore)
