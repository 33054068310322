/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const DocumentsLayout = () => import('@/layouts/DocumentsLayout.vue')
const DocumentsListPage = () => import('@/pages/Documents/DocumentListPage.vue')
const RevisionsListPage = () => import('@/pages/Documents/RevisionsListPage.vue')
const DocumentNewPage = () => import('@/pages/Documents/DocumentNewPage.vue')
const RevisionPage = () => import('@/pages/Documents/RevisionPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/documents',
    component: DocumentsLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: routeNames.DOCUMENT_DOCUMENTSLIST,
        component: DocumentsListPage,
      },
      {
        path: 'new',
        name: routeNames.DOCUMENT_NEW,
        component: DocumentNewPage,
      },
      {
        path: 'document/:documentId/revisions',
        props: true,
        name: routeNames.DOCUMENT_REVISIONSLIST,
        component: RevisionsListPage,
      },
      {
        path: 'document/:documentId/revision/new',
        props: true,
        name: routeNames.DOCUMENT_REVISION_NEW,
        component: DocumentNewPage,
      },
      {
        path: 'document/:documentId/revisions/:revisionId',
        props: true,
        name: routeNames.DOCUMENT_REVISION,
        component: RevisionPage,
      },
    ],
  },
]

export default routeConfig
