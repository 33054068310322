import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { hotReloadStore } from '@/utils/build'

import type { ProvisionDeviceResponse_Totp } from '@policyfly/protobuf/patrick'

export interface State {
  id: number | null
  email: string
  token: string
  password: string
  number: string
  firstName: string
}

export const useOnboardingStore = defineStore({
  id: 'onboarding',

  state: (): State => ({
    id: null,
    email: '',
    token: '',
    password: '',
    number: '',
    firstName: '',
  }),

  getters: {
    loaded: (state) => !!state.firstName,
  },

  actions: {
    async loadUser ({ email, token }: { email: string, token: string }): Promise<void> {
      const apiStore = useApiStore()
      const response = await apiStore.user.validateInvitedUser({
        email,
        token,
      })
      this.id = response.id ?? null
      this.firstName = response.firstName
      this.email = response.email
      this.token = token
    },
    async setupAccount (payload: { password: string }): Promise<void> {
      const apiStore = useApiStore()
      const { id } = await apiStore.user.setupAccount({
        password: payload.password,
        email: this.email,
        token: this.token,
      })
      this.token = ''
      const authenticationStore = useAuthenticationStore()
      authenticationStore.userId = id ?? null
    },
    async provisionSMS (newNumber: string): Promise<void> {
      const apiStore = useApiStore()
      if (newNumber) this.number = newNumber
      await apiStore.user.provisionDevice({
        emailAddress: this.email,
        method: { oneofKind: 'phone', phone: { phoneNumber: this.number } },
      })
    },
    async provisionQR (): Promise<ProvisionDeviceResponse_Totp> {
      const apiStore = useApiStore()
      const response = await apiStore.user.provisionDevice({
        emailAddress: this.email,
        method: { oneofKind: 'totp', totp: {} },
      })
      if (response.method.oneofKind !== 'totp') {
        throw new Error('Unexpected response from server')
      }
      return response.method.totp
    },
    async provisionEmail (): Promise<void> {
      const apiStore = useApiStore()
      await apiStore.user.provisionDevice({
        emailAddress: this.email,
        method: { oneofKind: 'email', email: {} },
      })
    },
  },
})

hotReloadStore(useOnboardingStore)
