import { createMap as createCrafdeMap } from './crafde'
import { createArrayComputedMap } from '@/utils/diffHelpers'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createCrafdeMap(),
    ...createArrayComputedMap('restaurantTypes', 'restaurantType', 'response', {
      fineDining: 'Fast Food - Sit Down',
      fastFood: 'Fast Food - Drive Thru',
      familyStyle: 'Family Style',
    }),
  }
}
