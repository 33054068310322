/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const RatingPage = () => import('@/pages/Rating/RatingPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/rating',
    component: RatingPage,
    name: routeNames.RATING,
    meta: { requiresAuth: true },
  },
]

export default routeConfig
