import { request } from '../methods'

export default {
  read: request('get', '/api/v1/attachments/{pk}/'),
  request_signature: request('post', '/api/v1/attachments/request_signature/'),
  cancel_signature: request('post', '/api/v1/attachments/cancel_signature/'),
  signed: request('post', '/api/v1/attachments/{sig_id}/signed/'),
  confirm_signature: request('put', '/api/v1/attachments/confirmsig/{luid}/'),
  archive: request('put', '/api/v1/attachments/{pk}/archive/'),
  soft_archive: request('put', '/api/v1/attachments/{pk}/soft_archive/'),
  restore: request('put', '/api/v1/attachments/{pk}/restore/'),
  set_visibility: request('put', '/api/v1/attachments/{pk}/set_visibility/'),
  remove: request('delete', '/api/v1/attachments/{pk}/'),
  fetch: request('get', '/api/v1/attachments/fetch/{luid}/'),
  parse: request('post', '/api/v1/attachments/parse_xlsx/'),
  rename: request('put', '/api/v1/attachments/{pk}/rename/'),
}
