import { createAddressComputedMap, createArrayComputedMap } from '@/utils/diffHelpers'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createAddressComputedMap('physicalAddress', { path: 'altAddress', source: 'responses' }),
    ...createAddressComputedMap('lenderAddress', { path: 'lender', source: 'responses' }),
    ...createArrayComputedMap('smokeDetectors', 'buildingInfo.lifeSafety.smokeDetectors', null, {
      battery: 'Battery',
      hardWired: 'Hard Wired',
    }),
    ...createArrayComputedMap('coDetectors', 'buildingInfo.lifeSafety.coDetectors', null, {
      battery: 'Battery',
      hardWired: 'Hard Wired',
    }),
    ...createArrayComputedMap('fireAlarm', 'buildingInfo.lifeSafety.fireAlarm', null, {
      local: 'Local',
      centralStation: 'Central Station',
    }),
    ...createArrayComputedMap('burglarAlarm', 'buildingInfo.lifeSafety.burglarAlarm', null, {
      local: 'Local',
      centralStation: 'Central Station',
    }),
    ...createArrayComputedMap('hurricaneProtection', 'buildingInfo.hurricaneProtection', 'response', {
      windMitigation: 'Wind Mitigation',
      impactGlass: 'Impact-Resistant Glass',
      hurricaneShutters: 'Hurricane Shutters',
    }),
  }
}
