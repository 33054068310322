import type { Condition } from '@policyfly/schema/types/shared/displayIf'
import type { DropdownItemObject } from '@policyfly/schema/types/shared/dropdown'
import type { FormPath } from '@policyfly/schema/types/shared/formPath'

export const EXCESS_POLICY_PATH = 'excessPolicy'
// Used on application to be the suggested rating basis on NEW
export const SUGGESTED_RATING_BASIS_PATH = 'suggestedRatingBasis'
// Used on application to either be the suggested rating basis on NEW or the parent's actual rating basis on non-NEW
export const CURRENT_RATING_BASIS_PATH = 'currentRatingBasis'
// Used on quote to be a definitive selection of the actual rating basis
export const RATING_BASIS_PATH = 'ratingBasis'
export const PRORATED_SUM_PATH = 'premiumSum.prorated'
export const DELTA_SUM_PATH = 'premiumSum.delta'
export const TARGET_GOODS_PATH = 'operations.targetGoods'
export const FINAL_PREMIUM_PATH = 'finalPremium'

// using responses here is fine because these are not changeable on edit, using 'local' will break includeIf types
export const includeIfExcess: Condition<'responses'> = { path: EXCESS_POLICY_PATH, source: 'responses', permittedValues: [true] }
export const includeIfStandard: Condition<'responses'> = { path: EXCESS_POLICY_PATH, source: 'responses', permittedValues: [false] }
export const includeIfExpired: Condition<'store'> = { source: 'store', store: 'policy', path: 'status', permittedValues: ['EXPIRED'] }
export const displayIfExcess: Condition<'responses'> = { path: EXCESS_POLICY_PATH, source: 'responses', permittedValues: [true] }
export const displayIfStandard: Condition<'responses'> = { path: EXCESS_POLICY_PATH, source: 'responses', permittedValues: [false] }

export const displayIfNamedDrivers: Condition<'quote'> = { path: RATING_BASIS_PATH, source: 'quote', permittedValues: ['namedDrivers'] }
export const displayIfVehicles: Condition<'quote'> = { path: RATING_BASIS_PATH, source: 'quote', permittedValues: ['vehicles'] }
export const displayIfGrossReceipts: Condition<'quote'> = { path: RATING_BASIS_PATH, source: 'quote', permittedValues: ['grossReceipts'] }

export const targetGoods: { label: string, path: string, responsePath: string }[] = [
  { label: 'Diapers', path: 'diapers' },
  { label: 'Baby Formula', path: 'babyFormula' },
  { label: 'Tobacco', path: 'tobacco' },
  { label: 'Cigars', path: 'cigars' },
  { label: 'Cigarettes', path: 'cigarettes' },
  { label: 'Wine', path: 'wine' },
  { label: 'Liquor', path: 'liquor' },
  { label: 'Non-Ferrous Metals', path: 'metals' },
  { label: 'Garments', path: 'garments' },
  { label: 'Seafood', path: 'seafood' },
  { label: 'Electronics', path: 'electronics' },
].map((t) => ({
  ...t,
  responsePath: `${t.path}.response`,
}))

export function grossReceiptsPath ({ offset, suffix }: { offset: number, suffix: string }): FormPath {
  return {
    prefix: 'coverages.grossReceipts.year',
    calculation: 'yearsAgo',
    calculationParams: { offset: offset * -1 },
    suffix,
  }
}

export const commodityTypeItems: DropdownItemObject[] = [
  { text: 'Agricultural', hint: 'Grain, Animal Feed, Straw Bales', value: 'agricultural' },
  { text: 'Auto Haulers', hint: 'New Vehicles, Used Vehicles (excludes Motor Bikes and Mobile Homes)', value: 'autoHaulers' },
  { text: 'Boat Haulers', value: 'boatHaulers' },
  // { text: 'Business', hint: 'Driving School', value: 'business' },
  { text: 'Coal Haulers', value: 'coalHaulers' },
  { text: 'Contractors & Construction', hint: 'Building Material', value: 'contractors' },
  { text: 'Cotton', value: 'cotton' },
  // { text: 'Courier (Packing Service) / Mail', value: 'courier' },
  { text: 'Dry Goods', hint: 'Canned Goods, Beverages, Glass', value: 'dryGoods' },
  { text: 'Dump', hint: 'Sand, Gravel, Fracked Sand', value: 'dump' },
  { text: 'Eggs', value: 'eggs' },
  { text: 'Furniture', hint: 'Office Equipment, Office Supplies (excludes Non-Residential Movers)', value: 'furniture' },
  { text: 'Garbage / Refuse Haulers', value: 'garbageHaulers' },
  { text: 'Household Goods (Movers)', value: 'householdGoods' },
  { text: 'Intermodal Containers', value: 'intermodal' },
  // { text: 'Livery', hint: 'Limousines, Non-Emergency Transport, Buses, Coaches', value: 'livery' },
  { text: 'Livestock Haulers', value: 'livestockHaulers' },
  { text: 'Logging', hint: 'Lumber, Logs', value: 'logging' },
  { text: 'Machinery & Equipment', hint: 'Logging Equipment, Machinery Plant / Cranes, Mining, Mobile Cranes, Agricultural', value: 'machinery' },
  { text: 'Military Equipment', value: 'military' },
  { text: 'Milk (Non-Reefer)', value: 'milk' },
  { text: 'Mobile / Modular Home Haulers', value: 'mobileHome' },
  { text: 'Nursery Stock / Plants / Flowers', value: 'nurseryPlants' },
  { text: 'On Hook', value: 'onHook' },
  { text: 'Oversize / Overweight', hint: 'Escorted Loads', value: 'oversizeOverweight' },
  { text: 'Paper / Plastic', value: 'paper' },
  { text: 'Refrigerated', value: 'refrigerated' },
  { text: 'Scrap Materials (Recycling)', hint: 'Metal, Plastic', value: 'scrap' },
  { text: 'Tanker (Hazmat)', value: 'tankerHazmat' },
  { text: 'Tanker (Non-Hazmat)', value: 'tankerNonHazmat' },
  { text: 'Target Goods', hint: targetGoods.map((v) => v.label).join(', '), value: 'targetGoods' },
]

export const commodityTypeMap = commodityTypeItems.reduce<Record<string, string>>((acc, item) => Object.assign(acc, { [item.value as string]: item.text }), {})
