import { onBeforeUnmount, onMounted, ref } from 'vue'

import type { Ref } from 'vue'

interface UseSupportReturnValue {
  isRequestingSupport: Ref<boolean>
}

export function useSupport (): UseSupportReturnValue {
  const keyPressBuffer = ref<string[]>([])
  const isRequestingSupport = ref(false)

  const checkSequence = (): boolean => {
    const supportCode = ['Control', 'Shift', 'Control']
    return supportCode.every((key, i) => {
      if (keyPressBuffer.value.length !== supportCode.length) return false
      return key === keyPressBuffer.value[i]
    })
  }

  const handleSupportRequested = (e: KeyboardEvent): void => {
    if (isRequestingSupport.value) return
    if (keyPressBuffer.value.length >= 3) {
      keyPressBuffer.value.shift()
    }
    keyPressBuffer.value.push(e.key)
    isRequestingSupport.value = checkSequence()
  }
  const handleOnMounted = (): void => {
    document.addEventListener('keydown', handleSupportRequested)
  }
  const handleBeforeUnmount = (): void => {
    document.removeEventListener('keydown', handleSupportRequested)
  }
  onMounted(handleOnMounted)
  onBeforeUnmount(handleBeforeUnmount)

  return {
    isRequestingSupport,
  }
}
