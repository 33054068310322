import { defineStore } from 'pinia'
import { computed, effectScope, reactive, ref } from 'vue'

import { useAuthenticationStore } from '@/stores/authentication'

import { createMap as createCommonMap } from './common'
import Payload, { PayloadArray } from '@/lib/Payload'
import { hotReloadStore } from '@/utils/build'

import type { AnyObject } from '@policyfly/types/common'
import type { ProgramSlug } from 'types/program'
import type { ComputedRef, EffectScope, Ref } from 'vue'

const programMaps = import.meta.glob('./programs/*.ts', { eager: true }) as Record<`./programs/${ProgramSlug}.ts`, { createMap: CreateMapFn }>

export type ComputedMap<T extends string = string> = Record<T, ComputedRef>
type CurrentMapState = Record<string, unknown>
export type CreateMapFn = () => ComputedMap
interface CurrentMap {
  slug: ProgramSlug | null
  scope: EffectScope | null
  state: Ref<CurrentMapState>
}

const currentMap: CurrentMap = {
  slug: null,
  scope: null,
  state: ref({}),
}

function resetProgramData (): void {
  currentMap.scope?.stop()
  currentMap.scope = null
  currentMap.slug = null
  currentMap.state.value = {}
}

function deepGet<T> (value: unknown, pathParts: string[]): T | undefined {
  if (!pathParts.length || typeof value !== 'object' || !value) return value as T

  let actualValue = value as AnyObject
  if (value instanceof Payload) {
    actualValue = value.payloadObject
  } else if (value instanceof PayloadArray) {
    actualValue = value.slice()
  }

  const [pathPart, ...nextParts] = pathParts
  if (!(pathPart in actualValue)) return undefined
  return deepGet(actualValue[pathPart], nextParts)
}

/**
 * Gets data for the current program.
 *
 * @param path The path to find.
 * @returns The computed result, or `undefined` if the path does not exist.
 */
function getProgramData<T> (path?: string): T | undefined {
  if (!path) return
  const authenticationStore = useAuthenticationStore()
  const currentSlug = authenticationStore.slug
  if (!currentSlug) return

  if (currentMap.slug !== currentSlug) {
    resetProgramData()
    currentMap.slug = currentSlug
    const mapPath = `./programs/${currentSlug}.ts` as const
    const createMap = programMaps[mapPath]?.createMap
    currentMap.scope = effectScope(true)
    currentMap.state.value = currentMap.scope.run(() => {
      return reactive<ComputedMap>({
        ...createCommonMap(),
        ...createMap?.() || {},
      })
    })!
  }

  return deepGet<T>(currentMap.state.value, path.split('.'))
}

/**
 * Gets program specific data, useful for review or information sections on forms.
 *
 * This used to be known as "review getters".
 */
export const useProgramDataStore = defineStore('programData', () => {
  const currentState = computed(() => currentMap.state.value)
  return {
    currentState,
    getProgramData,
    resetProgramData,
  }
})

hotReloadStore(useProgramDataStore)
