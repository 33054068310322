import { agencyInStore, restrictIfBroker } from './common'

import type { FormComponent } from '@policyfly/schema/types/shared/formComponent'
import type { FormComponentCheckbox } from '@policyfly/schema/types/shared/formComponent/checkbox'
import type { FormComponentCheckboxGroup } from '@policyfly/schema/types/shared/formComponent/checkboxGroup'
import type { FormComponentDate } from '@policyfly/schema/types/shared/formComponent/date'
import type { EditFormSource } from 'types/schema/edit'

export const targetGoods: { label: string, path: string, responsePath: string }[] = [
  { label: 'Diapers', path: 'diapers' },
  { label: 'Baby Formula', path: 'babyFormula' },
  { label: 'Tobacco', path: 'tobacco' },
  { label: 'Cigars', path: 'cigars' },
  { label: 'Cigarettes', path: 'cigarettes' },
  { label: 'Wine', path: 'wine' },
  { label: 'Liquor', path: 'liquor' },
  { label: 'Non-Ferrous Metals', path: 'metals' },
  { label: 'Garments', path: 'garments' },
  { label: 'Seafood', path: 'seafood' },
  { label: 'Electronics', path: 'electronics' },
  { label: 'Other', path: 'other' },
].map((t) => ({
  ...t,
  responsePath: `${t.path}.response`,
}))

export const targetGoodsCheckboxes: FormComponentCheckboxGroup<'local'>['checkboxes'] = targetGoods.map((targetGood) => {
  return {
    label: targetGood.label,
    path: targetGood.responsePath,
  }
})

export function scheduleManualAddDates (): FormComponentDate<EditFormSource>[] {
  return [
    {
      label: 'Add Date',
      data: {
        path: 'addDate',
        source: 'local',
      },
      type: 'date',
      rules: ['required', 'validEffectiveDate'],
      colSize: 6,
      formTypes: ['APP_ENDORSEMENT'],
    },
    {
      label: 'Add Date',
      data: {
        path: 'addDate',
        source: 'local',
      },
      type: 'date',
      rules: ['validEffectiveDate'],
      colSize: 6,
      formTypes: ['APP_RENEWAL'],
    },
  ]
}

export function scheduleArrayFieldAddDates (): FormComponentDate<EditFormSource>[] {
  return [
    {
      label: 'Add Date',
      data: {
        path: 'addDate',
        source: 'local',
      },
      type: 'date',
      rules: ['validEffectiveDate'],
      colSize: 6,
      formTypes: ['APP_ENDORSEMENT', 'APP_RENEWAL'],
      displayIf: { path: 'addedAppKind', source: 'local', permittedValues: ['APPLICATION', 'RENEWAL'] },
    },
    {
      label: 'Add Date',
      data: {
        path: 'addDate',
        source: 'local',
      },
      type: 'date',
      rules: ['validEffectiveDate'],
      colSize: 6,
      formTypes: ['APP_RENEWAL'],
      displayIf: { path: 'addedAppKind', source: 'local', restrictedValues: ['APPLICATION', 'RENEWAL'] },
    },
    {
      label: 'Add Date',
      data: {
        path: 'addDate',
        source: 'local',
      },
      type: 'date',
      rules: ['required', 'validEffectiveDate'],
      colSize: 6,
      formTypes: ['APP_ENDORSEMENT'],
      displayIf: { path: 'addedAppKind', source: 'local', restrictedValues: ['APPLICATION', 'RENEWAL'] },
    },
  ]
}

export function scheduleRemovalDates (): FormComponentDate<EditFormSource>[] {
  return [
    {
      label: 'Removal Date',
      data: {
        path: 'removalDate',
        source: 'local',
      },
      type: 'date',
      rules: ['required'],
      colSize: 4,
    },
  ]
}

export function optionalCoverageOnQuote ({ field, parentPath }: { field: Omit<FormComponentCheckbox<EditFormSource>, 'formTypes'>, parentPath: string }): FormComponentCheckbox<EditFormSource>[] {
  return [
    {
      ...field,
      formTypes: ['APP_INITIAL', 'APP_PENDING_INFO'],
    },
    {
      ...field,
      default: {
        overwriteParent: true,
        type: 'data',
        data: {
          source: 'parent',
          path: parentPath,
        },
      },
      formTypes: ['APP_ENDORSEMENT', 'APP_RENEWAL'],
    },
  ]
}

export function brokerEchoAgencyName (): FormComponent<EditFormSource> {
  return {
    type: 'computed',
    calculation: {
      name: 'echo',
      params: {
        valueData: {
          path: 'agency',
          source: 'store',
          store: 'appContext',
        },
      },
    },
    calculateIf: [agencyInStore, restrictIfBroker],
    data: {
      path: 'agencyName',
      source: 'local',
    },
  }
}
