import { computed } from 'vue'

import { useFormBinderStore } from '@/stores/form/binder'

import { createAddressComputedMap } from '@/utils/diffHelpers'
import { getNestedResponse } from '@/utils/responses'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createAddressComputedMap('physicalAddress', { path: 'physicalAddress', source: 'responses' }),
    ...createAddressComputedMap('mailingAddress', { path: 'mailingAddress', source: 'responses' }),
    effectiveDate: computed(() => {
      const formBinderStore = useFormBinderStore()
      const binderResponses = formBinderStore.responses
      return binderResponses.find((r) => r.k === 'effectiveDate') || getNestedResponse('effectiveDate', true)
    }),
  }
}
