import {
  createFirstYearInBusinessComputed,
} from '@/utils/programData'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    firstYearInBusiness: createFirstYearInBusinessComputed(),
  }
}
