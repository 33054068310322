import type { InspectorRootNodes } from '@/plugins/devtools/shared'
import type { KeysOfType } from '@policyfly/types/utils'
import type {
  CustomInspectorNode,
  CustomInspectorOptions,
  DevToolsV6PluginAPIHookKeys,
  DevToolsV6PluginAPIHookPayloads,
  PluginSetupFunction,
} from '@vue/devtools-kit'
import type { App } from 'vue'

export type GrpcLogLevel = 'debug' | 'info'
/**
 * Settings that are set within `setupDevtoolsPlugin`.
 * These can be controlled by plugin settings in the devtools UI.
 */
export interface DevtoolsSettings {
  showFAB: boolean
  luaLogLimit: string
  grpcLogLimit: string
  grpcLogLevel: GrpcLogLevel
}

export interface Devtools {
  /**
   * Whether the devtools instance is currently active.
   * Can be used to avoid doing a large amount of logic just for debugging.
   *
   * Keep in mind that generally devtools methods are noop functions if devtools is not installed and can be called safely.
   */
  active: boolean
  /**
   * @internal
   * The stored instance of the devtools api.
   */
  api: Parameters<PluginSetupFunction>[0] | null
  /**
   * The devtools App instance that runs alongside the main App.
   */
  app: App | null
  /**
   * @internal
   * All node actions that display whenever a root node is active.
   */
  rootNodeActions: NonNullable<CustomInspectorOptions['nodeActions']>
  /**
   * @internal
   * A list of functions to generate root nodes.
   * Run whenever the inspector view is updated to generate fresh nodes.
   *
   * Already filtered to not run unless this is a PolicyFly inspector.
   */
  rootNodeGetters: ((payload: DevToolsV6PluginAPIHookPayloads[DevToolsV6PluginAPIHookKeys.GET_INSPECTOR_TREE]) => Required<CustomInspectorNode> | null)[]
  /**
   * @internal
   * A list of functions to generate state when selecting a root node.
   * Run whenever the inspector view is updated to generate fresh state.
   * Note that the state should be modified on the passed in `payload` directly.
   *
   * Already filtered to not run unless this is a PolicyFly inspector.
   */
  stateGetters: ((payload: DevToolsV6PluginAPIHookPayloads[DevToolsV6PluginAPIHookKeys.GET_INSPECTOR_STATE]) => void)[]
  /**
   * @internal
   * A list of functions to edit state when `editable` is set to `true`.
   *
   * Already filtered to not run unless this is a PolicyFly inspector.
   */
  stateEditors: ((payload: DevToolsV6PluginAPIHookPayloads[DevToolsV6PluginAPIHookKeys.EDIT_INSPECTOR_STATE]) => void)[]
  /**
   * @internal
   * Creates a unique id for the given node.
   * Use {@link getRootNode} to return the root node for an id.
   */
  buildNodeId (root: InspectorRootNodes): string
  /**
   * @internal
   * Returns the root node from a node id.
   */
  getRootNode (nodeId: string): InspectorRootNodes | null
  /**
   * @internal
   * Returns a pretty time string from milliseconds param, defaults to the devtools timestamp.
   */
  prettyTime (ms?: number): string
  /**
   * @internal
   * Returns a pretty date string from milliseconds param, defaults to the devtools timestamp.
   */
  prettyDate (ms?: number): string
  /**
   * Allows logging of messages from the devtools.
   */
  logger: {
    warn (message: string): void
    error (message: string): void
  }
  /**
   * Refreshes the devtools interface & the devtools Vue instance.
   * Should be run whenever dependant values for the inspector or settings are changed.
   */
  refresh (): void
  /**
   * Shows a confirmation dialog on the app instance.
   * Returns `true` only if Yes is selected.
   */
  confirm (message: string): Promise<boolean>
  /**
   * Tries to retrieve and parse a plugin setting that should be numeric.
   * Returns the `defaultValue` if the setting is missing or not able to be parsed.
   */
  getNumericSetting (key: KeysOfType<DevtoolsSettings, string>, defaultValue: number): number
}

/**
 * The public API for interacting with the devtools.
 * Keep in mind that generally devtools methods are noop functions if devtools is not installed and can be called safely.
 */
export const devtools: Devtools = {
  active: false,
  api: null,
  app: null,
  rootNodeActions: [],
  rootNodeGetters: [],
  stateGetters: [],
  stateEditors: [],
  buildNodeId: () => '',
  getRootNode: () => null,
  prettyTime: () => '',
  prettyDate: () => '',
  logger: {
    warn: () => {},
    error: () => {},
  },
  refresh: () => {},
  refreshLua: async () => {},
  confirm: async () => false,
  getNumericSetting: () => 0,
  stores: {},
  logGrpc: () => {},
  loadForm: () => {},
  triggerFormUpdate: () => {},
  unloadForm: () => {},
}
