import { FHAWeightClass } from '@policyfly/protobuf'
import { Big } from 'big.js'

import Payload, { PayloadArray } from '@/lib/Payload'
import { formatValue, formatDate } from '@/utils/formatter'

import type { VINLookupVehicleBodyClass, VINLookupVehicleType } from '@policyfly/schema/types/shared/formComponent/schedule'
import type { TSFixMe } from '@policyfly/types/common'
import type { Formatter } from '@policyfly/utils/types/formatter'
import type { VINLookupVehicle } from 'types/api'
import type { DiffVehicle, VehiclesDiff } from 'types/programData/apd'

export type VehicleChangeState = 'Added' | 'Changed' | 'Removed'

const vehicleChanges: { name: string, path: keyof DiffVehicle, formatter: Formatter }[] = [
  { name: 'VIN', path: 'VIN', formatter: 'default' },
  { name: 'Amount', path: 'value', formatter: 'currency' },
  { name: 'Type', path: 'type', formatter: 'default' },
  { name: 'Year', path: 'year', formatter: 'default' },
  { name: 'Make', path: 'make', formatter: 'default' },
  { name: 'Model', path: 'model', formatter: 'default' },
  { name: 'Add Date', path: 'addDate', formatter: 'date' },
  { name: 'Deductible', path: 'deductibleOverride', formatter: 'currency' },
  { name: 'Total Loss', path: 'totalLoss', formatter: 'yN' },
  { name: 'Removal Date', path: 'removalDate', formatter: 'date' },
]

export function summariseVehicle (vehicle: DiffVehicle): string {
  const parts: string[] = []
  parts.push(vehicle.VIN)
  if (vehicle.removalDate) {
    parts.push(`Removed: ${formatDate(vehicle.removalDate, { year: '2-digit' })}`)
  }
  if (vehicle.addDate) {
    parts.push(`Added: ${formatDate(vehicle.addDate, { year: '2-digit' })}`)
  }
  if (Big(vehicle.value || 0).gt(0)) {
    parts.push(formatValue(vehicle.value, 'currency'))
  }
  return parts.join('   ')
}

export function extractVehicleChanges (vehicles: VehiclesDiff | null | undefined): PayloadArray {
  const basePayload = new Payload()
  basePayload.createResponse('state', null)
  basePayload.createResponse('summary', '')
  const payloadArray = new PayloadArray(basePayload)
  if (!vehicles) return payloadArray
  vehicles.added.forEach((vehicle) => {
    const newItem = payloadArray.add()
    newItem.set<VehicleChangeState>('state', 'Added')
    newItem.set('summary', summariseVehicle(vehicle))
  })
  vehicles.changed.forEach((change) => {
    const newSummary = summariseVehicle(change.new)
    const changesList: string[] = []
    vehicleChanges.forEach((c) => {
      const oldVal = formatValue(change.old[c.path], c.formatter) || 'N/A'
      const newVal = formatValue(change.new[c.path], c.formatter) || 'N/A'
      if (oldVal !== newVal) {
        changesList.push(`${c.name}: ${oldVal} to ${newVal}`)
      }
    })
    if (changesList.length > 0) {
      const newItem = payloadArray.add()
      newItem.set<VehicleChangeState>('state', 'Changed')
      newItem.set('summary', `${newSummary}  (${changesList.join(', ')})`)
    }
  })
  if (vehicles.removed) {
    vehicles.removed.forEach((vehicle) => {
      const newItem = payloadArray.add()
      newItem.set<VehicleChangeState>('state', 'Removed')
      newItem.set('summary', summariseVehicle(vehicle))
    })
  }
  return payloadArray
}

export function extractVehicleType (
  vehicle: VINLookupVehicle,
  acceptTypes: VINLookupVehicleType[] = ['BUS', 'MULTIPURPOSE PASSENGER VEHICLE (MPV)', 'PASSENGER CAR', 'TRUCK', 'TRACTOR', 'TRAILER'],
): VINLookupVehicleType | null {
  const { BodyClass, VehicleType }: VINLookupVehicle = vehicle || {}

  // Check BodyClass for exact matches plus a few variants
  if (BodyClass) {
    const parsedBodyClass = BodyClass.trim().toUpperCase() as VINLookupVehicleBodyClass
    if (acceptTypes.includes(parsedBodyClass as TSFixMe)) return parsedBodyClass as VINLookupVehicleType
    if (parsedBodyClass === 'PICKUP' && acceptTypes.includes('TRUCK')) return 'TRUCK'
    if (parsedBodyClass.includes('TRACTOR') && acceptTypes.includes('TRACTOR')) return 'TRACTOR'
  }

  // Alternative check on VehicleType
  if (VehicleType) {
    const parsedVehicleType = VehicleType.trim().toUpperCase() as VINLookupVehicleType
    if (acceptTypes.includes(parsedVehicleType)) return parsedVehicleType
  }
  return null
}

/**
 * Extracts protobuf FHA weight class from a GVWR string.
 * @example GVWRToFHAWeightClass('Class 1D: 5,001 - 6,000 lb (2,268 - 2,722 kg)') // FHA_WEIGHT_CLASS_1
 * @example GVWRToFHAWeightClass('14,000 lb') // FHA_WEIGHT_CLASS_3
 * @example GVWRToFHAWeightClass('Class 8: 33,001 lb or more') // FHA_WEIGHT_CLASS_8
 * @example GVWRToFHAWeightClass('Not Applicable') // UNKNOWN_FHA_WEIGHT_CLASS
 *
 * @param gvwr - The GVWR string to extract the weight class from.
 * @returns {@link FHAWeightClass} - The FHA weight class.
 */
export function extractFHAWeightClass (gvwr?: string): FHAWeightClass {
  if (!gvwr || gvwr.trim() === '' || gvwr.toLowerCase() === 'not applicable') {
    return FHAWeightClass.UNKNOWN_FHA_WEIGHT_CLASS
  }

  // Try to extract class designation
  const classMatch = gvwr.match(/Class\s*(\d+)[A-Za-z]*[:]?/i)
  if (classMatch) {
    const classNumber = parseInt(classMatch[1], 10)
    switch (classNumber) {
      case 1:
        return FHAWeightClass.FHA_WEIGHT_CLASS_1
      case 2:
        return FHAWeightClass.FHA_WEIGHT_CLASS_2
      case 3:
        return FHAWeightClass.FHA_WEIGHT_CLASS_3
      case 4:
        return FHAWeightClass.FHA_WEIGHT_CLASS_4
      case 5:
        return FHAWeightClass.FHA_WEIGHT_CLASS_5
      case 6:
        return FHAWeightClass.FHA_WEIGHT_CLASS_6
      case 7:
        return FHAWeightClass.FHA_WEIGHT_CLASS_7
      case 8:
        return FHAWeightClass.FHA_WEIGHT_CLASS_8
      default:
        return FHAWeightClass.UNKNOWN_FHA_WEIGHT_CLASS
    }
  }

  // Try to extract max weight from range or single weight value
  let maxWeight = 0
  const rangeMatch = gvwr.match(/(\d{1,3}(?:,\d{3})*)\s*-\s*(\d{1,3}(?:,\d{3})*)\s*lb/i)
  const singleWeightMatch = gvwr.match(/(\d{1,3}(?:,\d{3})*)\s*lb/i)
  if (rangeMatch) {
    maxWeight = parseInt(rangeMatch[2].replace(/,/g, ''), 10)
  } else if (singleWeightMatch) {
    maxWeight = parseInt(singleWeightMatch[1].replace(/,/g, ''), 10)
  }

  // Determine the weight class based on the maximum weight
  if (maxWeight) {
    switch (true) {
      case maxWeight <= 6000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_1
      case maxWeight <= 10000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_2
      case maxWeight <= 14000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_3
      case maxWeight <= 16000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_4
      case maxWeight <= 19500:
        return FHAWeightClass.FHA_WEIGHT_CLASS_5
      case maxWeight <= 26000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_6
      case maxWeight <= 33000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_7
      case maxWeight > 33000:
        return FHAWeightClass.FHA_WEIGHT_CLASS_8
    }
  }

  return FHAWeightClass.UNKNOWN_FHA_WEIGHT_CLASS
}
