import type { PrimitiveValue } from '@policyfly/types/common'
import type { SearchPreset, SearchMetadata } from 'types/home'
import type { RouteLocationNormalized } from 'vue-router'

export default class ElasticBuilder {
  /**
   * Converts boolean/number values to string
   * @param rawValue
   */
  static processValue (rawValue: string): PrimitiveValue {
    if (rawValue === 'true') return true
    else if (rawValue === 'false') return false
    else if (/^\d+$/.test(rawValue)) return parseInt(rawValue)
    else if (typeof rawValue === 'string') return rawValue
    else throw new Error('Invalid arg given to function')
  }

  /**
   * Convert to router friendly query object
   * @param filters -- ignored if searchQuery is present
   * @param meta
   * @param searchQuery - search string for PNI/Title
   */
  static toQueryObject (filters: SearchPreset['filter'], meta?: SearchMetadata, searchQuery?: string): Record<string, string> {
    const merged = Object.assign({}, searchQuery ? { search: searchQuery } : filters, meta ?? {})
    return Object.keys(merged).reduce<Record<string, string>>((acc, key) => {
      let values = key === 'search' ? searchQuery : merged[key]
      if (values === undefined || values === null || (Array.isArray(values) && values.length === 0)) return acc
      if (!Array.isArray(values)) values = [values]
      const suffix = values.length > 1 ? '__in' : ''
      const joinedValues = values.join('__')
      acc[`${key}${suffix}`] = joinedValues
      return acc
    }, {})
  }

  /**
   * Converts an object into a query string
   * @param obj Query Object
   */
  static queryize (obj: Record<string, PrimitiveValue>): string {
    return Object.keys(obj).reduce<string[]>((arr, key) => {
      arr.push(`${key}=${obj[key]}`)
      return arr
    }, []).join('&')
  }

  /**
   * Serializes an object of filter values, and their values into a query string
   * @param filters
   */
  static serializeParams (filters: SearchPreset['filter'], meta?: SearchMetadata, searchQuery?: string): string {
    const queryObj = this.toQueryObject(filters, meta, searchQuery)
    return this.queryize(queryObj)
  }

  /**
   * Parses a query string into filter values
   * @param params
   */
  static parseParams (params: string): SearchPreset['filter'] {
    const rawValues = params.split('&')
    return rawValues.reduce<SearchPreset['filter']>((obj, value) => {
      const isMulti = value.indexOf('__in') > -1
      const [key, values] = value.split(isMulti ? '__in=' : '=')
      const splitValue = values.split('__')
      obj[key] = splitValue.length === 1 ? this.processValue(splitValue[0]) : splitValue.map(this.processValue)
      return obj
    }, {})
  }

  /**
   * Parses a Router query object into filter values
   * @param params
   */
  static parseQueryObject (params: RouteLocationNormalized['query']): SearchPreset['filter'] {
    return Object.keys(params).reduce<SearchPreset['filter']>((filters, key) => {
      const value = params[key]
      const cleanKey = key.replace('__in', '')
      const values = (value as string).split('__')
      filters[cleanKey] = values.length === 1 ? this.processValue(values[0]) : values.map(this.processValue)
      return filters
    }, {})
  }
}
