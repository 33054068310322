import { request } from '../methods'

export default {
  list: request('get', '/api/v1/documents/'),
  create: request('post', '/api/v1/documents/'),
  read: request('get', '/api/v1/documents/{pk}/'),
  update: request('put', '/api/v1/documents/{pk}/'),
  patch: request('patch', '/api/v1/documents/{pk}/'),
  list_revisions: request('get', '/api/v1/documents/{manageddocument_pk}/revisions/'),
  create_revision: request('post', '/api/v1/documents/{manageddocument_pk}/revisions/'),
  read_revision: request('get', '/api/v1/documents/{manageddocument_pk}/revisions/{pk}/'),
  delete_revision: request('delete', '/api/v1/documents/{manageddocument_pk}/revisions/{pk}/'),
  clone_revision: request('post', '/api/v1/documents/{manageddocument_pk}/revisions/{pk}/clone/'),
  update_revision: request('put', '/api/v1/documents/{manageddocument_pk}/revisions/{pk}/'),
  get_edit_link: request('get', '/api/v1/documents/{manageddocument_pk}/revisions/{pk}/get_edit_link/'),
  finalize_edits: request('put', '/api/v1/documents/{manageddocument_pk}/revisions/{pk}/finalize_edits/'),
}
