import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { hotReloadStore } from '@/utils/build'

import type { OtpMethod, ValidateUserResponse } from '@policyfly/protobuf/patrick'

export const useLoginStore = defineStore({
  id: 'login',

  state: () => ({
    email: '',
    password: '',
    rememberMe: false,
    hasAuthenticator: false,
    hasSms: false,
    targetPath: '', // A path requested before login that should be persisted through the login process and then navigated to when login is complete
  }),

  actions: {
    /**
     * Validates a user using an email/password combination.
     * If valid will return information about the 2FA devices available.
     */
    async validate ({ email, password, rememberMe }: { email: string, password: string, rememberMe: boolean }): Promise<ValidateUserResponse> {
      const apiStore = useApiStore()
      const validateData = await apiStore.user.validateUser({ email, password })
      this.email = email
      this.password = password
      this.rememberMe = rememberMe
      this.hasAuthenticator = validateData.hasAuthenticator
      this.hasSms = validateData.hasSms
      return validateData
    },
    /**
     * Submits a 2FA code along with the already stored authentication information.
     * If successful will log in the user and store their session details.
     */
    async login ({ token, otpMethod }: { token: string, otpMethod: OtpMethod }): Promise<void> {
      const apiStore = useApiStore()
      const response = await apiStore.user.login({
        email: this.email,
        password: this.password,
        otpToken: token,
        rememberMe: this.rememberMe,
        otpMethod,
      })
      const authenticationStore = useAuthenticationStore()
      authenticationStore.bingoId = response.bingoId
    },
  },
})

hotReloadStore(useLoginStore)
