import { containedIn } from '@policyfly/utils/string'
import * as Sentry from '@sentry/vue'
import cloneDeep from 'lodash-es/cloneDeep'
import { getActivePinia } from 'pinia'

import { useAuthenticationStore } from '@/stores/authentication'
import { useUserStore } from '@/stores/user'

import type { AnyObject } from '@policyfly/types/common'
import type { App } from 'vue'

const IGNORED_MESSAGES: (string | RegExp)[] = [
  "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  'Request failed with status code 401',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Request failed with status code 422',
  /Navigating to current location (.*?) is not allowed/,
]

const WHITELIST: (string | RegExp)[] = []

function buildExtra (): AnyObject {
  try {
    const pinia = getActivePinia()
    if (!pinia) return {}
    const piniaCopy = cloneDeep(pinia.state.value)

    const appContext = piniaCopy.appContext
    if (appContext) {
      // remove any form data
      delete appContext.nestedResponseObjects
      const { loadedApplicationData } = appContext
      delete loadedApplicationData.responses
      delete loadedApplicationData.quoteSet
      delete loadedApplicationData.selectedQuote
      delete loadedApplicationData.policy
    }

    const policy = piniaCopy.policy
    if (policy) {
      // remove any applications
      delete policy.latest_application
      delete policy.latest_issued_app
      delete policy.applications
      delete policy.children
    }

    return {
      appContext,
      authentication: piniaCopy.authentication,
      policy,
      settings: piniaCopy.settings,
      user: piniaCopy.user,
    }
  } catch {
    return {}
  }
}

export function installSentry (app: App): void {
  let environment = 'local'
  if (window && location.origin) {
    const origin = location.origin
    switch (true) {
      case origin === 'https://canary.policyfly.com':
        environment = 'canary'
        break
      case origin === 'https://risingsun.policyfly.com':
        environment = 'risingsun'
        break
      case origin === 'https://app.policyfly.com':
        environment = 'production'
        break
      case /https?:\/\/donut-.+?/.test(origin):
        environment = 'donut'
        break
      default:
        break
    }
  }

  // We want to see Vue & JS errors in the local developer console rather than being silently rerouted to Sentry
  // Related GitHub issues: https://github.com/getsentry/sentry-javascript/issues/1416 https://github.com/vuejs/vue/issues/8433
  Sentry.init({
    app,
    dsn: 'https://226c9d823f644f269654d6b069926822@o388861.ingest.sentry.io/5226250',
    integrations: [
      Sentry.extraErrorDataIntegration({ depth: 3 }),
    ],
    release: import.meta.env.MODE,
    environment,
    beforeSend (event) {
      // manually set this event's extra data and user properties
      const authenticationStore = useAuthenticationStore()
      const userStore = useUserStore()
      event.user = {
        email: userStore.email,
        role: authenticationStore.userRole,
        program: authenticationStore.programId,
        userId: authenticationStore.userId,
      }
      event.extra = buildExtra()
      const exceptions = event.exception?.values ?? []

      if (exceptions && exceptions.length) {
        const [exception] = exceptions
        const message = exception.value || ''

        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && containedIn(message, WHITELIST)) {
          Sentry.showReportDialog({
            user: {
              email: userStore.email,
              name: userStore.fullName,
            },
            eventId: event.event_id,
          })
          return event
        } else if (event.exception && containedIn(message, IGNORED_MESSAGES)) {
          // dont send a sentry report for ignored errors
          return null
        } else {
          return event
        }
      }
      return event
    },
  })
}
