/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const ReleaseNotesLayout = () => import('@/layouts/ReleaseNotesLayout.vue')
const ReleaseNotesPage = () => import('@/pages/ReleaseNotes/ReleaseNotesPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/release-notes',
    component: ReleaseNotesLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: ReleaseNotesPage,
        name: routeNames.RELEASE_NOTES,
        meta: { isTabsList: false, hideExtension: true },
      },
    ],
  },
]

export default routeConfig
