import { targetGoods } from '../bcmtc/common'

import type { Condition, DisplayIf } from '@policyfly/schema/types/shared/displayIf'
import type { DropdownItemObject } from '@policyfly/schema/types/shared/dropdown'
import type { FormComponent } from '@policyfly/schema/types/shared/formComponent'
import type { FormComponentDropdown } from '@policyfly/schema/types/shared/formComponent/dropdown'
import type { FormComponentLabel } from '@policyfly/schema/types/shared/formComponent/label'
import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { QuoteFormSource } from 'types/schema/quote'

export type APCoverages = 'APD' | 'MTC'

export const APD_KEY = 'hasAPD'
export const MTC_KEY = 'hasMTC'
export const APD_COVERAGE_PATH = 'hasAPD'
export const MTC_COVERAGE_PATH = 'hasMTC'
export const GROUP_KEY = 'coverageGroup'
export const MONTHLY_REPORTING_KEY = 'isMonthlyReporting'

export const PREMIUM_PATH = 'premium'
export const ENDORSEMENT_PREMIUM_PATH = 'premium.prorated'
export const PRORATED_SUM_PATH = 'premiumSum.prorated'
export const DELTA_SUM_PATH = 'premiumSum.delta'

export const includeIfAPD: Condition<'programData'> = { path: APD_COVERAGE_PATH, source: 'programData', permittedValues: [true] }
export const includeIfMTC: Condition<'programData'> = { path: MTC_COVERAGE_PATH, source: 'programData', permittedValues: [true] }
export const includeIfDual: Condition<'programData'>[] = [includeIfAPD, includeIfMTC]
export const includeIfOnlyAPD: Condition<'programData'>[] = [includeIfAPD, { ...includeIfMTC, permittedValues: [false] }]
export const includeIfOnlyMTC: Condition<'programData'>[] = [{ ...includeIfAPD, permittedValues: [false] }, includeIfMTC]

export const commodityTypeItems: DropdownItemObject[] = [
  { text: 'Agricultural', hint: 'Grain, Animal Feed, Straw Bales', value: 'agricultural' },
  { text: 'Auto Haulers', hint: 'New Vehicles, Used Vehicles (excludes Motor Bikes and Mobile Homes)', value: 'autoHaulers' },
  { text: 'Boat Haulers', value: 'boatHaulers' },
  { text: 'Business Auto', includeIf: includeIfAPD, hint: 'Driving School', value: 'business' },
  { text: 'Coal Haulers', value: 'coalHaulers' },
  { text: 'Contractors & Construction', hint: 'Building Material', value: 'contractors' },
  { text: 'Cotton', value: 'cotton' },
  { text: 'Courier (Packing Service) / Mail', includeIf: includeIfAPD, value: 'courier' },
  { text: 'Dry Goods', hint: 'Canned Goods, Beverages, Glass', value: 'dryGoods' },
  { text: 'Dump', hint: 'Sand, Gravel, Fracked Sand', value: 'dump' },
  { text: 'Eggs', value: 'eggs' },
  { text: 'Furniture', includeIf: includeIfMTC, hint: 'Office Equipment, Office Supplies (excludes Non-Residential Movers)', value: 'furniture' },
  { text: 'Garbage / Refuse Haulers', value: 'garbageHaulers' },
  { text: 'Glass', includeIf: includeIfAPD, value: 'glass' },
  { text: 'Household Goods (Movers)', value: 'householdGoods' },
  { text: 'Intermodal Containers', value: 'intermodal' },
  { text: 'Livery', includeIf: includeIfAPD, hint: 'Limousines, Non-Emergency Transport, Buses, Coaches', value: 'livery' },
  { text: 'Livestock Haulers', value: 'livestockHaulers' },
  { text: 'Logging', hint: 'Lumber, Logs', value: 'logging' },
  { text: 'Machinery & Equipment', hint: 'Logging Equipment, Machinery Plant / Cranes, Mining, Mobile Cranes, Agricultural', value: 'machinery' },
  { text: 'Military Equipment', value: 'military' },
  { text: 'Milk (Non-Reefer)', value: 'milk' },
  { text: 'Mobile / Modular Home Haulers', value: 'mobileHome' },
  { text: 'Nursery Stock / Plants / Flowers', includeIf: includeIfMTC, value: 'nurseryPlants' },
  { text: 'On Hook', includeIf: includeIfMTC, value: 'onHook' },
  { text: 'Oversize / Overweight', hint: 'Escorted Loads', value: 'oversizeOverweight' },
  { text: 'Paper / Plastic', value: 'paper' },
  { text: 'Refrigerated', value: 'refrigerated' },
  { text: 'Scrap Materials (Recycling)', hint: 'Metal, Plastic', value: 'scrap' },
  { text: 'Tanker (Hazmat)', value: 'tankerHazmat' },
  { text: 'Tanker (Non-Hazmat)', value: 'tankerNonHazmat' },
  { text: 'Target Goods', includeIf: includeIfMTC, hint: targetGoods.map((v) => v.label).join(', '), value: 'targetGoods' },
]

export const commodityTypeMap = commodityTypeItems.reduce<Record<string, string>>((acc, item) => Object.assign(acc, { [item.value as string]: item.text }), {})

const adjustableCommodityTypes = ['autoHaulers', 'refrigerated', 'boatHaulers', 'mobileHome']
export const nonAdjustableCommodityTypes = commodityTypeItems.reduce<string[]>((acc, item) => {
  const value = item.value as string
  if (!adjustableCommodityTypes.includes(value as string)) acc.push(value)
  return acc
}, [])
export const displayIfNonAdjustableCommodityTypes: Condition<'responses'> = {
  path: 'operations.comType',
  source: 'responses',
  comparator: { name: 'includes', value: nonAdjustableCommodityTypes, options: { field: 'type' } },
}

export const displayIfNonAdjustableCommodityTypesOrRefrigerated: Condition<'responses'> = {
  path: 'operations.comType',
  source: 'responses',
  comparator: { name: 'includes', value: [...nonAdjustableCommodityTypes, 'refrigerated'], options: { field: 'type' } },
}

interface CommodityDeductibleParams<C extends FormCoverage> {
  path: string
  label: string
  items: FormComponentDropdown['items']
  extraField?: FormComponent<QuoteFormSource, C>
  deductibleDefault: FormComponentDropdown<QuoteFormSource>['default']
}
export function commodityDeductible<C extends FormCoverage = FormCoverage> ({ path, label, items, extraField, deductibleDefault }: CommodityDeductibleParams<C>): FormComponent<QuoteFormSource, C>[] {
  const commodity = path.split('.').pop() as string
  const displayIf: DisplayIf<QuoteFormSource> = {
    path: 'operations.comType',
    source: 'responses',
    comparator: { name: 'includes', value: commodity, options: { field: 'type' } },
  }
  const labelComponent: FormComponentLabel<QuoteFormSource> = {
    label,
    type: 'label',
    class: 'align-to-field',
    colSize: 4,
    displayIf,
  }
  const deductible: FormComponentDropdown<QuoteFormSource> = {
    type: 'dropdown',
    data: {
      path,
      source: 'local',
    },
    rules: ['required'],
    items,
    default: deductibleDefault,
    ignorePremiumReset: true,
    colSize: 4,
    displayIf,
  }
  if (!extraField) deductible.class = 'offset-right-4'
  const row: FormComponent<QuoteFormSource, C>[] = [labelComponent, deductible]
  if (extraField) row.push(extraField)
  return row
}
