import { ARRAY_DELETION_KEYS } from '@policyfly/utils/constants'
import { normalizePath } from '@policyfly/utils/string'
import { isAPIPayloadResponse } from '@policyfly/utils/type'
import get from 'lodash-es/get'
import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'

import { getInputData } from '@/utils/inputData'

import type { NestedResponseObject, NestedResponseValue, APIPayloadResponseWithUUID } from '@/lib/Payload'
import type { ComputedMap } from '@/stores/programData'
import type { InputData } from '@policyfly/schema/types/shared/inputData'
import type { AnyObject, PrimitiveValue, TSFixMe } from '@policyfly/types/common'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { DiffMap } from 'types/application'
import type { ReviewSource } from 'types/schema/review'

interface ExtractedSingleDiff {
  added: APIPayloadResponseWithUUID | null
  changed: { v: [PrimitiveValue, PrimitiveValue] } | null
  removed: APIPayloadResponseWithUUID | null
}
interface UnformattedExtractedDiff {
  v: PrimitiveValue
}
interface ExtractedDiff {
  added: PrimitiveValue
  removed: PrimitiveValue
}
type ComputedNames<Name extends string, Variations extends string> = Name | `${Name}${Variations}`
type DiffComputedNames<Name extends string> = ComputedNames<Name, 'Raw' | 'Diff'>
type DiffMapComputedNames<Name extends string> = ComputedNames<Name, 'Raw' | 'DiffMap'>

/**
 * Extracts a single diff
 * @param {string} uuid4
 * @param {Object} diffMap
 * @returns {Object} diff
 * @returns {Object} diff.added
 * @returns {Object} diff.changed
 * @returns {Object} diff.removed
 */
// TODO: This could probably be expanded to contain more of the repeated logic in the 2 extract...Diffs functions
export function extractSingleDiff (uuid4?: string | null, diffMap?: DiffMap | null): ExtractedSingleDiff {
  if (!uuid4 || !diffMap) return { added: null, changed: null, removed: null }
  const added = get(diffMap, ['added', uuid4], null)
  const changed = get(diffMap, ['changed', uuid4], null)
  const removed = get(diffMap, ['removed', uuid4], null)
  return { added, changed, removed }
}

/**
 * Extracts diff information for an object of { uuid, v } shape or with a nested value that has that shape
 * @param {Object} data
 * @param {Object} diffMap
 * @param {Function} formatter
 * @param {string} [nestedName]
 * @param {boolean} [reconstructValue] Instead of using `null` for values missing from the diffMap try and reconstruct the entire value as it was before/after
 * @returns {Object} diffs
 * @returns {Object} diffs.added
 * @returns {Object} diffs.removed
 */
export function extractObjectDiffs (data: NestedResponseObject | null, diffMap: DiffMap | null, formatter: (v: TSFixMe) => TSFixMe, nestedName?: string | null, reconstructValue = false): ExtractedDiff {
  if (!data || !diffMap) return { added: null, removed: null }
  const addedDiff: Record<string, UnformattedExtractedDiff> = {}
  const removedDiff: Record<string, UnformattedExtractedDiff> = {}
  let hasAddedDiff = false
  let hasRemovedDiff = false
  Object.entries(data).forEach(([key, val]) => {
    // @ts-expect-error: Cannot use string as index
    const { v, uuid4 } = (nestedName && val ? val[nestedName] : val) || {}
    const { added, changed, removed } = extractSingleDiff(uuid4, diffMap)
    if (added || changed) hasAddedDiff = true
    if (removed || changed) hasRemovedDiff = true
    addedDiff[key] = { v: added ? added.v : changed ? changed.v[1] : reconstructValue && !removed ? v : null }
    removedDiff[key] = { v: removed ? removed.v : changed ? changed.v[0] : reconstructValue && !added ? v : null }
  })
  return {
    added: hasAddedDiff ? formatter(addedDiff) : null,
    removed: hasRemovedDiff ? formatter(removedDiff) : null,
  }
}

/**
 * @see extractObjectDiffs
 * @param {Object} data
 * @param {Object} diffMap
 * @param {Function} formatter
 * @param {string} [nestedName]
 * @returns {Object} diffs
 * @returns {Object} diffs.added
 * @returns {Object} diffs.removed
 */
// TODO: A lot of the logic is similar between this and the extractObjectDiffs function and could probably be combined one day
export function extractArrayDiffs (data: (NestedResponseValue | null)[] | null, diffMap: DiffMap | null, formatter: (v: UnformattedExtractedDiff[]) => TSFixMe, nestedName?: string): ExtractedDiff {
  if (!data || !diffMap) return { added: null, removed: null }
  const addedDiff: UnformattedExtractedDiff[] = []
  const removedDiff: UnformattedExtractedDiff[] = []
  data.forEach((val) => {
    // @ts-expect-error: Cannot use string as index
    const { uuid4 } = ((nestedName && val ? val[nestedName] : val) || {}) as APIPayloadResponse
    const { added, changed, removed } = extractSingleDiff(uuid4, diffMap)
    addedDiff.push({ v: added ? added.v : changed ? changed.v[1] : null })
    removedDiff.push({ v: removed ? removed.v : changed ? changed.v[0] : null })
  })
  return {
    added: formatter(addedDiff),
    removed: formatter(removedDiff),
  }
}

/**
 * Combines items from the removed diff data by k values as they can't currently be retrieved otherwise
 * Isn't currently used anywhere but theoretically could be used for combining non-array nested data
 * @param {Object} data
 * @param {string} path
 * @param {Object} nestedRemovedDiffs
 * @returns {Object}
 */
export function combineRemovedObjectItems (data: NestedResponseObject | null, path: string | null, nestedRemovedDiffs: NestedResponseObject | null): NestedResponseObject | null {
  if (!path || !nestedRemovedDiffs) return data
  const removed = get(nestedRemovedDiffs, normalizePath(path), null) as NestedResponseObject | null
  if (!removed) return data
  return {
    ...data,
    ...removed,
  }
}

/**
 * Combines items from the removed diff data
 * @see combineRemovedObjectItems
 * @param {Array} data
 * @param {string} path
 * @param {Object} nestedRemovedDiffs
 * @param {Function} [mappingFunction]
 * @returns {Array}
 */
// TODO: A lot of the logic is similar between this and the combineRemovedObjectItems function and could probably be combined one day
export function combineRemovedArrayItems (data: NestedResponseObject[] | null, path: string | null, nestedRemovedDiffs: NestedResponseObject | null, mappingFunction?: TSFixMe): NestedResponseObject[] | null {
  if (!path || !nestedRemovedDiffs) return data
  if (!Array.isArray(data)) data = []
  const removed = get(nestedRemovedDiffs, normalizePath(path), []) as NestedResponseObject[]
  const combined = data
    .concat(mappingFunction ? removed.map(mappingFunction) : removed)
    .filter((v) => !!v) as NestedResponseObject[]
  if (!combined.length) return data
  return combined.sort((a, b) => {
    for (const key in a) {
      const aRes = a[key] as APIPayloadResponse
      const bRes = b[key] as APIPayloadResponse
      if (!aRes.k || !bRes || !bRes.k) continue
      const aIdx = aRes.k.match(/\d+/)
      const bIdx = bRes.k.match(/\d+/)
      if (aIdx && bIdx) {
        // sort the arrays by index if possible, fieldName_index_keyName
        return +aIdx - +bIdx
      } else {
        return aRes.k > bRes.k ? 1 : -1
      }
    }
    return 0
  })
}

export function addressFormatter (addressField: Record<'address' | 'address2' | 'city' | 'county' | 'state' | 'zip' | 'country', { v?: string }> | null): (string | null)[] {
  if (!addressField) return []
  const { address = {}, address2 = {}, city = {}, county = {}, state = {}, zip = {}, country = {} } = addressField
  let line3 = ''
  if (city.v) line3 += `${city.v}, `
  if (county.v) line3 += `${county.v} County, `
  if (state.v) line3 += `${state.v} `
  if (zip.v) line3 += zip.v
  return [
    address.v || null,
    address2.v || null,
    line3 || null,
    country.v || null,
  ]
}

/**
 * Generates 3 computed that can be spread into computed maps, used to generate addresses for multiline components based on a path.
 */
export function createAddressComputedMap<Name extends string> (computedName: Name, data: InputData<Exclude<ReviewSource, 'pb' | 'path'>>): ComputedMap<DiffComputedNames<Name>> {
  const rawName = `${computedName}Raw` as const
  const diffName = `${computedName}Diff` as const
  const computedMap = {} as ComputedMap<DiffComputedNames<Name>>
  const rawComputed = computed(() => {
    const nestedAddress = getInputData<AnyObject>(data, { whole: true })
    if (!nestedAddress) return null
    return {
      address: nestedAddress.address || {},
      address2: nestedAddress.address2 || {},
      county: nestedAddress.county || {},
      city: nestedAddress.city || {},
      state: nestedAddress.state || {},
      zip: nestedAddress.zip || {},
      country: nestedAddress.country || {},
    }
  })
  computedMap[rawName] = rawComputed
  computedMap[computedName] = computed(() => {
    return addressFormatter(rawComputed.value)
  })
  computedMap[diffName] = computed(() => {
    const diffStore = useDiffStore()
    return extractObjectDiffs(rawComputed.value, diffStore.diffMap, addressFormatter)
  })
  return computedMap
}

export function locationArrayFormatter (locations: Record<string, APIPayloadResponse<string>>[] | null, paramName = 'name'): TSFixMe[] {
  if (!locations) return []
  return locations.map(({ address, address2, city, county, state, zip, country, ...otherFields }, index) => {
    const v = [address, address2, city, county, state, zip, country]
      .reduce((a, part) => {
        // Loop through each part and add a comma between them if they exist
        if (!part || !part.v) return a
        if (a) a += ', '
        return a + part.v
      }, '')
    // Add all other fields under their original names
    // The uuids being returned here are for the custom locations diffmap, based solely on index and fieldname
    const mappedOtherFields = Object.entries(otherFields)
      .reduce<Record<string, APIPayloadResponse<string>>>((acc, [k, val]) => {
        acc[k] = {
          ...val,
          uuid4: `${index}${k}`,
        }
        return acc
      }, {})
    return {
      [paramName]: { v, uuid4: `${index}${paramName}` },
      ...mappedOtherFields,
    }
  })
}

/**
 * Generates 3 computed that can be spread into computed maps, used to transform an array that includes locations.
 *
 * @param {string} computedName
 * @param {string} [path]
 * @param {string} [source]
 * @param {string} locationKeyName Pass through param to store the locations formatter value
 * @returns {Object}
 */
export function createLocationArrayComputedMap<Name extends string> (computedName: Name, path: string, locationKeyName?: string): ComputedMap<DiffMapComputedNames<Name>> {
  const rawName = `${computedName}Raw` as const
  const diffName = `${computedName}DiffMap` as const
  const computedMap = {} as ComputedMap<DiffMapComputedNames<Name>>
  const rawComputed = computed(() => {
    const appContextStore = useAppContextStore()
    const diffStore = useDiffStore()
    if (!appContextStore.nestedResponsePayload) return null
    const locations = appContextStore.nestedResponsePayload.get(path)
    if (!locations) return null
    // @ts-expect-error: Locations isn't strict enough
    return combineRemovedArrayItems(locations, path, diffStore.nestedRemovedDiffs)
  })
  computedMap[rawName] = rawComputed
  computedMap[computedName] = computed(() => {
    return locationArrayFormatter(
      // @ts-expect-error: Type does not match
      rawComputed.value,
      locationKeyName,
    )
  })
  computedMap[diffName] = computed(() => {
    const diffStore = useDiffStore()
    return createComputedDiffMap(
      // @ts-expect-error: Type does not match
      rawComputed.value,
      diffStore.diffMap,
      locationArrayFormatter,
      locationKeyName,
    )
  })
  return computedMap
}

/**
 * Generates 3 computed that can be spread into computed maps, used to generate chips for ChipReview or mapped lines for MultilineReview.
 *
 * @param {string} computedName
 * @param {string} path
 * @param {string|null} nestedName
 * @param {Object} nameMap
 * @returns {Object}
 */
export function createArrayComputedMap<Name extends string> (computedName: Name, path: string, nestedName: string | null, nameMap: Record<string, string>): ComputedMap<DiffComputedNames<Name>> {
  const formatter = (list: Record<string, string>): (string | null)[] => {
    if (!list) return []
    return Object.entries(list)
      .map(([key, val]) => {
        if (!val) return null
        // @ts-expect-error: Cannot use string
        const { v } = val[nestedName] || val
        if (!v) return null
        const name = nameMap[key]
        if (!name) console.warn(`Missing name for key ${key}`)
        return name || null
      }, [])
  }
  const rawName = `${computedName}Raw` as const
  const diffName = `${computedName}Diff` as const
  const computedMap = {} as ComputedMap<DiffComputedNames<Name>>
  const rawComputed = computed<AnyObject | null>(() => {
    const appContextStore = useAppContextStore()
    return get(appContextStore, `nestedResponseObjects.${path}`, null)
  })
  computedMap[rawName] = rawComputed
  computedMap[computedName] = computed(() => {
    return formatter(rawComputed.value!)
  })
  computedMap[diffName] = computed(() => {
    const diffStore = useDiffStore()
    return extractObjectDiffs(rawComputed.value, diffStore.diffMap, formatter, nestedName)
  })
  return computedMap
}

/**
 * Takes an array of unformatted data, finds the associated diff data, and then creates a formatted diffMap based solely on index & field name
 * @param {Array} data
 * @param {Object} diffMap
 * @param {Function} formatter
 * @returns {Object}
 */
export function createComputedDiffMap (data: Record<string, APIPayloadResponse>[] | null, diffMap: DiffMap | null, formatter: (v: TSFixMe, n: TSFixMe) => Record<string, APIPayloadResponseWithUUID>[], locationsKeyName?: string): DiffMap {
  const computedDiffMap: DiffMap = { added: {}, changed: {}, removed: {} }
  if (!data || !diffMap) return computedDiffMap
  // First create another array with all the diff data and mark what diffMap it's going into
  const diffData = data.map((d) => {
    // added/removed are only if every item is in those maps, changed is calculated per formatted item (here it extracts the older value as we already have the newer one)
    let isAdded = true
    let isRemoved = true
    const diffObject: Record<string, UnformattedExtractedDiff> = {}
    Object.entries(d).forEach(([key, { uuid4, v }]) => {
      const { added, changed, removed } = extractSingleDiff(uuid4, diffMap)
      if (!added) isAdded = false
      if (!removed) isRemoved = false
      diffObject[key] = { v: added ? null : removed ? removed.v : changed ? changed.v[0] : v }
    })
    return { ...diffObject, diffMapName: isAdded ? 'added' : isRemoved ? 'removed' : '' }
  })
  // Format the old and new data which assigns the index & fieldname based uuids
  const formattedData = formatter(data, locationsKeyName)
  const formattedDiffData = formatter(diffData, locationsKeyName)
  // Finally loop through the diffData and assign it to the correct diffmap
  formattedDiffData.forEach((d, index) => {
    const { diffMapName } = diffData[index]
    Object.entries(d).forEach(([key, { uuid4, k, v }]) => {
      if (diffMapName === 'added') computedDiffMap.added[uuid4] = { uuid4, k, v }
      else if (diffMapName === 'removed') computedDiffMap.removed[uuid4] = { uuid4, k, v }
      else {
        const { v: newV } = formattedData[index][key] || { v: null }
        if (newV !== v) computedDiffMap.changed[uuid4] = { uuid4, v: [v, newV] }
      }
    })
  })
  return computedDiffMap
}

/**
 * Removes any part of the removed diff that shouldn't be included.
 *
 * - We remove any array items with only array deletion keys as they are not real diffs and just a side-effect of previous unnecessary key injection.
 */
export function removeInvalidDeletedDiffs (obj: NestedResponseObject): void {
  const deletionKeys: string[] = Object.values(ARRAY_DELETION_KEYS)
  Object.values(obj).forEach((value) => {
    if (isAPIPayloadResponse(value)) return
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (!item) return
        if (Object.keys(item).every((k) => deletionKeys.includes(k))) {
          // We want to set it to `empty` and not `undefined` so that it does not get included in iterators
          delete value[index]
        }
      })
    } else {
      removeInvalidDeletedDiffs(value)
    }
  })
}
