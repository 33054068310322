import { createArrayComputedMap } from '@/utils/diffHelpers'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createArrayComputedMap('restaurantTypes', 'restaurantType', 'response', {
      fineDining: 'Fine Dining',
      fastFood: 'Fast Food',
      familyStyle: 'Family Style',
    }),
    ...createArrayComputedMap('restaurantConstruction', 'restaurantConstruction', 'response', {
      shoppingMall: 'Inside shopping malls',
      stripCenter: 'In strip centers',
      standaloneStore: 'As standalone stores',
    }),
    ...createArrayComputedMap('safetyDevices', 'safety', 'response', {
      emergencyLighting: 'Emergency Lighting',
      smokeDetectors: 'Smoke Detectors',
      sprinklers: 'Sprinklers',
    }),
    ...createArrayComputedMap('inspectionFrequency', 'inspection.inspectionFrequency', 'response', {
      annually: 'Annually',
      monthly: 'Monthly',
      weekly: 'Weekly',
      other: 'Other',
    }),
  }
}
