import { unitTypes } from 'snippets/bcdol/edit'
import { computed } from 'vue'

import { createArrayComputedMap } from '@/utils/diffHelpers'
import { formatDate } from '@/utils/formatter'
import { getInputData } from '@/utils/inputData'
import { createSignedProposalComputed } from '@/utils/programData'

import type { CreateMapFn } from '@/stores/programData'
import type { APIPayloadResponse } from '@policyfly/utils/types'

export interface BCDOLLocation {
  address: APIPayloadResponse<string>
  security?: APIPayloadResponse<boolean>
  percentFenced?: APIPayloadResponse<string>
  percentIndoor?: APIPayloadResponse<string>
}

export const createMap: CreateMapFn = () => {
  return {
    hasSignedProposal: createSignedProposalComputed('PROPOSAL'),
    ...createArrayComputedMap('unitTypes', 'unitTypes.onPremises', null, unitTypes.reduce<Record<string, string>>((acc, t) => {
      acc[t.path] = t.label
      return acc
    }, {})),
    creditsDebitsSummary: computed(() => {
      const locations = getInputData<BCDOLLocation[]>({ path: 'locations', source: 'responses' }) ?? []
      const lines: string[] = []
      for (const location of locations) {
        const locationName = `${location.address.v} location`
        if (location.security?.v) {
          lines.push(`2.5% security linked to policy discount applied to ${locationName}`)
        }
        if (Number(location.percentFenced?.v ?? 0) === 100) {
          lines.push(`10% fully fenced lot discount applied to ${locationName}`)
        }
        const percentIndoor = Number(location.percentIndoor?.v ?? 0)
        if (percentIndoor >= 24 && percentIndoor < 50) {
          lines.push(`7.5% indoor inventory discount applied to ${locationName}`)
        } else if (percentIndoor >= 50 && percentIndoor < 75) {
          lines.push(`15% indoor inventory discount applied to ${locationName}`)
        } else if (percentIndoor >= 75) {
          lines.push(`25% indoor inventory discount applied to ${locationName}`)
        }
      }
      if (getInputData<boolean>({ path: 'unitTypes.onPremises.new', source: 'responses' })) {
        lines.push('10% new car debit applied to all locations')
      }
      if (getInputData<boolean>({ path: 'unitTypes.onPremises.trailer', source: 'responses' })) {
        lines.push('10% trailer discount applied to all locations')
      }
      return !lines.length ? 'None' : lines.join('\n')
    }),
    defaultEndorsementSummary: computed(() => {
      const endorsementEffectiveDate = getInputData<string>({ path: 'effectiveDate', source: 'responses' })
      return `It is hereby understood and agreed that with effect from ${formatDate(endorsementEffectiveDate)} the following amendments are made to coverage:`
    }),
  }
}
