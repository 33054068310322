<template>
  <v-snackbar
    v-model="snackbar.model"
    class="snackbar"
    :color="snackbar.type"
    :timeout="snackbar.timeout"
    location="bottom end"
  >
    <span v-text="snackbar.msg" />
    <template #actions>
      <v-btn
        v-if="snackbar.close"
        variant="text"
        @click="onClose"
      >
        CLOSE
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { useNotificationStore } from '@/stores/notification'

export default defineComponent({
  name: 'Snackbar',

  setup () {
    const notificationStore = useNotificationStore()
    const snackbar = computed(() => notificationStore.snackbar)

    function onClose (): void {
      notificationStore.snackbar.model = false
    }

    return {
      snackbar,
      onClose,
    }
  },
})
</script>

<style lang="sass" scoped>
.snackbar
  padding-right: 22px
</style>
