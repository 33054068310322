import { normalizePath } from '@policyfly/utils/string'
// eslint-disable-next-line vue/prefer-import-from-vue
import { enableTracking, pauseTracking } from '@vue/reactivity'
import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAppContextStore } from '@/stores/appContext'
import { useAttachmentStore } from '@/stores/attachment'
import { useFeedStore } from '@/stores/feed'
import { useNotificationStore } from '@/stores/notification'
import { useProtobufStore } from '@/stores/protobuf'

import Payload from '@/lib/Payload'
import router from '@/router'
import routeNames from '@/router/routeNames'
import { hotReloadStore } from '@/utils/build'
import { loadParentApplication, loadParentApplicationChain } from '@/utils/policy'

import type { ResponsesObject } from '@/utils/policy'
import type { User as CoreUser } from '@policyfly/protobuf'
import type { TSFixMe } from '@policyfly/types/common'
import type { AxiosErrorCode } from 'types/api'
import type { Application } from 'types/application'
import type { APIPolicy, Policy } from 'types/policy'

export const usePolicyStore = defineStore({
  id: 'policy',

  state: () => ({
    id: null as Policy['id'] | null,
    status: null as Policy['status'] | null,
    latest_application: null as Application | null, // PolicyReadOnly['latest_application'] | null
    latest_issued_app: null as Policy['latest_issued_app'] | null,
    applications: [] as Policy['applications'],
    coverage_periods: [] as TSFixMe[], // PolicyReadOnly['coverage_periods']
    is_reclaim: false as boolean, // PolicyReadOnly['is_reclaim']
    can_renew: false as boolean, // PolicyReadOnly['can_renew']
    can_reinstate: false as boolean, // PolicyReadOnly['can_reinstate']
    can_reclaim: false as boolean, // PolicyReadOnly['can_reclaim']
    renewal_forbidden: null as Policy['renewal_forbidden'] | null,
    renewal: null as TSFixMe,
    parent: null as TSFixMe, // PolicyReadOnly['parent'] | null
    children: [] as TSFixMe[], // PolicyReadOnly['children']
    parentPayload: null as Payload | null,
  }),

  getters: {
    currentCoveragePeriod: (state) => {
      const today = new Date()
      // find the current period we fall into
      const currentPeriod: TSFixMe = state.coverage_periods.find((p: TSFixMe, i) => {
        if (i === state.coverage_periods.length - 1) return true
        if (p.kind === 'ENDORSEMENT') return false
        return today >= new Date(p.effective) && today <= new Date(p.expiration)
      })
      if (!currentPeriod) return {}
      let reinstated = false
      let expiration = currentPeriod.expiration
      // check for any cancellations that shorten the expiration date or endorsements that extend it
      for (const period of state.coverage_periods as TSFixMe[]) {
        if (period.id === currentPeriod.id) continue
        if (['PBE', 'NPB'].includes(period.category)) {
          if (new Date(period.expiration) > new Date(expiration)) expiration = period.expiration
        } else if (period.category === 'REI') {
          reinstated = true
        } else if (period.category === 'CAN') {
          if (!reinstated && period.effective === currentPeriod.effective && new Date(period.expiration) < new Date(expiration)) {
            expiration = period.expiration
            break
          }
          reinstated = false
        }
      }
      return {
        ...currentPeriod,
        expiration,
      }
    },
    /**
     * A Payload containing the most recent value for every path in the policy life.
     * Used for the `recent` source.
     */
    recentPayload: (state) => {
      const appContextStore = useAppContextStore()
      const applicationChain = loadParentApplicationChain(
        state.applications,
        appContextStore.loadedApplicationData.id!,
      )
      const recentPayload = new Payload()
      // reverse so that newer responses overwrite older ones
      for (const responses of applicationChain.reverse()) {
        pauseTracking()
        for (const response of responses) {
          recentPayload.copyAPIResponse(normalizePath(response.k), response, false)
        }
        enableTracking()
      }
      return recentPayload
    },
    appsByIterator: (state) => {
      return state.applications.reduce<Record<number, Application>>((apps, app) => {
        if (!app.endorsement_iterator) return apps
        apps[app.endorsement_iterator] = app
        return apps
      }, {})
    },
  },

  actions: {
    /**
     * Fetches the policy with the provided id and sets relevant state in other stores.
     * If a policy fails to load, may redirect based on the reason for failure.
     */
    async fetchPolicy (id: number): Promise<Policy> {
      const notificationStore = useNotificationStore()
      const apiStore = useApiStore()
      const protobufStore = useProtobufStore()
      try {
        const { json, policy } = await apiStore.policy.read(id)
        if (policy) protobufStore.policy = policy
        this.loadPolicy(json as Policy)
        return json as Policy
      } catch (err) {
        const errorResponse = (err as AxiosErrorCode).response
        if (errorResponse?.status === 400 && errorResponse.data?.error?.code === 'PROGRAM_ID_MISMATCH') {
          notificationStore.snackbar = {
            model: true,
            close: true,
            msg: 'That application does not belong to this program.',
            timeout: 0,
            type: 'error',
          }
          router.push({ name: routeNames.HOME })
        }
        throw err
      }
    },
    async transferPolicy (id: number, owner: CoreUser | null): Promise<void> {
      const apiStore = useApiStore()
      const protobufStore = useProtobufStore()

      const { json, policy } = await apiStore.policy.transfer(id, owner)
      if (policy) protobufStore.policy = policy
      this.loadPolicy(json as Policy)
    },
    loadPolicy (policy: Policy) {
      const feedStore = useFeedStore()
      const attachmentStore = useAttachmentStore()
      this.loadPolicyData(policy)
      feedStore.loadPolicyEvents(policy.events)
      // @ts-expect-error: Not on global policy definition
      const commentCount: number = policy.unread_comment_count || 0
      feedStore.loadApplicationCommentCount(commentCount)
      attachmentStore.allPolicyAttachments = policy.attachments
    },
    loadPolicyData (policy: Policy | APIPolicy) {
      // all nested Policies, even shallow references, have at least an id and the current application
      this.id = policy.id
      this.applications = (policy.applications || []) as Application[]
      this.status = policy.status
      this.latest_application = policy.latest_application as Application
      this.latest_issued_app = policy.latest_issued_app as Application
      this.applications = (policy.applications || []) as Application[]
      this.coverage_periods = policy.coverage_periods || []
      this.is_reclaim = policy.is_reclaim
      this.can_reclaim = policy.can_reclaim
      this.can_renew = policy.can_renew
      this.can_reinstate = policy.can_reinstate
      this.renewal_forbidden = policy.renewal_forbidden
      // @ts-expect-error: Apparently this doesn't exist?
      this.renewal = policy.renewal
      this.parent = policy.parent
      this.children = 'children' in policy ? policy.children : []
    },
    async regenerateParentPayload (): Promise<never[] | [ResponsesObject]> {
      const appContextStore = useAppContextStore()
      const parentDataset = await loadParentApplication(
        this.applications,
        appContextStore.loadedApplicationData.parentApplicationID!,
        this.parent?.id,
      )
      if (parentDataset[0]) {
        this.parentPayload = Payload.fromResponses(parentDataset[0].responses, false)
      } else {
        this.parentPayload = null
      }
      return parentDataset
    },
  },
})

hotReloadStore(usePolicyStore)
