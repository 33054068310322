import accountRoutes from './account'
import adminRoutes from './admin'
import agencyRoutes from './agency'
import applicationRoutes from './application'
import documentRoutes from './document'
import forgotPasswordRoutes from './forgotPassword'
import homeRoutes from './home'
import legalRoutes from './legal'
import loginRoutes from './login'
import onboardingRoutes from './onboarding'
import policyRoutes from './policy'
import ratingRoutes from './rating'
import releaseNotesRoutes from './releaseNotes'
import reportingRoutes from './reporting'
import signatureRoutes from './signature'
import usersRoutes from './users'
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

export const routes: StrictRouteConfig[] = [
  {
    path: '/',
    redirect: { name: routeNames.HOME },
  },
  ...legalRoutes,
  ...loginRoutes,
  ...onboardingRoutes,
  ...forgotPasswordRoutes,
  ...accountRoutes,
  ...documentRoutes,
  ...homeRoutes,
  ...agencyRoutes,
  ...usersRoutes,
  ...releaseNotesRoutes,
  ...applicationRoutes,
  ...policyRoutes,
  ...reportingRoutes,
  ...ratingRoutes,
  ...signatureRoutes,
  ...adminRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: routeNames.HOME },
  },
]
