import {
  ReadSchemaRequest,
  ReadSchemaResponse,
  SchemaServiceClient,
  SchemaSubType,
  SchemaType,
} from '@policyfly/protobuf'
import { toSentenceCase } from '@policyfly/utils/string'
import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport'
import { RpcError } from '@protobuf-ts/runtime-rpc'

import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type {
  SchemaAttachments,
  SchemaDefinition,
  SchemaSettings,
  SchemaTranslations,
} from '@policyfly/protobuf'

type SchemaKind = NonNullable<SchemaDefinition['definition']['oneofKind']>
type SchemaRequestSingle<T> = (opts: { slug: string }) => Promise<T>

/**
 * Extracts the schema from the response based on the provided `oneofKind`.
 *
 * If schema is missing throws an `RpcError` with `NOT_FOUND` status code.
 */
function extractSchema<T> (response: ReadSchemaResponse, oneofKind: SchemaKind): T {
  const definition = response.schema?.schema?.definition
  if (definition?.oneofKind !== oneofKind) {
    throw new RpcError('Missing schema definition', GrpcStatusCode[GrpcStatusCode.NOT_FOUND])
  }
  return definition[oneofKind as keyof typeof definition] as T
}

export interface SchemaApiEndpoints {
  /**
   * Loads an `attachments` schema for the specified `slug`.
   */
  attachments: SchemaRequestSingle<SchemaAttachments>
  /**
   * Loads a `settings` schema for the specified `slug`.
   */
  settings: SchemaRequestSingle<SchemaSettings>
  /**
   * Loads a `translations` schema for the specified `slug`.
   */
  translations: SchemaRequestSingle<SchemaTranslations>
}

export const createSchemaEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<SchemaApiEndpoints> => {
  const schemaServiceClient = new SchemaServiceClient(params.transport)

  function createSchemaRequestSingle<T> (type: SchemaType, kind: SchemaKind): SchemaRequestSingle<T> {
    return async (opts) => {
      const request = ReadSchemaRequest.create({
        slug: opts.slug,
        type,
        subType: SchemaSubType.SCHEMA_SUB_TYPE_INITIAL,
      })
      const { response } = await schemaServiceClient.read(request)

      devtools.logGrpc({
        description: `${toSentenceCase(kind)} Schema`,
        messages: [
          { type: ReadSchemaRequest, key: 'request', message: request },
          { type: ReadSchemaResponse, key: 'response', message: response },
        ],
      })

      return extractSchema(response, kind)
    }
  }

  const endpoints = {
    attachments: createSchemaRequestSingle<SchemaAttachments>(SchemaType.SCHEMA_TYPE_ATTACHMENTS, 'attachments'),
    settings: createSchemaRequestSingle<SchemaSettings>(SchemaType.SCHEMA_TYPE_SETTINGS, 'settings'),
    translations: createSchemaRequestSingle<SchemaTranslations>(SchemaType.SCHEMA_TYPE_TRANSLATION, 'translations'),
  }

  return {
    django: endpoints,
    grpc: endpoints,
  }
}
