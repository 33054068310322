import {
  EventIDType,
  EventLogServiceClient,
  ListEventEntriesByIDResponse,
  ListEventsByIDRequest,
  EventVisibility,
} from '@policyfly/protobuf'
import { computed } from 'vue'

import { useAuthenticationStore } from '@/stores/authentication'
import { useFeedStore } from '@/stores/feed'

import { devtools } from '@/plugins/devtools/api'
import { eventEntryResponseToFeedEventLog } from '@/utils/protobuf'

import type { GrpcLogLevel } from '@/plugins/devtools/api'
import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type { EventEntryResponse } from '@policyfly/protobuf'
import type { FeedEventLog } from 'types/events'

export interface EventApiEndpoints {
  list: (params: {
    id: string
    type?: EventIDType
    include?: string
    exclude?: string
    limit?: number
    logLevel?: GrpcLogLevel
  }) => Promise<{
    events?: EventEntryResponse[]
    json: FeedEventLog[]
  }>
}

/**
 * Creates endpoints related to the Application workflow.
 */
export const createEventEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<EventApiEndpoints> => {
  const eventLogServiceClient = new EventLogServiceClient(params.transport)
  const authenticationStore = useAuthenticationStore()
  const visibility = computed(() => {
    if (authenticationStore.userRole === 'SUPERUSER') return EventVisibility.EVENT_VISIBILITY_STAFF
    if (authenticationStore.isInternalRole) return EventVisibility.EVENT_VISIBILITY_RESTRICTED
    return EventVisibility.EVENT_VISIBILITY_STANDARD
  })

  const listDjango: EventApiEndpoints['list'] = async ({ type = EventIDType.EventIDType_Application }) => {
    const feedStore = useFeedStore()

    const json = type === EventIDType.EventIDType_Application
      ? feedStore.allApplicationEvents
      : feedStore.allPolicyEvents

    return {
      json,
    }
  }

  const listGrpc: EventApiEndpoints['list'] = async ({ id, type = EventIDType.EventIDType_Unspecified, include, exclude, limit, logLevel }) => {
    const request = ListEventsByIDRequest.create({
      id,
      type,
      eventTypeFilter: include,
      limit,
      visibility: visibility.value,
      eventTypeExclusions: exclude,
    })
    const { response } = await eventLogServiceClient.listEventEntriesByID(request)

    devtools.logGrpc({
      description: 'List Events',
      messages: [
        { type: ListEventsByIDRequest, key: 'request', message: request },
        { type: ListEventEntriesByIDResponse, key: 'response', message: response },
      ],
      logLevel,
    })

    return {
      events: response.events,
      json: response.events.map(eventEntryResponseToFeedEventLog),
    }
  }

  return {
    django: {
      list: listDjango,
    },
    grpc: {
      list: listGrpc,
    },
  }
}
