import { SchemaTranslations } from '@policyfly/protobuf'

import { page } from './page'

// Default translations, program specific translations are loaded in settings store
export const en = SchemaTranslations.create({
  page,
  action: {
    activatePolicy: {
      button: 'ACTIVATE {type}',
      dialogTitle: 'Confirm {type} Activation',
      dialogBody: [
        {
          value: 'Please confirm the activation of this cancellation.',
          condition: {
            conditions: [{
              type: {
                oneofKind: 'common',
                common: {
                  inputData: {
                    source: {
                      oneofKind: 'store',
                      store: {
                        path: 'isCancellation',
                        store: 'appContext',
                      },
                    },
                  },
                  permittedValues: [
                    {
                      value: {
                        oneofKind: 'bool',
                        bool: true,
                      },
                    },
                  ],
                  restrictedValues: [],
                },
              },
            }],
          },
        },
        { value: 'Please confirm you have sent the certificate and debit notice to the agent. If not please do so before activating the {type}.' },
      ],
    },
  },
})
