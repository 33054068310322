/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { registrationGuard } from '@/router/middleware'
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const RegisterPage = () => import('@/pages/Onboarding/RegisterPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/register',
    name: routeNames.REGISTER,
    component: RegisterPage,
    beforeEnter: registrationGuard,
    meta: { hideNavbar: true },
  },
]

export default routeConfig
