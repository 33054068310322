/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { accountGuard } from '@/router/middleware'
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const MyAccountLayout = () => import('@/layouts/MyAccountLayout.vue')
const MyAccountIndexPage = () => import('@/pages/MyAccount/MyAccountIndexPage.vue')
const LogoutPage = () => import('@/pages/MyAccount/LogoutPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/account',
    component: MyAccountLayout,
    meta: { requiresAuth: true, accountLayout: true },
    beforeEnter: accountGuard,
    children: [
      {
        path: '',
        component: MyAccountIndexPage,
        name: routeNames.ACCOUNT_INDEX,
      },
      {
        path: '*',
        redirect: { name: routeNames.ACCOUNT_INDEX },
      },
    ],
  },
  {
    path: '/logout',
    component: LogoutPage,
    name: routeNames.ACCOUNT_LOGOUT,
    meta: { hideNavbar: true },
  },
]

export default routeConfig
