import { TaskStatus } from '@policyfly/protobuf/patrick'

/**
 * A map of task statuses to their displayed status.
 */
export const TASK_STATUS_MAP = {
  [TaskStatus.TASK_STATUS_UNKNOWN]: 'INCOMPLETE',
  [TaskStatus.TASK_STATUS_OPEN]: 'INCOMPLETE',
  [TaskStatus.TASK_STATUS_PENDING_VERIFICATION]: 'PENDING VERIFICATION',
  [TaskStatus.TASK_STATUS_CLOSED]: 'COMPLETE',
} as const
