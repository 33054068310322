import { MESSAGE_TYPE } from '@protobuf-ts/runtime'
import { markRaw } from 'vue'

// force protobuf messages internal property to be marked as raw
const originalCreate = Object.create
Object.defineProperty(Object, 'create', {
  value (...args: Parameters<typeof originalCreate>) {
    if (args[1] && MESSAGE_TYPE in args[1]) {
      args[1][MESSAGE_TYPE] = { value: markRaw(args[1][MESSAGE_TYPE].value) }
    }
    return originalCreate(...args)
  },
})
