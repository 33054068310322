// code adapted from "locale2" v2.3.1 https://github.com/moimikey/locale2
import type { TSFixMe } from '@policyfly/types/common'

function formatLocale (localeToFormat: unknown): string {
  if (typeof localeToFormat !== 'string') return 'en-US'
  return localeToFormat.split('-').slice(0, 2).map(function (chunk, index) {
    if (index !== 0 && chunk.length === 2) return chunk.toUpperCase()
    return chunk
  }).join('-')
}

function getLocale (): string {
  const windowLocale: Window & { chrome?: TSFixMe, Intl?: TSFixMe } = window

  let currentLocale = ''

  if (windowLocale.chrome && windowLocale.chrome.runtime && typeof windowLocale.chrome.runtime.getManifest === 'function') {
    const chromeLocale = windowLocale.chrome.runtime.getManifest()
    if (chromeLocale && chromeLocale.current_locale) {
      return chromeLocale.current_locale
    }
  }

  currentLocale = (windowLocale.navigator && (
    (windowLocale.navigator.languages && windowLocale.navigator.languages[0]) ||
    windowLocale.navigator.language ||
    // @ts-expect-error: Only exists in some browsers
    windowLocale.navigator.userLanguage
  ))

  if (!currentLocale && windowLocale.navigator && windowLocale.navigator.userAgent) {
    const userAgentLocale = windowLocale.navigator.userAgent.match(/;.(\w+-\w+)/i)
    if (userAgentLocale) return userAgentLocale[1]
  }

  if (!currentLocale) {
    currentLocale = (windowLocale.clientInformation || /* istanbul ignore next -- @preserve */ Object.create(null)).language
  }

  if (!currentLocale) {
    if (windowLocale.Intl && typeof windowLocale.Intl.DateTimeFormat === 'function') {
      currentLocale = windowLocale.Intl.DateTimeFormat().resolvedOptions && windowLocale.Intl.DateTimeFormat().resolvedOptions().locale
    }
  }
  return currentLocale
}

export function locale (): string {
  return formatLocale(getLocale())
}

/**
 * Return the date format based on the current locale.
 * Locales that allow a single digit for day/month are forced to use 2 for consistency.
 *
 * @example
 * 'en-US' -> 'MM/DD/YYYY'
 * 'en-GB' -> 'DD/MM/YYYY'
 */
export function localeDateFormat (): string {
  return new Intl.DateTimeFormat(locale(), {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
    .formatToParts()
    .map((p) => {
      switch (p.type) {
        case 'day': return 'DD'
        case 'month': return 'MM'
        case 'year': return 'YYYY'
        case 'literal': return p.value
        default: return ''
      }
    })
    .join('')
}
