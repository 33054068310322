import {
  ArchiveAttachmentRequest,
  AlexandriaServiceClient,
  CreateAttachmentResponse,
  CreateAttachmentRequest,
  DeleteAttachmentResponse,
  DeleteAttachmentRequest,
  ReadAttachmentResponse,
  ReadAttachmentRequest,
  RestoreAttachmentRequest,
  RestoreAttachmentResponse,
  AttachmentVisibility,
  ArchiveAttachmentResponse,
  AttachmentMetadata,
  ListAttachmentRequest,
  AttachmentIDType,
  ListAttachmentResponse,
  UpdateAttachmentVisibilityRequest,
  UpdateAttachmentVisibilityResponse,
  RenameAttachmentRequest,
  RenameAttachmentResponse,
} from '@policyfly/protobuf'

import { useAttachmentStore } from '@/stores/attachment'
import { useAuthenticationStore } from '@/stores/authentication'

import { api } from '@/api'
import { devtools } from '@/plugins/devtools/api'
import { attachmentToApplicationAttachment, systemAttachmentCategoryToAttachmentCategory } from '@/utils/protobuf'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type {
  AttachmentCategory,
  Attachment,
} from '@policyfly/protobuf'
import type { AttachmentCategory as LegacyAttachmentCategory } from '@policyfly/schema/types/shared/attachmentCategory'
import type { MakeKeysOptional } from '@policyfly/types/utils'
import type { ApplicationAttachment } from 'types/application'
import type { FeedEventLog } from 'types/events'

export interface AttachmentApiEndpoints {
  /**
   * Archive an attachment, will return attachment data when soft archiving
   */
  archive: (id: number, soft?: boolean) => Promise<{
    attachment?: Attachment
    eventLog?: FeedEventLog
    json: ApplicationAttachment
  }>
  /**
   * Remove attachment metadata and delete stored data
   */
  delete: (id: number) => Promise<{ eventLog?: FeedEventLog }>
  /**
   * List all attachments for currently loaded application or policy
   */
  list: (id: number, type?: AttachmentIDType) => Promise<{
    attachments?: Attachment[]
    json: ApplicationAttachment[]
  }>
  /**
   * Read attachment data from the server
   */
  read: (id: number) => Promise<{
    attachment?: Attachment
    json: ApplicationAttachment
  }>
  /**
   * Unarchive an attachment that has been soft archived
   */
  restore: (id: number) => Promise<{
    attachment?: Attachment
    eventLog?: FeedEventLog
    json: ApplicationAttachment
  }>
  /**
   * Update the name of an attachment
   */
  rename: (id: number, name: string) => Promise<{
    attachment?: Attachment
    eventLog?: FeedEventLog
    json: ApplicationAttachment
  }>
  /**
   * Set the visibility of an attachment
   */
  updateVisibility: (id: number, visibility: AttachmentVisibility) => Promise<{
    attachment?: Attachment
    eventLog?: FeedEventLog
    json: ApplicationAttachment
  }>
  /**
   * Upload a file and store it on the server
   */
  upload: (
    file: File,
    meta: AttachmentApiMeta,
    fuzzy?: boolean
  ) => Promise<{
    attachment?: Attachment
    eventLog?: FeedEventLog
    json: ApplicationAttachment
  }>
}

/**
 * Metadata fields for uploading an attachment
 *
 * Both legacy and protobuf attachment categories are supported
 */
export type AttachmentApiMeta =
  Pick<AttachmentMetadata, 'applicationIds' | 'policyId'>
  & Pick<MakeKeysOptional<AttachmentMetadata, 'policyDocument'>, 'policyDocument'>
  & { category?: AttachmentCategory | LegacyAttachmentCategory | undefined }

/**
 * Creates endpoints for uploading and managing files
 */
export const createAttachmentEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<AttachmentApiEndpoints> => {
  const alexandriaServiceClient = new AlexandriaServiceClient(params.transport)

  const archiveDjango: AttachmentApiEndpoints['archive'] = async (id, soft = false) => {
    // soft archive response includes FeedEvent
    const { data } = !soft
      ? await api.attachments.archive({ path: { pk: id }, body: { log: false } })
      : await api.attachments.soft_archive({ path: { pk: id } })

    return {
      // @ts-expect-error: Attachment in swagger doesn't match our types
      json: data.Attachment as ApplicationAttachment,
      eventLog: data.EventLog,
    }
  }
  const archiveGprc: AttachmentApiEndpoints['archive'] = async (id, soft = false) => {
    const request = ArchiveAttachmentRequest.create({
      attachmentId: String(id),
      softArchive: soft,
      restorable: soft,
    })
    const { response } = await alexandriaServiceClient.archive(request)

    devtools.logGrpc({
      description: 'Archive Attachment',
      messages: [
        { type: ArchiveAttachmentRequest, key: 'request', message: request },
        { type: ArchiveAttachmentResponse, key: 'response', message: response },
      ],
    })

    return await readGrpc(id)
  }

  const deleteDjango: AttachmentApiEndpoints['delete'] = async (id) => {
    const { data } = await api.attachments.remove({ path: { pk: id } })

    return {
      eventLog: data.EventLog,
    }
  }
  const deleteGrpc: AttachmentApiEndpoints['delete'] = async (id) => {
    const request: DeleteAttachmentRequest = { attachmentId: String(id) }
    const { response } = await alexandriaServiceClient.delete(request)

    devtools.logGrpc({
      description: 'Delete Attachment',
      messages: [
        { type: DeleteAttachmentRequest, key: 'request', message: request },
        { type: DeleteAttachmentResponse, key: 'response', message: response },
      ],
    })

    return {
      // @todo once EventLogServiceClient has been implemented on BE, return an event
    }
  }

  const listDjango: AttachmentApiEndpoints['list'] = async (_id, type) => {
    const attachmentStore = useAttachmentStore()
    return {
      json: type === AttachmentIDType.ID_Type_Policy
        ? attachmentStore.allPolicyAttachments
        : attachmentStore.allApplicationAttachments,
    }
  }

  const listGrpc: AttachmentApiEndpoints['list'] = async (id, type = AttachmentIDType.ID_Type_Application) => {
    const attachmentRequest = ListAttachmentRequest.create({
      id: `${id}`,
      type,
      full: false,
    })
    const { response: attachmentResponse } = await alexandriaServiceClient.list(attachmentRequest)

    devtools.logGrpc({
      description: 'List Attachments',
      messages: [
        { type: ListAttachmentRequest, key: 'request', message: attachmentRequest },
        { type: ListAttachmentResponse, key: 'response', message: attachmentResponse },
      ],
    })

    return {
      attachments: attachmentResponse.attachments,
      json: attachmentResponse.attachments.map(attachmentToApplicationAttachment),
    }
  }

  const readDjango: AttachmentApiEndpoints['read'] = async (id) => {
    const { data } = await api.attachments.read({ path: { pk: id } })

    return {
      // @ts-expect-error: Attachment in swagger doesn't match our types
      json: data.Attachment as ApplicationAttachment,
    }
  }

  const readGrpc: AttachmentApiEndpoints['read'] = async (id) => {
    const request = ReadAttachmentRequest.create({ attachmentId: String(id) })
    const { response } = await alexandriaServiceClient.read(request)

    devtools.logGrpc({
      description: 'Read Attachment',
      messages: [
        { type: ReadAttachmentRequest, key: 'request', message: request },
        { type: ReadAttachmentResponse, key: 'response', message: response },
      ],
    })

    if (!response.attachment) throw new Error(`Unable to retrieve Attachment: ${id}`)

    return {
      json: attachmentToApplicationAttachment(response.attachment),
      attachment: response.attachment,
    }
  }

  const restoreDjango: AttachmentApiEndpoints['restore'] = async (id) => {
    const { data } = await api.attachments.restore({ path: { pk: id } })

    return {
      // @ts-expect-error: Attachment in swagger doesn't match our types
      json: data.Attachment as ApplicationAttachment,
      eventLog: data.EventLog,
    }
  }
  const restoreGrpc: AttachmentApiEndpoints['restore'] = async (id) => {
    const request: RestoreAttachmentRequest = { attachmentId: String(id) }
    const { response } = await alexandriaServiceClient.restore(request)

    devtools.logGrpc({
      description: 'Restore Attachment',
      messages: [
        { type: RestoreAttachmentRequest, key: 'request', message: request },
        { type: RestoreAttachmentResponse, key: 'response', message: response },
      ],
    })

    return await readGrpc(id)
  }

  const renameDjango: AttachmentApiEndpoints['rename'] = async (id, name) => {
    // django endpoint requires name without extension
    const { data } = await api.attachments.rename({ path: { pk: id }, body: { name: name.split('.')[0] } })

    return {
      // @ts-expect-error: Attachment in swagger doesn't match our types
      json: data.Attachment as ApplicationAttachment,
      eventLog: data.EventLog,
    }
  }
  const renameGrpc: AttachmentApiEndpoints['rename'] = async (id, name) => {
    const request = RenameAttachmentRequest.create({ id: String(id), newName: name })
    const { response } = await alexandriaServiceClient.rename(request)

    devtools.logGrpc({
      description: 'Rename Attachment',
      messages: [
        { type: RenameAttachmentRequest, key: 'request', message: request },
        { type: RenameAttachmentResponse, key: 'response', message: response },
      ],
    })

    return readGrpc(id)
  }

  const uploadDjango: AttachmentApiEndpoints['upload'] = async (file, meta, fuzzy) => {
    const payload = {
      application: Number(meta.applicationIds[0]),
      policyId: undefined,
      policy: meta.policyDocument ? Number(meta.policyId) : undefined,
      category: meta.category,
      file,
    }
    const formData = new FormData()
    Object.entries(payload).forEach(([key, value]: [string, string | number | boolean | File | undefined]) => {
      if (value !== undefined) formData.append(key, key === 'file' ? value as File : String(value))
    })

    // Use policy upload endpoint when called from PolicyDocsPage
    const { data } = meta.policyDocument
      ? await api.policies.upload({ body: formData })
      : await api.applications.upload({ body: formData, query: fuzzy ? { mode: 'fuzzy' } : {} })

    return {
      // @ts-expect-error: Attachment in swagger doesn't match our types
      json: meta.policyDocument ? data.Policy.attachments[0] : data.Attachment,
      eventLog: data.EventLog,
    }
  }
  const uploadGrpc: AttachmentApiEndpoints['upload'] = async (file, meta) => {
    const { applicationIds, policyId, policyDocument, category } = meta
    const authenticationStore = useAuthenticationStore()
    const request = CreateAttachmentRequest.create({
      attachment: {
        data: new Uint8Array(await file.arrayBuffer()),
        metadata: AttachmentMetadata.create({
          name: file.name,
          mimeType: file.type,
          sizeBytes: String(file.size),
          applicationIds,
          policyId,
          policyDocument,
          programId: String(authenticationStore.programId),
          category: typeof category === 'string'
            ? systemAttachmentCategoryToAttachmentCategory(category)
            : category,
        }),
      },
    })
    const { response } = await alexandriaServiceClient.create(request)

    devtools.logGrpc({
      description: 'Upload Attachment',
      messages: [
        { type: CreateAttachmentRequest, key: 'request', message: request },
        { type: CreateAttachmentResponse, key: 'response', message: response },
      ],
    })

    // @todo once EventLogServiceClient has been implemented on BE, return an event
    return await readGrpc(Number(response.id))
  }

  const updateVisibilityDjango: AttachmentApiEndpoints['updateVisibility'] = async (id, visibility) => {
    const { data } = await api.attachments.set_visibility({
      path: { pk: id },
      body: { restricted_visibility: visibility === AttachmentVisibility.Visibility_Restricted },
    })

    return {
      // @ts-expect-error: Attachment in swagger doesn't match our types
      json: data.Attachment as ApplicationAttachment,
      eventLog: data.EventLog,
    }
  }
  const updateVisibilityGrpc: AttachmentApiEndpoints['updateVisibility'] = async (id, visibility) => {
    const request = UpdateAttachmentVisibilityRequest.create({
      id: String(id),
      newVisibility: visibility,
    })
    const { response } = await alexandriaServiceClient.updateVisibility(request)

    devtools.logGrpc({
      description: 'Set Attachment Visibility',
      messages: [
        { type: UpdateAttachmentVisibilityRequest, key: 'request', message: request },
        { type: UpdateAttachmentVisibilityResponse, key: 'response', message: response },
      ],
    })

    return readGrpc(id)
  }

  return {
    django: {
      archive: archiveDjango,
      delete: deleteDjango,
      list: listDjango,
      read: readDjango,
      rename: renameDjango,
      restore: restoreDjango,
      updateVisibility: updateVisibilityDjango,
      upload: uploadDjango,
    },
    grpc: {
      archive: archiveGprc,
      delete: deleteGrpc,
      list: listGrpc,
      read: readGrpc,
      restore: restoreGrpc,
      rename: renameGrpc,
      updateVisibility: updateVisibilityGrpc,
      upload: uploadGrpc,
    },
  }
}
