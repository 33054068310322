/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const ForgotPasswordLayout = () => import('@/layouts/ForgotPasswordLayout.vue')
const ResetPasswordPage = () => import('@/pages/ForgotPassword/ResetPasswordPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/forgotpassword',
    component: ForgotPasswordLayout,
    children: [
      {
        path: 'resetpassword',
        component: ResetPasswordPage,
        name: routeNames.FORGOT_PASSWORD_RESET_PASSWORD,
        meta: { hideNavbar: true },
      },
    ],
  },
]

export default routeConfig
