import { filter, createClient } from '@prismicio/client'

import type { TSFixMe } from '@policyfly/types/common'
import type { Client, PrismicDocument } from '@prismicio/client'

type ProgramIdMap = Record<string, string>
type RichTextField = [TSFixMe, ...TSFixMe[]]

export interface PrismicDoc {
  date_of_release: string
  programs: TSFixMe[]
  release_id: number | null
  title: RichTextField
  description: RichTextField
  priority: boolean
  main_content: MainContent[]
  production: boolean
  visibility: 'both' | 'progmin' | 'agent' | null
}

export interface MainContent {
  header: RichTextField
  subheader: RichTextField
  subtitle: RichTextField
  body: RichTextField
  image: TSFixMe
}

export interface ReleaseNote {
  id: string
  date_of_release: string
  created: string | null
  programs: TSFixMe[]
  release_id: number | null
  titleRaw: RichTextField
  descriptionRaw: RichTextField
  production: boolean
  priority: boolean
  title: string
  description: string
  main_content: MainContent[]
  visibility: 'both' | 'progmin' | 'agent' | null
}

class ContentManagementAdapter {
  private _baseUrl: string
  private _client: Client | null
  private _programMap: ProgramIdMap | null = null
  public isConnected = false

  constructor (url: string) {
    this._baseUrl = url
    this._client = this.connect()
  }

  public async init (): Promise<void> {
    if (!this._client) return
    const response = await this._client.get({
      filters: [filter.at('document.type', 'program')],
    })
    this._programMap = response.results.reduce((map, program) => {
      map[program.data.slug] = program.id
      return map
    }, {} as ProgramIdMap)
  }

  private connect (): Client | null {
    try {
      const client = createClient(this._baseUrl)
      this.isConnected = true
      return client
    } catch (err) {
      console.error(err)
      return null
    }
  }

  private transformDoc (doc: PrismicDocument): ReleaseNote {
    const {
      description,
      date_of_release,
      main_content,
      production,
      priority,
      programs,
      release_id,
      title,
      visibility,
    } = doc.data
    const [noteTitle] = title ?? []
    const [noteDesc] = description ?? []
    const created = doc.first_publication_date
    return {
      id: doc.id,
      date_of_release,
      created,
      programs,
      release_id,
      priority,
      titleRaw: title,
      title: noteTitle?.text,
      production,
      description: noteDesc?.text,
      descriptionRaw: description,
      main_content,
      visibility,
    }
  }

  public async getReleaseNotes ({ program, isInternalRole, prodOnly }: { program: string, isInternalRole: boolean, prodOnly: boolean }): Promise<ReleaseNote[]> {
    if (!this._client) return []
    if (!this._programMap) await this.init()
    try {
      const slugId = this._programMap![program]
      if (!slugId) {
        console.warn(`Program is not defined within Prismic: ${program}`)
        return []
      }
      const filters = [
        filter.at('document.type', 'release_note'),
        filter.at('my.release_note.programs.program', slugId),
        filter.any('my.release_note.visibility', isInternalRole ? ['both', 'progmin'] : ['both', 'agent']),
      ]
      if (prodOnly) filters.push(filter.at('my.release_note.production', prodOnly))
      const response = await this._client.get({ filters })
      return response.results.map(this.transformDoc)
    } catch (err) {
      console.error(err)
      return []
    }
  }
}

export default ContentManagementAdapter
