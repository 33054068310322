/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { loggedInGuard } from '@/router/middleware'
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const NoProgramsPage = () => import('@/pages/Login/NoProgramsPage.vue')
const LoginPage = () => import('@/pages/Login/LoginPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/login',
    name: routeNames.LOGIN,
    component: LoginPage,
    beforeEnter: loggedInGuard,
    meta: { hideSidebar: true, hideNavbar: true },
  },
  {
    path: '/no-programs',
    name: routeNames.NO_PROGRAMS,
    component: NoProgramsPage,
    meta: { hideSidebar: true, hideNavbar: true },
  },
]

export default routeConfig
