import { defineAsyncComponent, markRaw } from 'vue'

import type { ConfirmComponentNames } from '@/stores/notification'
import type { Component } from 'vue'

export const dialogComponentMap: Record<ConfirmComponentNames, Component> = markRaw({
  AddAgency: defineAsyncComponent(() => import('./dialog/AddAgency.vue')),
  AddAgent: defineAsyncComponent(() => import('./dialog/AddAgent.vue')),
  DateOption: defineAsyncComponent(() => import('./dialog/DateOption.vue')),
  Default: defineAsyncComponent(() => import('./dialog/Default.vue')),
  EndorsementCorrection: defineAsyncComponent(() => import('./dialog/EndorsementCorrection.vue')),
  ManageAuthenticator: defineAsyncComponent(() => import('./dialog/ManageAuthenticator.vue')),
  ManageProgramAccess: defineAsyncComponent(() => import('./dialog/ManageProgramAccess.vue')),
  ManageSms: defineAsyncComponent(() => import('./dialog/ManageSms.vue')),
  RadioOption: defineAsyncComponent(() => import('./dialog/RadioOption.vue')),
  TextField: defineAsyncComponent(() => import('./dialog/TextField.vue')),
})
