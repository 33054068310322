import {
  PatrickServiceClient,
  ListApplicationsRequest,
  ListApplicationsResponse,
} from '@policyfly/protobuf/patrick'

import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface SearchApiEndpoints {
  /**
   * @see {@link PatrickServiceClient.listApplications listApplications}
   */
  listApplications: (request: ListApplicationsRequest, abortController: AbortController) => Promise<ListApplicationsResponse>
}

/**
 * Creates endpoints related to search functionality.
 */
export const createSearchEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<SearchApiEndpoints> => {
  const patrickServiceClient = new PatrickServiceClient(params.transport)

  const listApplications: SearchApiEndpoints['listApplications'] = async (request, abortController) => {
    const { response } = await patrickServiceClient.listApplications(request, { abort: abortController.signal })

    devtools.logGrpc({
      description: 'List Applications',
      messages: [
        { type: ListApplicationsRequest, key: 'request', message: request },
        { type: ListApplicationsResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const endpoints: SearchApiEndpoints = {
    listApplications,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
