import { useAppContextStore } from '@/stores/appContext'
import { useAttachmentStore } from '@/stores/attachment'
import { useAuthenticationStore } from '@/stores/authentication'
import { useDiffStore } from '@/stores/diff'
import { usePolicyStore } from '@/stores/policy'

type InternalPiniaKeys = '$id' | '$state' | '_p' | '$patch' | '$reset' | '$subscribe' | '_as' | '$onAction'
export type DynamicStores = typeof dynamicStores
export type DynamicStoreName = keyof DynamicStores
export type DynamicStoreKeys = { [K in keyof DynamicStores]: Exclude<keyof ReturnType<DynamicStores[K]>, InternalPiniaKeys> }
export type DynamicStoreAllKeys = DynamicStoreKeys[keyof DynamicStores] | string

export const dynamicStores = {
  appContext: () => useAppContextStore(),
  attachment: () => useAttachmentStore(),
  authentication: () => useAuthenticationStore(),
  diff: () => useDiffStore(),
  policy: () => usePolicyStore(),
} as const

interface UseDynamicStoreReturnValue<T extends DynamicStoreName> {
  store: ReturnType<typeof dynamicStores[T]>
}

export function useDynamicStore<T extends DynamicStoreName> (storeName: T): UseDynamicStoreReturnValue<T> {
  const dynamicStore = dynamicStores[storeName]
  const store = dynamicStore() as UseDynamicStoreReturnValue<T>['store']

  return {
    store,
  }
}
