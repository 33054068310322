import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import { routes } from './paths'

const router = createRouter({
  history: import.meta.env.MODE === 'test' ? createMemoryHistory() : createWebHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
})

router.onError((err) => {
  if (/loading chunk .* failed./i.test(err.message)) {
    location.reload()
  }
})

export default router
