import { request } from '../methods'

export default {
  read: request('get', '/api/v1/policies/{pk}/'),
  create: request('post', '/api/v1/policies/'),
  transfer: request('put', '/api/v1/policies/{pk}/transfer/'),
  upload: request('post', '/api/v1/policies/upload/'),
  forbid_renewal: request('put', '/api/v1/policies/{pk}/forbid_renewal/'),
  reinstate: request('put', '/api/v1/policies/{pk}/reinstate/'),
  abort_cancellation: request('put', '/api/v1/policies/{pk}/abort_cancellation/'),
  // @ts-expect-error: this is a TYAPD only method, we just don't have a way to represent that yet
  generate_endorsement_correction: request('post', '/api/v1/policies/{pk}/endorsement_summary/'),
}
