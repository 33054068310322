import { DisplayIf as DisplayIfGrpc } from '@policyfly/protobuf'
import { revertSchema } from '@policyfly/schema'
import { preferNumberCompare } from '@policyfly/utils/number'
import { castArray } from '@policyfly/utils/type'

import { PayloadArray } from '@/lib/Payload'
import { getDateToday, isValidDateString, offsetDate } from '@/utils/date'
import { blankInputDataResolver, getInputData, createInputDataPathResolver } from '@/utils/inputData'

import type { PayloadLike } from '@/lib/Payload'
import type {
  DisplayIf,
  Condition,
  DisplayIfComparator,
  DisplayIfComparatorIncludes,
  MatchCondition,
} from '@policyfly/schema/types/shared/displayIf'
import type { FormSource, FormSourceDisplayIf } from '@policyfly/schema/types/shared/formSource'
import type { InputData, InputDataResolved, InputDataResolver } from '@policyfly/schema/types/shared/inputData'
import type { PrimitiveValue, TSFixMe } from '@policyfly/types/common'

type ValueListValue = NonNullable<Condition['permittedValues']>[number]
type DisplayIfValueList = (ValueListValue | undefined)[] | undefined
type ComparatorFunction = (v: TSFixMe, data: InputData<FormSource>) => boolean

/**
 * @deprecated Temporary measure to support both formats of DisplayIf.
 */
export function revertDisplayIf (displayIf: DisplayIf | DisplayIfGrpc): DisplayIf {
  if (Array.isArray(displayIf) || !('operator' in displayIf) || typeof displayIf.operator !== 'number') {
    return displayIf
  }
  return revertSchema(DisplayIfGrpc, displayIf)
}

/**
 * Checks a {@link DisplayIf} condition.
 * Returns `true` if no conditions are passed or the condition passes.
 */
export function checkDisplayIf (
  displayIf: DisplayIf | DisplayIfGrpc | null | undefined,
  localValues?: PayloadLike | null,
  resolver?: InputDataResolver | null,
): boolean {
  if (!displayIf) return true
  displayIf = revertDisplayIf(displayIf)
  // if array of operator | condition
  if (Array.isArray(displayIf)) {
    return displayIf.every((c) => checkDisplayIf(c, localValues, resolver))
  }
  // if operator object
  if ('operator' in displayIf) {
    switch (displayIf.operator) {
      case 'AND': return displayIf.conditions.every((c) => checkDisplayIf(c, localValues, resolver))
      case 'OR': return displayIf.conditions.some((c) => checkDisplayIf(c, localValues, resolver))
    }
  }
  // if non-operator object
  return checkCondition(displayIf, localValues, resolver)
}

/**
 * Returns all the conditions in a DisplayIf.
 * Deeply recurses through the object to find all conditions.
 */
export function getAllConditions<S extends FormSource> (
  displayIf: DisplayIf<S>,
): Condition<S>[] {
  if (Array.isArray(displayIf)) {
    return displayIf.flatMap((c) => getAllConditions(c))
  } else if ('operator' in displayIf) {
    return displayIf.conditions.flatMap((c) => getAllConditions(c))
  } else {
    return [displayIf]
  }
}

/**
 * Takes a condition or list of conditions with possible array paths.
 * Will return a list of {@link InputDataResolved}, each using one of those specified paths.
 */
export function castConditionToInputDataArray<S extends FormSource> (
  condition: Condition<S> | InputData<S>,
  resolver: InputDataResolver | null | undefined,
): InputDataResolved<S>[] {
  const castResolver = resolver ?? blankInputDataResolver
  return castArray(condition.path ?? '')
    .map((path) => {
      const data = { ...condition, path } as InputData<S>
      return castResolver(data)
    })
}

/**
 * Checks the provided condition and returns a boolean.
 *
 * If the `path` of the condition is passed as an array it acts as OR logic (if any are true, return true).
 */
function checkCondition (
  condition: Condition,
  localValues: PayloadLike | null | undefined,
  resolver: InputDataResolver | null | undefined,
): boolean {
  const { restrictUndefined, permitUndefined, comparator } = condition
  let { permittedValues, restrictedValues } = condition as { permittedValues: DisplayIfValueList, restrictedValues: DisplayIfValueList }
  const dataArray = castConditionToInputDataArray(condition, resolver)
  // add undefined to value arrays as an extra possible condition
  if (permitUndefined) permittedValues = Array.isArray(permittedValues) ? permittedValues.concat(undefined) : [undefined]
  if (restrictUndefined) restrictedValues = Array.isArray(restrictedValues) ? restrictedValues.concat(undefined) : [undefined]
  const comparatorFn = buildComparatorFn(comparator, localValues, resolver)
  return dataArray.some((data) => {
    const response = getInputData<PrimitiveValue>(data, { payload: localValues })
    // if the permittedValues, restrictedValues & comparatorFn properties aren't set, we are only interested in whether the value exists
    if (!permittedValues && !restrictedValues && !comparatorFn) return response !== undefined
    // if restrictedValues is set, check that the value is not in that array
    if (restrictedValues && restrictedValues.includes(response)) return false
    // if permittedValues is set, check that the value is in that array
    if (permittedValues && !permittedValues.includes(response)) return false
    // if comparator is set, check against the generated function
    if (comparatorFn && !comparatorFn(response, data)) return false
    return true
  })
}

const INVALID_VALUE = Symbol('invalidValue')
type TestValue = PrimitiveValue | PrimitiveValue[] | undefined
function getTestValue (
  comparator: DisplayIfComparator<FormSourceDisplayIf>,
  localValues: PayloadLike | null | undefined,
  resolver: InputDataResolver | null | undefined,
): TestValue | typeof INVALID_VALUE {
  if (!('value' in comparator)) return undefined
  if ('calculated' in comparator && comparator.calculated) {
    switch (comparator.value) {
      case 'thirtyYearsAgo':
        return new Date().getFullYear() - 30
      default:
        console.warn('Invalid calculated value specified', comparator.value)
        return INVALID_VALUE
    }
  }
  if (comparator.value && typeof comparator.value === 'object' && !Array.isArray(comparator.value)) {
    return getInputData(comparator.value, { payload: localValues, resolver }) as PrimitiveValue
  }
  return comparator.value
}

/**
 * Returns a comparator function that can be used against array values.
 */
function checkArrayValues (
  comparator: DisplayIfComparatorIncludes,
  localValues: PayloadLike | null | undefined,
  testValue: TestValue,
  valueCheckFn: (arrayValues: (PrimitiveValue | undefined)[], testValues: (PrimitiveValue | undefined)[]) => boolean,
): ComparatorFunction {
  return (v, data) => {
    if (!(v instanceof PayloadArray) && !Array.isArray(v)) return false
    const pathResolver = createInputDataPathResolver(data)
    const arrayValues = v.map((_, index) => {
      return getInputData<TestValue>(pathResolver(`${index}.${comparator.options.field}`), { payload: localValues })
    })
    const testValues = castArray(testValue)
    return valueCheckFn(arrayValues, testValues)
  }
}

/**
 * Builds a comparator function that can be checked against each response in a DisplayIf condition.
 */
export function buildComparatorFn (
  comparator: DisplayIfComparator<FormSourceDisplayIf> | undefined,
  localValues: PayloadLike | null | undefined,
  resolver: InputDataResolver | null | undefined,
): null | ComparatorFunction {
  if (!comparator || typeof comparator !== 'object') return null

  const testValue = getTestValue(comparator, localValues, resolver)
  if (testValue === INVALID_VALUE) return null

  let fn: ComparatorFunction = (): boolean => false
  switch (comparator.name) {
    case 'equal': {
      fn = (v) => preferNumberCompare(v, testValue, (v1, v2) => v1 === v2)
      break
    }
    case 'notEqual': {
      fn = (v) => preferNumberCompare(v, testValue, (v1, v2) => v1 !== v2)
      break
    }
    case 'lessThan': {
      fn = (v) => preferNumberCompare(v, testValue as string | number, (v1, v2) => v1 < v2)
      break
    }
    case 'lessThanOrEqual': {
      fn = (v) => preferNumberCompare(v, testValue as string | number, (v1, v2) => v1 <= v2)
      break
    }
    case 'greaterThan': {
      fn = (v) => preferNumberCompare(v, testValue as string | number, (v1, v2) => v1 > v2)
      break
    }
    case 'greaterThanOrEqual': {
      fn = (v) => preferNumberCompare(v, testValue as string | number, (v1, v2) => v1 >= v2)
      break
    }
    case 'greaterThanOrEqualOrZero': {
      fn = (v) => preferNumberCompare(v, testValue as string | number, (v1, v2) => v1 === 0 || v1 >= v2)
      break
    }
    case 'emptyArray': {
      fn = (v) => {
        const isArray = Array.isArray(v) || v instanceof PayloadArray
        return !isArray || v.length === 0
      }
      break
    }
    case 'nonEmptyArray': {
      fn = (v) => {
        const isArray = Array.isArray(v) || v instanceof PayloadArray
        return isArray && v.length !== 0
      }
      break
    }
    case 'includes': {
      fn = checkArrayValues(comparator, localValues, testValue, (arrayValues, testValues) => {
        return arrayValues.some((val) => testValues.includes(val))
      })
      break
    }
    case 'includesExcept': {
      fn = checkArrayValues(comparator, localValues, testValue, (arrayValues, testValues) => {
        if (arrayValues.length === 0) return true
        return arrayValues.some((val) => !testValues.includes(val))
      })
      break
    }
    case 'notIncludes': {
      fn = checkArrayValues(comparator, localValues, testValue, (arrayValues, testValues) => {
        return arrayValues.every((val) => !testValues.includes(val))
      })
      break
    }
    case 'onlyIncludes': {
      fn = checkArrayValues(comparator, localValues, testValue, (arrayValues, testValues) => {
        if (arrayValues.length === 0) return false
        return arrayValues.every((val) => testValues.includes(val))
      })
      break
    }
    case 'withinDayRange': {
      fn = (v) => {
        if (!isValidDateString(v)) return false
        const today = getDateToday()
        if (comparator.options.start !== undefined) {
          const start = offsetDate(today, { days: comparator.options.start })
          if (start && v < start) return false
        }
        if (comparator.options.end !== undefined) {
          const end = offsetDate(today, { days: comparator.options.end })
          if (end && v > end) return false
        }
        return true
      }
      break
    }
    default:
      // @ts-expect-error: `name` does not exist on never
      console.warn('Invalid comparator name specified', comparator.name)
      return null
  }

  return (v, data) => {
    if ('ignore' in comparator && Array.isArray(comparator.ignore) && comparator.ignore.includes(v)) return true
    return fn(v, data)
  }
}

/**
 * If passed an array of conditions will return the value of the first condition that matches or the default value.
 *
 * If passed a value will return that value unless it is nullish in which case the default will be used.
 *
 * @param matcher Either an array of match conditions or a value
 * @param defaultValue The default value to use if no conditions match or if no matcher is provided
 * @param payload The local payload to use within the displayIf check
 * @param resolver The resolver to use within the displayIf check
 */
export function matchCondition<T = PrimitiveValue> (
  matcher: T | MatchCondition<T>[] | undefined,
  defaultValue: T,
  payload: PayloadLike | null | undefined,
  resolver: InputDataResolver | null | undefined,
): T {
  if (Array.isArray(matcher)) {
    for (const { value, condition } of matcher) {
      if (checkDisplayIf(condition, payload, resolver)) return value
    }
    return defaultValue
  }
  return matcher ?? defaultValue
}
