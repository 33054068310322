import axios from 'axios'

import type { TSFixMe } from '@policyfly/types/common'

export default {
  calculate_endorsement: ({ id, date, hvc }: { id: number, date: string, hvc: string }) => {
    return axios.get<TSFixMe>(`/applications/${id}/calculate_endorsement/?date=${date}&hvc=${hvc}`)
  },
  calculate_cancellation: ({ id, date, reason }: { id: number, date: string, reason: string }) => {
    return axios.get<TSFixMe>(`/applications/${id}/calculate_cancellation/?date=${date}&reason=${reason}`)
  },
}
