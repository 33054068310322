import { CoverageType } from '@policyfly/protobuf'

import defaultAvatar from 'assets/policyfly_logo.png'

import type { FeedEventLog } from 'types/events'

export const CATEGORY_MAP: Record<string, string> = {
  REC: 'reclaim',
  REN: 'renewal',
}

/**
 * Pretty coverage names for displaying in feed event descriptions.
 */
export const COVERAGE_MAP: Partial<Record<CoverageType, string>> = {
  [CoverageType.APD_COVERAGE]: 'APD',
  [CoverageType.MTC_COVERAGE]: 'MTC',
  [CoverageType.FREIGHT_FORWARDING_COVERAGE]: 'Freight Forwarding',
}

/**
 * Friendly names for document types,
 * set as null to simply toLowerCase
 * the name
 */
export const DOCUMENT_TYPE_MAP: Record<string, string> = {
  /* eslint-disable @stylistic/key-spacing */
  // shared
  APPLICATION             : 'Application',
  QUOTE                   : 'Quote',
  AGENT_INVOICE           : 'Agent Invoice',
  INSURED_INVOICE         : 'Insured Invoice',
  DECLARATIONS_PAGE       : 'Declarations Page',
  ENDORSEMENT             : 'Endorsement',

  // sterling risk
  // smartu/nrsa
  BINDER                        : 'Binder',
  DEC_PAGE_BASE                 : 'Declaration Page',
  DEC_PAGE_FIREMANS             : 'Declaration Page (Firemans)',
  DEC_PAGE_OHIO_CASUALTY        : 'Declaration Page (Ohio Casualty)',
  DEC_PAGE_LIBERTY_INS          : 'Declaration Page (Liberty Ins)',
  // crafde/crafde-sonic
  DEC_PAGE_CINCINNATI_INS       : 'Declaration Page (Cincinnati Ins)',
  // srae
  ORDER_TO_BIND                 : 'Order to Bind',
  ENDORSEMENT_QUOTE_SUM         : 'Endorsement Quote Summary',
  QUOTE_SUM                     : 'Quote Summary',
  // coverall
  // homefront
  // truenorth
  QUOTE_SHEET                   : 'Quote Sheet',
  // bcapd
  CERTIFICATE                   : 'Certificate',
  CONFIRMATION_OF_COVER         : 'Confirmation Of Cover',
  // tyapd
  FORMAL_QUOTE                  : 'Formal Quote',
  CONFIMRATION_OF_COVER         : 'Confirmation of Cover',
  FEE_NOTICE                    : 'Fee Notice',
  CANCELLATION_ENDORSEMENT      : 'Cancellation Endorsement',
  ENDORSEMENT_CORRECTION_REPORT : 'Endorsement Correction Report',
  // brapd
  REINSTATEMENT                 : 'Reinstatement',
  /* eslint-enable @stylistic/key-spacing */
}

export const DEFAULT_NAME = 'Customer'
export const SYSTEM_NAME = 'PolicyFly'
export const DUPLICATE_EVENT = 'DUPLICATE_EVENT'
export const NO_HANDLER = 'No handler is implemented for feed event'

export const SYSTEM_USER: FeedEventLog['created_by'] = {
  id: 0,
  first_name: SYSTEM_NAME,
  last_name: '',
  avatar_url: defaultAvatar,
}

export const DEFAULT_USER: FeedEventLog['created_by'] = {
  id: 0,
  first_name: SYSTEM_NAME,
  last_name: '',
  avatar_url: defaultAvatar,
}
