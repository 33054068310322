import { request } from '../methods'

export default {
  list: request('get', '/api/v1/tasklist/', (params) => {
    if (params.query.search) {
      const term = params.query.search.trim().replace(/(^\*)|(\*$)/g, '')
      params.query.search = `*${term}*`
    }
  }),
  read: request('get', '/api/v1/tasklist/{pk}/'),
}
