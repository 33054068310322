export const unitTypes: { label: string, path: string }[] = [
  { path: 'used', label: 'Used Cars' },
  { path: 'recreational', label: 'Recreational Vehicles' },
  { path: 'mobile', label: 'Mobile Homes' },
  { path: 'new', label: 'New Cars' },
  { path: 'terrain', label: 'All Terrain Vehicles' },
  { path: 'truck', label: 'Trucks/Tractors' },
  { path: 'trailer', label: 'Trailers' },
  { path: 'motorcycles', label: 'Motorcycles' },
  { path: 'other', label: 'Other' },
]
