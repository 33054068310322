import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { PINIA_STORAGE_KEY } from '@/stores/plugins/persistedState'

import { hotReloadStore } from '@/utils/build'

import type { PersistedState } from '@/stores/plugins/persistedState'
import type { LastInTuple } from '@policyfly/types/utils'
import type { WritableComputedRef } from 'vue'

interface LocalStorageSchema<V extends number = number> {
  $version: V
}
type LocalStorageSchemaV0 = LocalStorageSchema<0>
interface LocalStorageSchemaV1 extends LocalStorageSchema<1> {
  refreshToken: string | null
  spaToken: string | null
  spaTokenExpiration: string | null
}

type MigrationFn<Prev extends LocalStorageSchema, Curr extends LocalStorageSchema> = (previousSchema: Prev) => Curr
function createMigration<Prev extends LocalStorageSchema, Curr extends LocalStorageSchema> (version: Curr['$version'], fn: MigrationFn<Prev, Curr>): (schema: Prev | Curr) => Curr {
  return (schema) => {
    return (schema.$version < version) ? fn(schema as Prev) : schema as Curr
  }
}
const migrations = [
  createMigration<LocalStorageSchemaV0, LocalStorageSchemaV1>(1, (_prev) => {
    const piniaState: Partial<PersistedState> = JSON.parse(localStorage.getItem(PINIA_STORAGE_KEY) || '{}')
    return {
      $version: 1,
      refreshToken: piniaState.api?.refreshToken ?? null,
      spaToken: piniaState.api?.spaToken ?? null,
      spaTokenExpiration: piniaState.api?.spaTokenExpiration ?? null,
    }
  }),
] as const

export type CurrentSchema = ReturnType<LastInTuple<typeof migrations>>
export interface LocalStorageStore {
  getItem<T extends keyof CurrentSchema> (key: T): CurrentSchema[T]
  setItem<T extends keyof CurrentSchema> (key: T, value: CurrentSchema[T]): void
  createItemRef<T extends keyof CurrentSchema> (key: T): WritableComputedRef<CurrentSchema[T]>
}

export const LOCAL_STORAGE_KEY = 'policyfly'

/**
 * Creates reactive, type safe and versioned access to local storage.
 */
export const useLocalStorageStore = defineStore('localStorage', (): LocalStorageStore => {
  const currentSchema = ref({} as CurrentSchema)

  function saveCurrentSchema (): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentSchema.value))
  }
  function reloadSchema (): void {
    const previousSchema: LocalStorageSchema = (() => {
      const defaultSchema: LocalStorageSchemaV0 = { $version: 0 }
      const latestVersion: CurrentSchema['$version'] = 1
      try {
        const item = localStorage.getItem(LOCAL_STORAGE_KEY)
        if (!item) return defaultSchema
        const parsedItem = JSON.parse(item)
        return ('$version' in parsedItem && parsedItem.$version <= latestVersion)
          ? parsedItem
          : defaultSchema
      } catch {
        return defaultSchema
      }
    })()

    try {
      currentSchema.value = migrations.reduce((acc, migration) => {
        return migration(acc as Parameters<typeof migration>[0])
      }, previousSchema) as CurrentSchema
    } catch (err) {
      console.warn(`Could not convert local storage schema, setting default value: ${(err as Error).message}`)
      currentSchema.value = {
        $version: 1,
        refreshToken: null,
        spaToken: null,
        spaTokenExpiration: null,
      }
    }
  }

  // import & migrate, then save result
  reloadSchema()
  saveCurrentSchema()

  // reacts to any manual changes in the devtools or other pages
  // we don't save here as that can cause an update race
  window.addEventListener('storage', (event) => {
    if (event.key !== LOCAL_STORAGE_KEY) return
    reloadSchema()
  })

  const getItem: LocalStorageStore['getItem'] = (key) => {
    return currentSchema.value[key]
  }
  const setItem: LocalStorageStore['setItem'] = (key, value) => {
    currentSchema.value[key] = value
    saveCurrentSchema()
  }
  const createItemRef: LocalStorageStore['createItemRef'] = (key) => {
    return computed({
      get: () => getItem(key),
      set: (val) => setItem(key, val),
    })
  }

  return {
    getItem,
    setItem,
    createItemRef,
  }
})

hotReloadStore(useLocalStorageStore)
