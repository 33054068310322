import { formatSentence } from '@policyfly/utils/string'
import Big from 'big.js'
import { removalReasonsMap } from 'snippets/gamp/common'

import Payload, { PayloadArray } from '@/lib/Payload'
import { formatDate, formatValue } from '@/utils/formatter'
import { vehicleTypeToVinLookupTypeMap } from '@/utils/protobuf/mappers'

import type { ChangeList, ChangeListItem, Vehicle, VehicleType } from '@policyfly/protobuf'
import type { Formatter } from '@policyfly/utils/types/formatter'

interface StrictChangeListItem<T extends string, U extends object> extends Omit<ChangeListItem, 'values' | 'previousValues'> {
  itemType: T
  values: Partial<Record<keyof U, string>>
  previousValues: Partial<Record<keyof U, string>>
}
export type ValidChangeListItem =
  | StrictChangeListItem<'VEHICLE', Vehicle>

type ChangeFields<T extends object> = {
  name: string
  path: keyof T
  formatter: Formatter
}

// Friendly display names for vehicle types
export const vehicleTypeToVinLookupTypeRecord: Record<VehicleType, string> = Object.fromEntries(
  new Map(Array.from(vehicleTypeToVinLookupTypeMap).map(([type, name]) => [type, formatSentence(name)])),
) as Record<VehicleType, string>

const vehicleChanges: ChangeFields<Vehicle>[] = [
  { name: 'VIN', path: 'vin', formatter: 'default' },
  { name: 'Amount', path: 'insuredValue', formatter: 'currency' },
  { name: 'Type', path: 'type', formatter: { name: 'mapValue', params: { valueMap: vehicleTypeToVinLookupTypeRecord } } },
  { name: 'Year', path: 'year', formatter: 'default' },
  { name: 'Make', path: 'make', formatter: 'default' },
  { name: 'Model', path: 'model', formatter: 'default' },
  { name: 'Add Date', path: 'added', formatter: 'date' },
  { name: 'Deductible', path: 'replacementValue', formatter: 'currency' },
  { name: 'Total Loss', path: 'actualCashValue', formatter: 'currency' },
  { name: 'Removal Date', path: 'removed', formatter: 'date' },
]

enum ScheduleDiffState {
  ADDED = 'Added',
  REMOVED = 'Removed',
  CHANGED = 'Changed'
}

/**
 * Generates a text summary for the passed in {@link ChangeListItem}.
 * The `itemType` affects what displays in the summary.
 */
export function generateItemSummary (item: ValidChangeListItem, type?: keyof Omit<ChangeList, 'title'>): string {
  const parts: string[] = []
  switch (item.itemType) {
    case 'VEHICLE': {
      const vehicle = item.values
      parts.push(vehicle.vin ?? '')
      // changed items only display changed fields
      if (type === 'changed') {
        // only include insured value for APD
        if (item.values.insuredValue) parts.push(`${formatValue(item.values.insuredValue, 'currency')}`)
        // check if fields marked for display had values previously and display them
        const changes: string[] = []
        vehicleChanges.forEach(({ path, name, formatter }) => {
          const value = item.values[path]
          const previous = item.previousValues[path]
          if (previous) changes.push(`${name}: ${formatValue(previous, formatter)} to ${formatValue(value, formatter)}`)
        })
        if (changes.length) parts.push(`(${changes.join(',  ')})`)
      } else {
        const { removed, added, insuredValue, removedReason, removedReasonDetail } = vehicle
        if (removed) parts.push(`Removed: ${formatDate(removed, { year: '2-digit' })}`)
        if (added) parts.push(`Added: ${formatDate(added, { year: '2-digit' })}`)
        if (Big(insuredValue || 0).gt(0)) parts.push(formatValue(insuredValue, 'currency'))
        if (removedReason && removedReason !== '0') {
          const formattedReason = formatValue(removedReason, { name: 'mapValue', params: { valueMap: removalReasonsMap } })
          parts.push(`Reason: ${removedReasonDetail || formattedReason}`)
        }
      }
      return parts.join('   ')
    }
    default:
      return ''
  }
}

/**
 * Converts the passed in {@link ChangeList} into a {@link PayloadArray}.
 * This is designed for use within `programData` to generate endorsement summaries.
 */
export function convertChangeList (changes: ChangeList | null | undefined): PayloadArray {
  const basePayload = new Payload()
  basePayload.createResponse('state', null)
  basePayload.createResponse('summary', '')
  const payloadArray = new PayloadArray(basePayload)
  if (!changes) return payloadArray
  changes.added.forEach((item) => {
    const newItem = payloadArray.add()
    newItem.set('state', ScheduleDiffState.ADDED)
    newItem.set('summary', generateItemSummary(item as ValidChangeListItem, 'added'))
  })
  changes.changed.forEach((change) => {
    const newItem = payloadArray.add()
    newItem.set('state', ScheduleDiffState.CHANGED)
    newItem.set('summary', generateItemSummary(change as ValidChangeListItem, 'changed'))
  })
  if (changes.removed) {
    changes.removed.forEach((item) => {
      const newItem = payloadArray.add()
      newItem.set('state', ScheduleDiffState.REMOVED)
      newItem.set('summary', generateItemSummary(item as ValidChangeListItem, 'removed'))
    })
  }
  return payloadArray
}
