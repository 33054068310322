import {
  PatrickServiceClient,
  ListAgenciesRequest,
  ListAgenciesResponse,
  CreateAgencyRequest,
  CreateAgencyResponse,
  ArchiveAgencyRequest,
  ArchiveAgencyResponse,
  RenameAgencyRequest,
  RenameAgencyResponse,
  ListAgencyMembersRequest,
  ListAgencyMembersResponse,
  CreateAgencyMemberRequest,
  CreateAgencyMemberResponse,
  UpdateAgencyMemberRequest,
  UpdateAgencyMemberResponse,
  DeleteAgencyMemberRequest,
  DeleteAgencyMemberResponse,
  UpdateAgencyMemberDenylistRequest,
  UpdateAgencyMemberDenylistResponse,
  UpdateAgencyProgramsRequest,
  UpdateAgencyProgramsResponse,
} from '@policyfly/protobuf/patrick'

import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface AgencyApiEndpoints {
  /**
   * @see {@link PatrickServiceClient.listAgencies listAgencies}
   */
  listAgencies: (request: ListAgenciesRequest) => Promise<ListAgenciesResponse>
  /**
   * @see {@link PatrickServiceClient.createAgency createAgency}
   */
  createAgency: (request: CreateAgencyRequest) => Promise<CreateAgencyResponse>
  /**
   * @see {@link PatrickServiceClient.archiveAgency archiveAgency}
   */
  archiveAgency: (request: ArchiveAgencyRequest) => Promise<ArchiveAgencyResponse>
  /**
   * @see {@link PatrickServiceClient.renameAgency renameAgency}
   */
  renameAgency: (request: RenameAgencyRequest) => Promise<RenameAgencyResponse>
  /**
   * @see {@link PatrickServiceClient.listAgencyMembers listAgencyMembers}
   */
  listAgencyMembers: (request: ListAgencyMembersRequest) => Promise<ListAgencyMembersResponse>
  /**
   * @see {@link PatrickServiceClient.createAgencyMember createAgencyMember}
   */
  createAgencyMember: (request: CreateAgencyMemberRequest) => Promise<CreateAgencyMemberResponse>
  /**
   * @see {@link PatrickServiceClient.updateAgencyMember updateAgencyMember}
   */
  updateAgencyMember: (request: UpdateAgencyMemberRequest) => Promise<UpdateAgencyMemberResponse>
  /**
   * @see {@link PatrickServiceClient.deleteAgencyMember deleteAgencyMember}
   */
  deleteAgencyMember: (request: DeleteAgencyMemberRequest) => Promise<DeleteAgencyMemberResponse>
  /**
   * @see {@link PatrickServiceClient.updateAgencyMemberDenylist updateAgencyMemberDenylist}
   */
  updateAgencyMemberDenylist: (request: UpdateAgencyMemberDenylistRequest) => Promise<UpdateAgencyMemberDenylistResponse>
  /**
   * @see {@link PatrickServiceClient.updateAgencyPrograms updateAgencyPrograms}
   */
  updateAgencyPrograms: (request: UpdateAgencyProgramsRequest) => Promise<UpdateAgencyProgramsResponse>
}

/**
 * Creates endpoints related to Agencies.
 */
export const createAgencyEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<AgencyApiEndpoints> => {
  const patrickServiceClient = new PatrickServiceClient(params.transport)

  const listAgencies: AgencyApiEndpoints['listAgencies'] = async (request) => {
    const { response } = await patrickServiceClient.listAgencies(request)

    devtools.logGrpc({
      description: 'List Agencies',
      messages: [
        { type: ListAgenciesRequest, key: 'request', message: request },
        { type: ListAgenciesResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const createAgency: AgencyApiEndpoints['createAgency'] = async (request) => {
    const { response } = await patrickServiceClient.createAgency(request)

    devtools.logGrpc({
      description: 'Create Agency',
      messages: [
        { type: CreateAgencyRequest, key: 'request', message: request },
        { type: CreateAgencyResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const archiveAgency: AgencyApiEndpoints['archiveAgency'] = async (request) => {
    const { response } = await patrickServiceClient.archiveAgency(request)

    devtools.logGrpc({
      description: 'Archive Agency',
      messages: [
        { type: ArchiveAgencyRequest, key: 'request', message: request },
        { type: ArchiveAgencyResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const renameAgency: AgencyApiEndpoints['renameAgency'] = async (request) => {
    const { response } = await patrickServiceClient.renameAgency(request)

    devtools.logGrpc({
      description: 'Rename Agency',
      messages: [
        { type: RenameAgencyRequest, key: 'request', message: request },
        { type: RenameAgencyResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const listAgencyMembers: AgencyApiEndpoints['listAgencyMembers'] = async (request) => {
    const { response } = await patrickServiceClient.listAgencyMembers(request)

    devtools.logGrpc({
      description: 'List Agency Members',
      messages: [
        { type: ListAgencyMembersRequest, key: 'request', message: request },
        { type: ListAgencyMembersResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const createAgencyMember: AgencyApiEndpoints['createAgencyMember'] = async (request) => {
    const { response } = await patrickServiceClient.createAgencyMember(request)

    devtools.logGrpc({
      description: 'Create Agency Member',
      messages: [
        { type: CreateAgencyMemberRequest, key: 'request', message: request },
        { type: CreateAgencyMemberResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const updateAgencyMember: AgencyApiEndpoints['updateAgencyMember'] = async (request) => {
    const { response } = await patrickServiceClient.updateAgencyMember(request)

    devtools.logGrpc({
      description: 'Update Agency Member',
      messages: [
        { type: UpdateAgencyMemberRequest, key: 'request', message: request },
        { type: UpdateAgencyMemberResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const deleteAgencyMember: AgencyApiEndpoints['deleteAgencyMember'] = async (request) => {
    const { response } = await patrickServiceClient.deleteAgencyMember(request)

    devtools.logGrpc({
      description: 'Delete Agency Member',
      messages: [
        { type: DeleteAgencyMemberRequest, key: 'request', message: request },
        { type: DeleteAgencyMemberResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const updateAgencyMemberDenylist: AgencyApiEndpoints['updateAgencyMemberDenylist'] = async (request) => {
    const { response } = await patrickServiceClient.updateAgencyMemberDenylist(request)

    devtools.logGrpc({
      description: 'Update Agency Member Denylist',
      messages: [
        { type: UpdateAgencyMemberDenylistRequest, key: 'request', message: request },
        { type: UpdateAgencyMemberDenylistResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const updateAgencyPrograms: AgencyApiEndpoints['updateAgencyPrograms'] = async (request) => {
    const { response } = await patrickServiceClient.updateAgencyPrograms(request)

    devtools.logGrpc({
      description: 'Update Agency Programs',
      messages: [
        { type: UpdateAgencyProgramsRequest, key: 'request', message: request },
        { type: UpdateAgencyProgramsResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const endpoints: AgencyApiEndpoints = {
    listAgencies,
    createAgency,
    archiveAgency,
    renameAgency,
    listAgencyMembers,
    createAgencyMember,
    updateAgencyMember,
    deleteAgencyMember,
    updateAgencyMemberDenylist,
    updateAgencyPrograms,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
