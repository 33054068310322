import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'
import { usePolicyStore } from '@/stores/policy'

import Payload from '@/lib/Payload'
import { extractVehicleChanges } from '@/utils/apdHelpers'
import { createArrayComputedMap, createAddressComputedMap } from '@/utils/diffHelpers'
import { formatValue } from '@/utils/formatter'
import { loadParentApplicationChain } from '@/utils/policy'
import { getNestedResponse, getQuoteResponse } from '@/utils/responses'

import type { PayloadObject, PayloadArray, PayloadResponse } from '@/lib/Payload'
import type { VehicleAppDiff } from '@/stores/diff'
import type { CreateMapFn } from '@/stores/programData'
import type { LoadableApp } from '@/utils/policy'

interface Wording {
  name: {
    v: string
  }
}

export const createMap: CreateMapFn = () => {
  return {
    ...createAddressComputedMap('surplusLinesAddress', { path: 'surplusLines', source: 'issue' }),
    ...createAddressComputedMap('surplusLinesAddressRecent', { path: 'surplusLines', source: 'recent' }),
    ...createArrayComputedMap('commodityType', 'operations.comType', null, {
      alcoholTobacco: 'Alcohol, Wine and/or Tobacco (Not Beer)',
      automobiles: 'Automobiles and/or Motorcycles',
      bulkAgricultural: 'Bulk Agricultural (Including Grain)',
      coal: 'Coal',
      containerized: 'Containerized and/or Dry Van Freight (Including Beer)',
      cisFlatbed: 'Cotton, Iron, Steel and/or Flatbed Commodities',
      electronics: 'Electronics and/or Department Store Goods',
      garbage: 'Garbage',
      textiles: 'Garments and/or Textiles',
      machinery: 'Heavy Machinery, Printing Presses, Switchgear and/or Oversized Loads',
      householdGoods: 'Household Goods',
      liquids: 'Liquids (Including Milk) and/or Petroleum',
      livestock: 'Livestock',
      log: 'Log',
      mail: 'Mail',
      nonFerrousMetal: 'Non-Ferrous Metal in Scrap and/or Ingot Form',
      cosmetics: 'Perfume and/or Cosmetics',
      plants: 'Plants (Refrigerated)',
      concrete: 'Ready Mix Concrete',
      refrigerated: 'Refrigerated Commodities',
      sandGravel: 'Sand and/or Gravel',
      ferrousScrap: 'Scrap (Not Non-Ferrous)',
      seafood: 'Seafood',
      swingingMeat: 'Swinging Meat',
      tiresAutoParts: 'Tires and/or Auto Parts',
      tow: 'Tow Trucks and/or On-Hook',
      other: 'Other',
    }),
    wordings: computed(() => {
      const wordings: Wording[] = []
      if (getQuoteResponse('conditions.WOAADC.response')) wordings.push({ name: { v: 'WhiteOak Automatic Additions and Deletions Clause' } })
      if (getQuoteResponse('conditions.WOAADCNV.response')) wordings.push({ name: { v: 'WhiteOak Automatic Additions and Deletions Clause New Venture' } })
      if (getQuoteResponse('conditions.WOAADCM.response')) wordings.push({ name: { v: 'WhiteOak Automatic Additions and Deletions Clause Monthly Reporting of Values' } })
      if (getQuoteResponse('conditions.WODC.response')) wordings.push({ name: { v: 'WhiteOak Driver Criteria' } })
      if (getQuoteResponse('conditions.WONVDC.response')) wordings.push({ name: { v: 'WhiteOak New Venture Driver Criteria' } })
      if (getQuoteResponse('conditions.WOADC.response')) wordings.push({ name: { v: 'WhiteOak AMWINS Driver Criteria' } })
      if (getQuoteResponse('conditions.WOANVDC.response')) wordings.push({ name: { v: 'WhiteOak AMWINS New Venture Driver Criteria' } })
      if (getQuoteResponse('conditions.WONUDAC.response')) wordings.push({ name: { v: 'WhiteOak Non-US Driver Acceptance Clause' } })
      if (getQuoteResponse('conditions.WORME.response')) wordings.push({ name: { v: 'WhiteOak Risk Mitigation Endorsement' } })
      if (getQuoteResponse('conditions.WordingSA.response')) wordings.push({ name: { v: 'Special Acceptance' } })
      if (getQuoteResponse('conditions.WOASH.response')) wordings.push({ name: { v: 'WhiteOak WinsRisk Hot Ash / Toxic Waste Exclusion' } })
      if (getQuoteResponse('conditions.WOGARBAGE.response')) wordings.push({ name: { v: 'WhiteOak WinsRisk Garbage / Refuse Endorsement' } })
      if (getQuoteResponse('conditions.FSRCCEC.response')) wordings.push({ name: { v: 'Faraday Strikes, Riots or Civil Commotion Exclusion Clause' } })
      if (getQuoteResponse('conditions.FCDEC.response')) wordings.push({ name: { v: 'Faraday Communicable Disease Exclusion Clause' } })
      if (getQuoteResponse('conditions.GAADC.response')) wordings.push({ name: { v: 'Attento Automatic Additions and Deletions Clause' } })
      if (getQuoteResponse('conditions.GAADCNV.response')) wordings.push({ name: { v: 'Attento Automatic Additions and Deletions Clause New Venture' } })
      if (getQuoteResponse('conditions.GAADCM.response')) wordings.push({ name: { v: 'Attento Automatic Additions and Deletions Clause Monthly Reporting of Values' } })
      if (getQuoteResponse('conditions.GDC.response')) wordings.push({ name: { v: 'Attento Driver Criteria' } })
      if (getQuoteResponse('conditions.GNVDC.response')) wordings.push({ name: { v: 'Attento New Venture Driver Criteria' } })
      if (getQuoteResponse('conditions.GADC.response')) wordings.push({ name: { v: 'Attento AMWINS Driver Criteria' } })
      if (getQuoteResponse('conditions.GANVDC.response')) wordings.push({ name: { v: 'Attento AMWINS New Venture Driver Criteria' } })
      if (getQuoteResponse('conditions.GNUDAC.response')) wordings.push({ name: { v: 'Attento Non-US Driver Acceptance Clause' } })
      if (getQuoteResponse('conditions.GRME.response')) wordings.push({ name: { v: 'Attento Risk Mitigation Endorsement' } })
      if (getQuoteResponse('conditions.HATWE.response')) wordings.push({ name: { v: 'LSW536-Hot Ash/Toxic Waste Exclusion' } })
      return wordings
    }),
    limitsRequested: computed<string[]>(() => {
      const limits = getNestedResponse('coverages.towingExtension.limits', true) as PayloadObject[] | null
      if (!limits) return []
      return limits.map((resp) => formatValue((resp.limit as PayloadResponse).get(), 'currency'))
    }),
    activeVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.vehicles)
    }),
    inactiveVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.deletedVehicles)
    }),
    // TODO: Replace with `recent` source
    facility: computed<string | null>(() => {
      const policyStore = usePolicyStore()
      const appContextStore = useAppContextStore()
      const applications = policyStore.applications as LoadableApp[]
      const apps = loadParentApplicationChain(applications, appContextStore.loadedApplicationData.parentApplicationID)
      for (const responses of apps) {
        const payload = Payload.fromResponses(responses)
        if (payload.get('facility')) return payload.get('facility')
      }
      return null
    }),
  }
}
