import { matchCondition } from '@/utils/displayIf'

import type { AnyObject, TSFixMe } from '@policyfly/types/common'
import type { MessageContext } from 'vue-i18n'

// Convert Protobuf object into Vue-i18n compatible format
export const processTranslations = (translations: AnyObject, path = ''): AnyObject => {
  return Object.entries(translations).reduce((acc: AnyObject, [key, value]) => {
    const currentPath = path ? `${path}.${key}` : key

    // Transform MatchConditions into a field that holds the condition, and a function to evaluate it
    // This is necessary for vue-i18n formatting/interpolation to work
    if (Array.isArray(value)) {
      acc[key] = (ctx: MessageContext & { message: (path: string) => (ctx: MessageContext) => string }) => {
        try {
          const text = ctx.message(`${currentPath}-condition`)
          const condition = JSON.parse(text(ctx))
          return matchCondition(condition, '', null, null)
        } catch (e) {
          return currentPath
        }
      }
      acc[`${key}-condition`] = stringifyTranslation(value)
    // Convert enums to strings, numbers will evaluate to empty translations
    } else if (typeof value === 'number') {
      acc[key] = stringifyTranslation(value)
    // Recurse into nested objects
    } else if (typeof value === 'object' && value !== null) {
      acc[key] = processTranslations(value, currentPath)
    } else {
      acc[key] = value
    }
    return acc
  }, {})
}

// Escape characters that are reseverd for 'vue-i18n' translations
// {}, @, $, | will be escaped using {''} syntax
//
// Does not escape characters within strings, so interpolation, pluralization and formatting still work
export const stringifyTranslation = (translations: number | TSFixMe[]): string => {
  return JSON.stringify(translations).replace(/([{}@$|])(?![^"]*"[^"]*(?:"[^"]*"[^"]*)*$)/g, (match) => `{'${match}'}`)
}
