/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { signatureGuard } from '@/router/middleware'
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const SignatureExpiredPage = () => import('@/pages/Signature/SignatureExpiredPage.vue')
const SignatureDetailsPage = () => import('@/pages/Signature/SignatureDetailsPage.vue')
const SignatureSignPage = () => import('@/pages/Signature/SignatureSignPage.vue')
const SignatureCompletePage = () => import('@/pages/Signature/SignatureCompletePage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/signature',
    redirect: { name: routeNames.SIGNATURE_DETAILS },
  },
  {
    path: '/signature/details',
    component: SignatureDetailsPage,
    name: routeNames.SIGNATURE_DETAILS,
    beforeEnter: signatureGuard,
    meta: { hideNavbar: true },
  },
  {
    path: '/signature/sign',
    component: SignatureSignPage,
    name: routeNames.SIGNATURE_SIGN,
    beforeEnter: signatureGuard,
    meta: { hideNavbar: true },
  },
  {
    path: '/signature/complete',
    component: SignatureCompletePage,
    name: routeNames.SIGNATURE_COMPLETE,
    beforeEnter: signatureGuard,
    meta: { hideNavbar: true },
  },
  {
    path: '/signature/expired',
    component: SignatureExpiredPage,
    name: routeNames.SIGNATURE_EXPIRED,
    meta: { hideNavbar: true },
  },
]

export default routeConfig
