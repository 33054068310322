import { nextTick } from 'vue'

import { formatDate } from './formatter'
import { localeDateFormat } from '@/utils/locale'

/**
 * Returns today's date in `yyyy-mm-dd` format.
 */
export function getDateToday (): string {
  const today = new Date()
  const [y, m, d] = [today.getFullYear(), today.getMonth() + 1, today.getDate()].map((v) => v.toString())
  return `${y}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`
}

/**
 * Returns the number of days between 2 dates.
 */
export function daysBetween (startDate: string, endDate: string, inclusive = false): number {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()
  return Math.round((end - start) / ONE_DAY) + (inclusive ? 1 : 0)
}

/**
 * Returns true if a valid date string.
 */
export function isValidDateString (date?: unknown): date is string {
  if (typeof date !== 'string' || !date) return false
  const currDate = new Date(`${date}T12:00:00Z`)
  return !(isNaN(currDate.getTime()))
}

/**
 * Offsets the provided date based on the provided offset params.
 * Negative offsets are supported.
 *
 * @returns If `date` is invalid then `null`, otherwise a string of the offset date formatted as `yyyy-mm-dd`
 */
export function offsetDate (date: string | null | undefined, offset: { years?: number, days?: number }): string | null {
  if (!date) return null
  // set to midday to avoid timezone offset problems
  const currDate = new Date(`${date}T12:00:00Z`)
  if (isNaN(currDate.getTime())) return null
  currDate.setUTCFullYear(currDate.getUTCFullYear() + (offset.years || 0))
  currDate.setUTCDate(currDate.getUTCDate() + (offset.days || 0))
  return currDate.toISOString().split('T')[0]
}

/**
 * Returns date format information if the provided date matches the expected format based on the current locale.
 * Also validates that the year/month/day are valid.
 *
 * Returns false if an invalid date is provided.
 *
 * @example
 * // in en-US format
 * '01/30/2021' -> { year: 2021, month: 1, day: 30, iso: '2021-01-30' }
 */
export function getDateFormat (date?: string | null): false | { year: number, month: number, day: number, iso: string } {
  if (!date) return false
  const expectedFormat = localeDateFormat()
  let y = -1
  let m = -1
  let d = -1
  if (date.length !== expectedFormat.length) return false
  for (let i = 0; i < expectedFormat.length;) {
    const expectedChar = expectedFormat.charAt(i)
    switch (expectedChar) {
      case 'M':
      case 'D': {
        const next2 = date.slice(i, i + 2)
        if (!/\d\d/.test(next2)) return false
        if (expectedChar === 'M') m = +next2
        else if (expectedChar === 'D') d = +next2
        i += 2
        break
      }
      case 'Y': {
        const next4 = date.slice(i, i + 4)
        if (!/\d\d\d\d/.test(next4)) return false
        y = +next4
        i += 4
        break
      }
      default:
        i++
    }
  }
  if (y === -1 || m === -1 || d === -1) return false
  const testDate = new Date()
  testDate.setUTCFullYear(y, m - 1, d)
  if (testDate.getUTCFullYear() !== y) return false
  if (testDate.getUTCMonth() + 1 !== m) return false
  /* istanbul ignore if: cannot find a logical way this would be false if the above 2 pass -- @preserve */
  if (testDate.getUTCDate() !== d) return false
  return {
    year: y,
    month: m,
    day: d,
    iso: `${y}-${String(m).padStart(2, '0')}-${String(d).padStart(2, '0')}`,
  }
}

/**
 * Returns the number of full years elapsed since a date (a person's age).
 */
export function ageFromToday (date: string): number {
  const [y0, m0, d0] = date.split('-')
  const birthDate = new Date(Date.UTC(+y0, +m0, +d0))
  const [y1, m1, d1] = getDateToday().split('-')
  const basisDate = new Date(Date.UTC(+y1, +m1, +d1))
  let age = basisDate.getFullYear() - birthDate.getFullYear()
  const month = basisDate.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && basisDate.getDate() < birthDate.getDate())) age--
  return age
}

/**
 * Returns the time elapsed since the provided date in human readable format.
 *
 * @example
 * timeSince('date-of-2-seconds-ago') // 2 seconds
 */
export function timeSince (date: Date | string | number): string {
  if (!(date instanceof Date)) date = new Date(date)
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000)
  let interval = Math.floor(seconds / 31536000)

  if (interval >= 1) {
    return `${interval} year${interval > 1 ? 's' : ''}`
  }
  interval = Math.floor(seconds / 2592000)
  if (interval >= 1) {
    return `${interval} month${interval > 1 ? 's' : ''}`
  }
  interval = Math.floor(seconds / 86400)
  if (interval >= 1) {
    return `${interval} day${interval > 1 ? 's' : ''}`
  }
  interval = Math.floor(seconds / 3600)
  if (interval >= 1) {
    return `${interval} hour${interval > 1 ? 's' : ''}`
  }
  interval = Math.floor(seconds / 60)
  if (interval >= 1) {
    return `${interval} minute${interval > 1 ? 's' : ''}`
  }
  return `${Math.floor(seconds)} second${seconds > 1 ? 's' : ''}`
}

/**
 * Determines if a date can be inferred from a string, and if so returns the formatted date.
 * Basically checks that month and day have at least one digit, year must be 2-4 digits.
 * Returns false if an unparsable date is provided.
 *
 * @example
 * validateShortDate('2/2/23') // "02/02/2023"
 * validateShortDate('2/2') // false
 * validateShortDate('2/99/23') // false
 */
export function inferDate (date: string): false | string {
  // Extract date and ignore timezone it isn't offset
  try {
    if (date.trim() === '') return false
    // Check the date against appropriate regex based on locale - defaults to MM/DD/YYYY
    const localeFormat = localeDateFormat()
    let formatted = ''

    switch (localeFormat) {
      case 'DD/MM/YYYY':
        // already in en-GB format
        if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/.test(date)) {
          return date
        } else if (/^([0-2]?[1-9]|3[01])\/(0?[1-9]|1[012])\/(\d{2,4})$/.test(date)) {
          // convert to en-GB format
          const parts = date.split('/')
          const parsed = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`)
          parsed.setTime(parsed.getTime() - parsed.getTimezoneOffset() * 60 * 1000)
          formatted = formatDate(parsed.toISOString()) ?? false
        }
        break
      case 'MM/DD/YYYY':
      default:
        // already in en-US format
        if (/^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(date)) {
          return date
        } else if (/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2,4})$/.test(date)) {
          // convert en-US format
          const parsed = new Date(date)
          parsed.setTime(parsed.getTime() - parsed.getTimezoneOffset() * 60 * 1000)
          formatted = formatDate(parsed.toISOString()) ?? false
        }
    }
    return formatted || false
  } catch (err) {
    console.error(err)
  }
  return false
}

/**
 * Event handler for date input keydown events from text and textarea inputs.
 * Automatically inserts "/" between date segments and pads segments with leading zeros.
 * Also prevents invalid dates from being entered.
 *
 * @returns Returns false if event should be ignore, otherwise returns the formatted date string
 */
export function handleDateKeydown (event: KeyboardEvent): false | string {
  const { key } = event

  if (!/^[\d/]|Backspace$/.test(key)) {
    event.preventDefault()
    return false
  }

  const target = event.target as HTMLInputElement
  const { value } = target

  let selectionStart = target.selectionStart!
  const selectionEnd = target.selectionEnd!

  // construct input value as it will be after the key press
  let updatedValue = value.slice(0, selectionStart) + key + value.slice(selectionEnd)
  let line = (updatedValue.substring(0, selectionStart).match(/\n/g) || []).length
  let lines = updatedValue.split('\n')

  if (key === 'Backspace') {
    event.preventDefault()
    const selectionDelta = selectionEnd - selectionStart
    const deletedValue = value.slice(
      selectionDelta ? selectionStart : selectionStart - 1,
      selectionDelta ? selectionEnd : selectionStart,
    )

    // when forward slash is deleted remove its leading digit instead
    if (deletedValue === '/') {
      updatedValue = value.slice(0, selectionStart - 2) + value.slice(selectionStart - 1)
      selectionStart -= 2
    } else if (selectionDelta < 2 && deletedValue !== '') {
      updatedValue = value.slice(0, selectionStart - 1) + value.slice(selectionStart)
      selectionStart -= 1
    } else {
      updatedValue = value.slice(0, selectionStart) + value.slice(selectionEnd)
    }
    lines = updatedValue.split('\n')
    line = lines.length - 1 < line ? lines.length - 1 : line
    updatedValue = lines[line]
  } else {
    updatedValue = lines[line]
    if (key === '/') {
      const segments = updatedValue.split('/').filter((s) => !!s)
      selectionStart += 1

      // forward slash manually inserted,
      // see if current segment needs to be padded
      if (segments.length > 2 || !segments.length) {
        event.preventDefault()
        return false
      }
      if (segments.length && segments[0].length === 1) {
        segments[0] = `0${segments[0]}`
        selectionStart += 1
      }
      if (segments.length === 2 && segments[1].length === 1) {
        segments[1] = `0${segments[1]}`
        selectionStart += 1
      }

      updatedValue = segments.join('/')
    } else {
      const lineSelectionStart = selectionStart - value.substring(0, selectionStart).lastIndexOf('\n')
      // when digit is entered, bump cursor position if current segment is full
      selectionStart += lineSelectionStart === 2 || lineSelectionStart === 5 ? 2 : 1
    }

    event.preventDefault()
    if (updatedValue.length > 10) return false
  }

  // wait for model to update, then adjust cursor position
  nextTick(() => nextTick(() => target.setSelectionRange(selectionStart, selectionStart)))

  // create formatted date string
  updatedValue = updatedValue.replace(/\//g, '')
  const segments = [updatedValue.substring(0, 2), updatedValue.substring(2, 4), updatedValue.substring(4)]
    .filter((segment, index, array) => segment.length || (index !== 0 && array[index - 1].length === 2))
  lines[line] = segments.join('/')
  return lines.join('\n')
}
