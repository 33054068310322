/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const HomeLayout = () => import('@/layouts/HomeLayout.vue')
const HomePage = () => import('@/pages/Home/HomePage.vue')
const TasksPage = () => import('@/pages/Home/TasksPage.vue')

const routeConfig: StrictRouteConfig[] = [
  {
    path: '/home',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: HomePage,
        name: routeNames.HOME,
        meta: { isTabsList: true },
      },
      {
        path: 'tasks',
        component: TasksPage,
        name: routeNames.TASKS,
        meta: { isTabsList: true },
      },
    ],
  },
]

export default routeConfig
