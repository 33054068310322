import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useApplicationStore } from '@/stores/application'
import { useDiffStore } from '@/stores/diff'
import { usePolicyStore } from '@/stores/policy'
import { useSettingsStore } from '@/stores/settings'

import {
  createAddressComputedMap,
  createLocationArrayComputedMap,
  extractObjectDiffs,
  extractArrayDiffs,
  combineRemovedArrayItems,
} from '@/utils/diffHelpers'
import { formatDate } from '@/utils/formatter'
import { getInputData } from '@/utils/inputData'
import { getNestedResponse } from '@/utils/responses'

import type { NestedResponseObject } from '@/lib/Payload'
import type { CreateMapFn } from '@/stores/programData'
import type { APIPayloadResponse } from '@policyfly/utils/types'

function contactPersonFormatter (contactPerson?: { contactPerson?: APIPayloadResponse<string>, title?: APIPayloadResponse<string>, phone?: APIPayloadResponse<string> } | null): (string | null)[] {
  if (!contactPerson) return []
  const { contactPerson: person = { v: null }, title = { v: null }, phone = { v: null } } = contactPerson
  return [
    person.v,
    title.v,
    phone.v,
  ]
}
function additionalNamedInsuredFormatter (additionalNamedInsured: { name?: { v: string }, v: string }[]): string[] {
  if (!additionalNamedInsured) return []
  return additionalNamedInsured.map((n) => n.name ? n.name.v : n.v)
}

export const createMap: CreateMapFn = () => {
  const contactPersonRaw = computed(() => {
    const appContextStore = useAppContextStore()
    const nestedResponseObjects = appContextStore.nestedResponseObjects
    if (!nestedResponseObjects) return null
    return {
      contactPerson: nestedResponseObjects.contactPerson || {},
      title: nestedResponseObjects.title || {},
      phone: nestedResponseObjects.phone || {},
    }
  })

  const additionalNamedInsuredRaw = computed(() => {
    const appContextStore = useAppContextStore()
    const diffStore = useDiffStore()
    const nestedResponseObjects = appContextStore.nestedResponseObjects
    if (!nestedResponseObjects) return null
    const allANIs = combineRemovedArrayItems(
      nestedResponseObjects.additionalNamedInsured as NestedResponseObject[],
      'additionalNamedInsured',
      diffStore.nestedRemovedDiffs,
      // @ts-expect-error: Name not known to be PayloadResponse
      ({ name = {}, ...otherKeys }) => ({ name: { k: name.k, uuid4: name.uuid4, v: null }, ...otherKeys }),
    )
    const { coverages } = useSettingsStore()
    if (!coverages?.length || !allANIs) return allANIs
    return allANIs.filter((ani) => {
      return coverages.some((c) => {
        // @ts-expect-error: May not exist
        const hasCoverage: boolean = ani[c.label]?.v
        return hasCoverage && getInputData<boolean>(c.data!)
      })
    })
  })

  return {
    ...createAddressComputedMap('mainAddress', { path: '', source: 'responses' }),
    ...createLocationArrayComputedMap('locations', 'locations.data'),
    contactPersonRaw,
    contactPerson: computed(() => {
      // @ts-expect-error: Shape is different
      return contactPersonFormatter(contactPersonRaw.value)
    }),
    contactPersonDiff: computed(() => {
      const diffStore = useDiffStore()
      return extractObjectDiffs(contactPersonRaw.value, diffStore.diffMap, contactPersonFormatter)
    }),
    additionalNamedInsuredRaw,
    additionalNamedInsured: computed(() => {
      // @ts-expect-error: Shape is different
      return additionalNamedInsuredFormatter(additionalNamedInsuredRaw.value)
    }),
    additionalNamedInsuredDiff: computed(() => {
      const diffStore = useDiffStore()
      return extractArrayDiffs(
        additionalNamedInsuredRaw.value,
        diffStore.diffMap,
        // @ts-expect-error: Can return null
        additionalNamedInsuredFormatter,
        'name',
      )
    }),
    wordings: computed(() => {
      const applicationStore = useApplicationStore()
      return applicationStore.wordings.map((w) => {
        // nest all meta keys in { v }
        const meta = Object.entries(w.meta).reduce<Record<string, { v: string | number | boolean }>>((acc, [k, v]) => {
          acc[k] = { v }
          return acc
        }, {})
        return {
          text: { v: w.formatted || w.text },
          meta,
        }
      }).sort((a, b) => {
        const aSectionOrder = a.meta.sectionOrder?.v
        const bSectionOrder = b.meta.sectionOrder?.v
        if (aSectionOrder === bSectionOrder) {
          const aOrder = a.meta.order?.v
          const bOrder = b.meta.order?.v
          if (aOrder === bOrder) return 0
          return (aOrder < bOrder) ? -1 : 1
        }
        return (aSectionOrder < bSectionOrder) ? -1 : 1
      })
    }),
    wordingsDiffMap: computed(() => {
      const diffStore = useDiffStore()
      return diffStore.diffMap
    }),
    term: computed(() => {
      const appContextStore = useAppContextStore()
      if (!appContextStore.loadedApplicationData) return null
      const isInProgress = appContextStore.isInProgress
      const policyStore = usePolicyStore()
      const { effectiveDate, expirationDate } = appContextStore.loadedApplicationData
      const { effective: policyEffectiveDate, expiration: policyExpirationDate } = policyStore.currentCoveragePeriod
      const usedPolicyEffective = isInProgress ? null : policyEffectiveDate
      const usedPolicyExpiration = isInProgress ? null : policyExpirationDate
      const effective = usedPolicyEffective || effectiveDate || getNestedResponse('startDate') || getNestedResponse('effectiveDate') || null
      const expiration = usedPolicyExpiration || expirationDate || null
      const formatter = (date: string): string => formatDate(date)
      let term = ''
      if (effective) {
        term += `${formatter(effective)} - `
        if (expiration) term += formatter(expiration)
        else {
          let [y, m, d] = effective.split('T')[0].split('-')
          y++
          if (m === '02' && d === '29') d = '28'
          term += formatter(`${y}-${m}-${d}`)
        }
      }
      return { v: term }
    }),
  }
}
