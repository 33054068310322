import Payload, { PayloadArray } from '@/lib/Payload'
import { formatValue, formatDate } from '@/utils/formatter'

import type { Formatter } from '@policyfly/utils/types/formatter'
import type { DiffNamedDriver, DiffPlate, NamedDriversDiff, PlatesDiff } from 'types/programData/apd'

export enum ScheduleDiffState {
  ADDED = 'Added',
  REMOVED = 'Removed',
  CHANGED = 'Changed'
}

export function summariseDriver (driver: DiffNamedDriver): string {
  const parts: string[] = []
  parts.push(driver.name)
  parts.push(`License #: ${driver.licenseNumber}`)
  parts.push(formatValue(driver.state, 'state'))
  if (driver.birthDate) parts.push(`DOB: ${formatDate(driver.birthDate, { year: '2-digit' })}`)
  return parts.join('   ')
}

const driverChanges: { name: string, path: keyof DiffNamedDriver, formatter: Formatter }[] = [
  { name: 'Name', path: 'name', formatter: 'default' },
  { name: 'License Number', path: 'licenseNumber', formatter: 'default' },
  { name: 'State', path: 'state', formatter: 'state' },
  { name: 'Birth Date', path: 'birthDate', formatter: 'date' },
]

export function extractNamedDriverChanges (namedDrivers: NamedDriversDiff | null | undefined): PayloadArray {
  const basePayload = new Payload()
  basePayload.createResponse('state', null)
  basePayload.createResponse('summary', '')
  const payloadArray = new PayloadArray(basePayload)
  if (!namedDrivers) return payloadArray
  namedDrivers.added.forEach((vehicle) => {
    const newItem = payloadArray.add()
    newItem.set('state', ScheduleDiffState.ADDED)
    newItem.set('summary', summariseDriver(vehicle))
  })
  namedDrivers.changed.forEach((change) => {
    const newSummary = summariseDriver(change.new)
    const changesList: string[] = []
    driverChanges.forEach((c) => {
      const oldVal = formatValue(change.old[c.path], c.formatter) || 'N/A'
      const newVal = formatValue(change.new[c.path], c.formatter) || 'N/A'
      if (oldVal !== newVal) {
        changesList.push(`${c.name}: ${oldVal} to ${newVal}`)
      }
    })
    if (changesList.length > 0) {
      const newItem = payloadArray.add()
      newItem.set('state', ScheduleDiffState.CHANGED)
      newItem.set('summary', `${newSummary}  (${changesList.join(', ')})`)
    }
  })
  if (namedDrivers.removed) {
    namedDrivers.removed.forEach((vehicle) => {
      const newItem = payloadArray.add()
      newItem.set('state', ScheduleDiffState.REMOVED)
      newItem.set('summary', summariseDriver(vehicle))
    })
  }
  return payloadArray
}

export function summarisePlate (plate: DiffPlate): string {
  const parts: string[] = []
  parts.push(plate.plateID)
  parts.push(formatValue(plate.state, 'state'))
  return parts.join('   ')
}

const plateChanges: { name: string, path: keyof DiffPlate, formatter: Formatter }[] = [
  { name: 'Plate', path: 'plateID', formatter: 'default' },
  { name: 'State', path: 'state', formatter: 'state' },
]

export function extractPlateChanges (plates: PlatesDiff | null | undefined): PayloadArray {
  const basePayload = new Payload()
  basePayload.createResponse('state', null)
  basePayload.createResponse('summary', '')
  const payloadArray = new PayloadArray(basePayload)
  if (!plates) return payloadArray
  plates.added.forEach((plate) => {
    const newItem = payloadArray.add()
    newItem.set('state', ScheduleDiffState.ADDED)
    newItem.set('summary', summarisePlate(plate))
  })
  plates.changed.forEach((change) => {
    const newSummary = summarisePlate(change.new)
    const changesList: string[] = []
    plateChanges.forEach((c) => {
      const oldVal = formatValue(change.old[c.path], c.formatter) || 'N/A'
      const newVal = formatValue(change.new[c.path], c.formatter) || 'N/A'
      if (oldVal !== newVal) {
        changesList.push(`${c.name}: ${oldVal} to ${newVal}`)
      }
    })
    if (changesList.length > 0) {
      const newItem = payloadArray.add()
      newItem.set('state', ScheduleDiffState.CHANGED)
      newItem.set('summary', `${newSummary}  (${changesList.join(', ')})`)
    }
  })
  if (plates.removed) {
    plates.removed.forEach((vehicle) => {
      const newItem = payloadArray.add()
      newItem.set('state', ScheduleDiffState.REMOVED)
      newItem.set('summary', summarisePlate(vehicle))
    })
  }
  return payloadArray
}
