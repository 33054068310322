import { defineStore } from 'pinia'

import { hotReloadStore } from '@/utils/build'

import type { UserAgency, UserProgram } from '@policyfly/protobuf/patrick'
import type { RoleNameIncSuperuser } from '@policyfly/types/user'
import type { ProgramSlug } from 'types/program'

export interface AuthenticationState {
  userId: number | null
  programs: UserProgram[]
  programId: number
  agency: UserAgency | null
  bingoId: string | null
}

export const useAuthenticationStore = defineStore({
  id: 'authentication',

  state: (): AuthenticationState => ({
    userId: null,
    programs: [],
    programId: 0,
    agency: null,
    bingoId: null,
  }),

  getters: {
    currentProgram (state): UserProgram | null {
      return state.programs.find((p) => +p.id === +state.programId) ?? null
    },
    userRole (): RoleNameIncSuperuser | null {
      return this.currentProgram ? this.currentProgram.role as RoleNameIncSuperuser : null
    },
    isProgramAdmin (): boolean {
      return !!this.userRole && ['PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    isProgmin (): boolean {
      return !!this.userRole && ['TECHNICIAN', 'UNDERWRITER', 'PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    isInternalRole (): boolean {
      return !!this.userRole && ['TECHNICIAN', 'UNDERWRITER', 'QUALITY_CONTROLLER', 'PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    isUnderwriterOrProgmin (): boolean {
      return !!this.userRole && ['UNDERWRITER', 'PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    programAccess (): number[] {
      return this.programs.map((p) => p.id)
    },
    slug (): ProgramSlug | '' {
      return this.currentProgram ? this.currentProgram.slug as ProgramSlug : ''
    },
    /**
     * Whether the user is an agency admin.
     */
    isAdministrator (): boolean {
      return !!this.agency?.isAdministrator
    },
  },

  actions: {
    /**
     * Returns true if a default program was assigned, false if no programs exist
     */
    checkDefaultProgram (): boolean {
      if (!this.programs.length) return false
      // switch to the default or first program
      const defaultProgram = this.programs.find((m) => m.defaultProgram) || this.programs[0]
      this.programId = defaultProgram.id
      return true
    },
    /**
     * Returns true if the user has access to the given program id
     */
    hasProgramAccess (programId: number): boolean {
      return this.programAccess.includes(programId)
    },
  },
})

hotReloadStore(useAuthenticationStore)
