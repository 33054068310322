import { defineStore } from 'pinia'

import { useAuthenticationStore } from '@/stores/authentication'

import Payload from '@/lib/Payload'
import { handleChunkLoadError, hotReloadStore } from '@/utils/build'

import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { ProgramSlug } from 'types/program'
import type { BinderFormSource, BinderSection } from 'types/schema/binder'
import type { FormSchema } from 'types/schema/shared/formSchema'

export const useFormBinderStore = defineStore({
  id: 'formBinder',

  state: () => ({
    schema: null as FormSchema<BinderFormSource, FormCoverage, BinderSection> | null,
    sectionsSlug: '' as ProgramSlug | '',
    responses: [] as APIPayloadResponse[],
  }),

  // TS refuses to infer the `state` argument in getters as BinderSection is so complex
  // instead we use `this` and provide a return type
  getters: {
    binderPayload (): Payload | null {
      if (!this.responses || !this.responses.length) return null
      return Payload.fromResponses(this.responses)
    },
  },

  actions: {
    async loadSections (): Promise<BinderSection<FormCoverage>[] | undefined> {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (this.schema && this.sectionsSlug === slug) return Promise.resolve(this.schema.sections)
      this.schema = null
      this.sectionsSlug = ''
      try {
        const file = await import(`../../assets/programs/${slug}/binder.ts`)
        const { sections, contextFields } = file.default || file
        this.schema = {
          sections,
          contextFields,
        }
        this.sectionsSlug = slug
        return sections
      } catch (err) {
        handleChunkLoadError(err)
      }
    },
  },
})

hotReloadStore(useFormBinderStore)
