import { GenericStatus } from '@policyfly/protobuf'
import { dateToString } from '@policyfly/utils/protobuf'

import { attachmentToApplicationAttachment } from './attachment'
import { policyStateToApplication } from './policyState'
import { genericStatusToPolicyStatus } from './status'
import { getDateToday } from '@/utils/date'

import type { ReadonlyPolicy } from '@/stores/protobuf'
import type { Attachment, Policy as PolicyGrpc } from '@policyfly/protobuf'
import type { Application } from 'types/application'
import type { PolicyCoveragePeriod, Policy as PolicyJson } from 'types/policy'

/**
 * @todo Wire up remaining properties
 *
 * Converts a Policy to an equivalent Django Policy structure.
 * Used to preserve backwards compatibility for older programs.
 */
export function policyGrpcToJson (policy: PolicyGrpc | ReadonlyPolicy, attachments: Attachment[] = [], shallow = false): PolicyJson {
  const unknownUser: PolicyJson['last_modified_by'] = {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    avatar_url: '',
    is_active: false,
    active_at: '',
    isClerk: false,
    domain_program_memberships: [],
  }
  // Remove applications that are not in a state that should be displayed
  const applications = policy.states?.filter((policyState) =>
    ![GenericStatus.TERMINATED, GenericStatus.RENEWAL_DECLINED, GenericStatus.RENEWAL_OFFERED, GenericStatus.READY_FOR_RENEWAL]
      .includes(policyState.status))
    .map((policyState) => policyStateToApplication(policyState) as unknown as Application) || []
  const latestApplication = applications.length ? applications[0] : null
  const latestIssuedApplication = applications.find((application) => application.status === 'ISSUED') ?? null
  // Any remaining terminated applications are actually mapped from expired policyStates, which break coverage period display
  const periods: PolicyCoveragePeriod[] = applications?.filter(
    (application) => !['TERMINATED'].includes(application.status),
  ).map((application) => ({
    effective: application.effective ?? '',
    expiration: application.expiration ?? '',
    category: application.category,
    id: application.id,
    kind: application.kind,
  }))

  const today = new Date(getDateToday())
  const canReinstate = policy.status === GenericStatus.CANCELLED &&
    policy.reinstatementWindow && new Date(dateToString(policy.reinstatementWindow)!) > today
  const canRenew = policy.renewalWindow && latestIssuedApplication && latestIssuedApplication.expiration &&
    new Date(latestIssuedApplication.expiration) > today && today > new Date(dateToString(policy.renewalWindow)!)
  const canReclaim = policy.status === GenericStatus.EXPIRED

  return {
    id: policy.id,
    actionable_applications: [], // TODO: wire up
    active_tab_label: '', // not needed
    applications,
    attachments: attachments.map((file) => attachmentToApplicationAttachment(file)),
    can_reclaim: canReclaim,
    can_reinstate: !!canReinstate,
    can_renew: !!canRenew,
    renewal_forbidden: policy.status === GenericStatus.RENEWAL_DECLINED
      ? true // allow renewal to be "reconsidered" if already declined
      : policy.status !== GenericStatus.RENEWAL_OFFERED,
    children: [], // TODO: wire up
    coverage_periods: periods,
    created: '', // TODO: wire up
    effective: dateToString(policy.term?.effective) ?? latestIssuedApplication?.effective ?? '',
    events: [], // should be loaded through the event service
    expiration: dateToString(policy.term?.expiration) ?? latestIssuedApplication?.expiration ?? '',
    first_child: null, // TODO: wire up
    has_children: false, // TODO: wire up
    has_reclaim: false, // TODO: wire up
    home_tab_label: '', // not needed
    inactive_tab_label: '', // not needed
    is_home: false, // not needed
    is_policies_active: false, // not needed
    is_policies_inactive: false, // not needed
    is_reclaim: false, // TODO: wire up
    last_modified_by: unknownUser, // TODO: wire up
    // @ts-expect-error: Forcing null to avoid recursion
    latest_application: shallow ? null : latestApplication,
    // @ts-expect-error: Forcing null to avoid recursion
    latest_issued_app: shallow ? null : latestIssuedApplication,
    modified: '', // TODO: wire up
    parent: null, // will always be null for protobuf Policy
    PNI: policy.primaryNamedInsured?.legalName ?? '',
    status: genericStatusToPolicyStatus(policy.status),
  }
}
